import type {
  Operation as OperationAPI,
  TimeUnit,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { SchedulingType } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { AsapScheduling, Operation, PreorderScheduling } from '../../types/businessTypes';

const TIME_UNIT_MULTIPLIER: Record<TimeUnit, number> = {
  MINUTES: 1,
  UNKNOWN_TIME_UNIT: 0,
  DAYS: 24 * 60,
  HOURS: 60,
};
export const OperationAdapter = (operation: OperationAPI): Operation => {
  return {
    id: operation.id || '',
    enabled: !!operation.enabled,
    fulfillmentIds: operation.fulfillmentIds || [],
    asapOptions: getAsapOptionsFromOperation(operation),
    preOrderOptions: getPreOrderOptions(operation),
    operationType: getOperationType(operation),
    allowSameDayPreorder: !!operation.scheduling?.asapOptions?.allowSameDayPreorder,
  };
};

function getOperationType(operation: OperationAPI) {
  switch (operation.scheduling?.type) {
    case SchedulingType.PREORDER:
      return 'PRE_ORDER';
    case SchedulingType.ASAP:
    default:
      return 'ASAP';
  }
}

function getPreOrderOptions(operation: OperationAPI): PreorderScheduling | undefined {
  if (!operation.scheduling?.preorderOptions) {
    return;
  }
  const timeBoundedOptions = operation.scheduling?.preorderOptions?.method?.timeBoundedOptions;

  if (!timeBoundedOptions) {
    return;
  }
  const max =
    TIME_UNIT_MULTIPLIER[timeBoundedOptions!.maximumInAdvanceTime!.timeUnit!] *
    timeBoundedOptions!.maximumInAdvanceTime!.duration!;
  const min =
    TIME_UNIT_MULTIPLIER[timeBoundedOptions!.minimumInAdvanceTime!.timeUnit!] *
    timeBoundedOptions!.minimumInAdvanceTime!.duration!;
  const timeWindowsOptions =
    operation.scheduling?.preorderOptions?.fulfillmentTimesDisplayConfig?.timeWindowsOptions;
  const timeWindowDuration =
    TIME_UNIT_MULTIPLIER[timeWindowsOptions!.timeUnit!] * timeWindowsOptions!.duration!;
  return {
    timeInAdvance: { min, max },
    timeWindowDuration,
  };
}

function getAsapOptionsFromOperation(operation: OperationAPI): AsapScheduling | undefined {
  if (!operation.scheduling?.asapOptions) {
    return;
  }
  const { rangeOptions, maxOptions } = operation.scheduling.asapOptions;
  if (
    maxOptions &&
    maxOptions.duration !== null &&
    maxOptions.duration !== undefined &&
    maxOptions.timeUnit
  ) {
    return {
      maxInMinutes: maxOptions.duration * TIME_UNIT_MULTIPLIER[maxOptions.timeUnit],
    };
  }
  if (
    rangeOptions &&
    rangeOptions.timeUnit &&
    rangeOptions.rangeMaximumDuration !== undefined &&
    rangeOptions.rangeMaximumDuration !== null &&
    rangeOptions.rangeMinimumDuration !== undefined &&
    rangeOptions.rangeMinimumDuration !== null
  ) {
    return {
      rangeInMinutes: {
        min: rangeOptions.rangeMinimumDuration * TIME_UNIT_MULTIPLIER[rangeOptions.timeUnit],
        max: rangeOptions.rangeMaximumDuration * TIME_UNIT_MULTIPLIER[rangeOptions.timeUnit],
      },
    };
  }
}
