import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';
import { DispatchType } from '../../types/businessTypes';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
const DEFAULT_DATA: FulfillmentPickerProps = {
  availableDispatchTypes: [DispatchType.PICKUP, DispatchType.DELIVERY],
  configuredDispatchTypes: [DispatchType.PICKUP, DispatchType.DELIVERY],
  selectedDispatchType: DispatchType.PICKUP,
  onDispatchTypeChange: () => {},
};
export default createBlocksModel({
  widgetName: 'FulfillmentPicker',
  props: {
    // Here you can define your widget props
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: DEFAULT_DATA,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
