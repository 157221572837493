import type { BindAll } from './types';
import { HEADER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';

export interface BindHeaderProps {
  $bindAll: BindAll;
  componentsProps: {
    headerContainer: HeaderContainerProps;
    dispatchPicker: DispatchPickerProps;
    statusIndicator: StatusIndicatorProps;
    acceptOrders: AcceptOrderProps;
    minOrder: MinOrderProps;
    deliveryFee: DeliveryFeeProps;
    addressInfo: AddressInfoProps;
    timeInfo: TimeInfoProps;
    addressChange: AddressChangeProps;
    timeChange: TimeChangeProps;
  };
}

export function bindHeader(props: BindHeaderProps) {
  const { $bindAll, componentsProps } = props;
  const {
    headerContainer,
    dispatchPicker,
    statusIndicator,
    acceptOrders,
    minOrder,
    deliveryFee,
    addressInfo,
    timeInfo,
    addressChange,
    timeChange,
  } = componentsProps;
  $bindAll({
    [HEADER_WIDGET_COMPONENT_IDS.headerContainer]: {
      onViewportEnter: headerContainer.onViewportEnter,
      onViewportLeave: headerContainer.onViewportLeave,
    },
    [HEADER_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
      data: () => dispatchPicker.data(),
      collapsed: () => dispatchPicker.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.acceptOrders]: {
      text: () => acceptOrders.text(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.statusIndicator]: {
      style: statusIndicator.style,
    },
    [HEADER_WIDGET_COMPONENT_IDS.minOrder]: {
      collapsed: () => minOrder.collapsed(),
      text: () => minOrder.text(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.minOrderWrapper]: {
      collapsed: () => minOrder.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.deliveryFee]: {
      collapsed: () => deliveryFee.collapsed(),
      text: () => deliveryFee.text(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.deliveryFeeWrapper]: {
      collapsed: () => deliveryFee.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.freeDelivery]: {
      collapsed: () => deliveryFee.freeDeliveryCollapse(),
      text: () => deliveryFee.freeDeliveryText(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.freeDeliveryWrapper]: {
      collapsed: () => deliveryFee.freeDeliveryCollapse(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.addressInfo]: {
      text: () => addressInfo.text(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.addressInfoLine]: {
      collapsed: () => addressInfo.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.timeInfo]: {
      text: () => timeInfo.displayableTimeInfo().timeInfoText,
    },
    [HEADER_WIDGET_COMPONENT_IDS.timeInfoLine]: {
      collapsed: () => timeInfo.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.addressChange]: {
      onClick: addressChange.onClick,
      label: () => addressChange.label(),
      collapsed: () => addressChange.collapsed(),
    },
    [HEADER_WIDGET_COMPONENT_IDS.timeChange]: {
      onClick: timeChange.onClick,
      label: () => timeChange.label(),
      collapsed: () => timeChange.collapsed(),
    },
  });
}

export interface HeaderContainerProps {
  onViewportEnter: () => void;
  onViewportLeave: () => void;
}
export interface DispatchPickerProps {
  collapsed: () => boolean;
  data: () => FulfillmentPickerProps;
}

export interface StatusIndicatorProps {
  style: { backgroundColor: () => string };
}

export interface AcceptOrderProps {
  text: () => string;
}

export interface MinOrderProps {
  collapsed: () => boolean;
  text: () => string;
}
export interface DeliveryFeeProps {
  collapsed: () => boolean;
  text: () => string;
  freeDeliveryCollapse: () => boolean;
  freeDeliveryText: () => string;
}

export interface AddressInfoProps {
  text: () => string;
  collapsed: () => boolean;
}

export interface TimeInfoProps {
  displayableTimeInfo: () => { displayableTime?: string; timeInfoText: string };
  collapsed: () => boolean;
}

export interface AddressChangeProps {
  onClick: () => void;
  label: () => string;
  collapsed: () => boolean;
}

export interface TimeChangeProps {
  onClick: () => void;
  label: () => string;
  collapsed: () => boolean;
}
