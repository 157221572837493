import type { DispatchModalState } from './state/DispatchModalState';
import type { GetDropdownTimeOptionsParams } from '../../logic/timeUtils';
import {
  convertOptionToDispatchTime,
  getDayOfTheWeek,
  getDropdownTimeOptions,
  getMinutesPassedSinceStartOfWeek,
  getOptionIdFromDispatchTime,
  getToday,
  getDropdownOptionsFromDates,
} from '../../logic/timeUtils';
import type {
  DispatchTime,
  PreorderScheduling,
  SameDayScheduling,
  WeeklyAvailability,
} from '../../types/businessTypes';
import { SchedulingType, TimeState } from '../../types/businessTypes';
import { toJS } from 'mobx';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { DispatchModalForm } from '../../utils/dispatchModalUtils';
import { EMPTY_DISPATCH_TIME } from '../../api/consts';
import type { DispatchState } from '../../states/HeaderState';
import { getFirstAvailableMinuteOfTheWeek } from './componentsBindings/datePickerUtils';
import { DateTime } from 'luxon';

export const getTimeState = (
  state: DispatchModalState,
  schedulingType: SchedulingType,
  canOrderForNow: boolean,
  timezone: string
) => {
  const canSubmitOrder = !!state.weeklyAvailability[getToday(timezone)].find(
    (min) =>
      min.start <= getMinutesPassedSinceStartOfWeek(timezone) &&
      min.end > getMinutesPassedSinceStartOfWeek(timezone)
  );
  return resolveTimeSchedulingState(
    schedulingType,
    state,
    (state.isPickup && canOrderForNow) || canSubmitOrder
  );
};

export function resolveTimeSchedulingState(
  schedulingType: SchedulingType | SameDayScheduling,
  state: DispatchModalState,
  canOrderForNow?: boolean
): TimeState {
  const resolveSameDay = () => {
    if (!canOrderForNow) {
      return TimeState.SAME_DAY_LATER;
    }
    if (
      canOrderForNow &&
      ((state.options && state.options.length > 0) ||
        (state.isDelivery && (!state.address || !state.error)))
    ) {
      return TimeState.SAME_DAY;
    }
    if (canOrderForNow && (!state.options || state.options.length < 1)) {
      return TimeState.ASAP_ONLY;
    }
    return TimeState.ASAP_ONLY;
  };

  switch (schedulingType) {
    case SchedulingType.PRE_ORDER:
      return TimeState.PRE_ORDER;
    case SchedulingType.SAME_DAY: {
      return resolveSameDay();
    }
    case SchedulingType.ASAP:
    default:
      return TimeState.ASAP_ONLY;
  }
}

export function getDispatchFormToSave(state: DispatchModalState): DispatchModalForm {
  const selectedTime = state.selectedDate
    ? {
        from: state.selectedTime.from,
        until: state.selectedTime.until,
        date: state.selectedDate,
      }
    : state.selectedTime;
  const selected = {
    from: toJS(selectedTime.from),
    until: toJS(selectedTime.until),
    date: toJS(selectedTime.date),
  };
  return {
    availableFulfillments: state.availableFulfillments,
    prepTime: toJS(state[state.selectedDispatchType].prepTime),
    selectedDispatchType: state.selectedDispatchType,
    selectedSameDayScheduling: state.selectedSchedulingType,
    ...(state.isDelivery && state.address !== undefined
      ? {
          address: toJS(state.address),
        }
      : {}),
    selectedTime: selected,
  };
}

export const getSelectedTime = (
  options: { label: string; value: string }[],
  initialSelectedTime?: DispatchTime
) => {
  const selectedTimeOptionId =
    options.length > 0
      ? options.find((option) => option.value === getOptionIdFromDispatchTime(initialSelectedTime))
          ?.value || options[0].value
      : '';
  const selectedTime =
    initialSelectedTime ||
    (selectedTimeOptionId
      ? convertOptionToDispatchTime(selectedTimeOptionId)
      : EMPTY_DISPATCH_TIME);
  return { selectedTimeOptionId, selectedTime };
};

export function getDispatchDropdownOptions({
  t,
  locale,
  dispatchState,
  timezone,
  preorderOptions,
  weeklyAvailability,
  minDate,
}: {
  t: TFunction;
  locale: string;
  weeklyAvailability: WeeklyAvailability;
  dispatchState: DispatchState;
  timezone: string;
  preorderOptions?: PreorderScheduling;
  minDate?: DateTime;
}) {
  let options: { label: string; value: string }[] = [];
  const initialSelectedTime = dispatchState.dispatchTime;

  const dayOfTheWeek = initialSelectedTime?.date
    ? getDayOfTheWeek(initialSelectedTime.date)
    : getToday(timezone);

  const chosenDate = initialSelectedTime?.date || DateTime.local({ zone: timezone });

  const firstAvailableMinuteOfTheWeek = getFirstAvailableMinuteOfTheWeek({
    minDate,
    timezone,
    chosenDate,
    preorderOptions,
  });

  const timeWindow = preorderOptions?.timeWindowDuration;
  const dropdownParams: GetDropdownTimeOptionsParams = {
    day: dayOfTheWeek,
    weeklyAvailability,
    locale,
    timezone,
    prepTime: dispatchState.prepTime.max,
    t,
    firstAvailableMinuteOfTheWeek,
    isAsap: !preorderOptions,
    ...(timeWindow !== undefined ? { range: timeWindow } : {}),
  };
  options = getDropdownTimeOptions(dropdownParams);

  return options;
}

const isSameDay = (date1: DateTime, date2: DateTime) => {
  return date1.day === date2.day && date1.month === date2.month && date1.year === date2.year;
};

export const getPreOrderTimeSlotsDropdownOptions = ({
  date,
  preOrderTimeWindows,
  locale,
  timezone,
  t,
}: {
  date?: DateTime;
  preOrderTimeWindows?: DispatchTime[];
  locale: string;
  timezone: string;
  t: TFunction;
}) => {
  return getDropdownOptionsFromDates({
    minutes: (preOrderTimeWindows as DispatchTime[]).filter((time: DispatchTime) => {
      return time.date && isSameDay(time.date, date || DateTime.now().setZone(timezone));
    }),
    locale,
    timezone,
    t,
  });
};
