import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { listItems, getItem } from '@wix/ambassador-restaurants-menus-v1-item/http';

type FileInfoType = Partial<{
  width: number;
  height: number;
  file_name: string;
}>;

export class ItemsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchAllItems = async () => {
    try {
      return this.httpClient.request(listItems({})).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getItemById = async (itemId: string) => {
    try {
      return this.httpClient.request(getItem({ itemId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getImgsData = async (
    fileIds: string[],
    metaSiteId: string
  ): Promise<{ id: string; width: number; height: number }[]> => {
    try {
      const url = `https://files.wix.com/site/media/files/multi/info`;

      return this.httpClient
        .post(url, {
          files: fileIds,
          site_id: metaSiteId,
        })
        .then((response) => {
          const { files = [] } = response.data as Partial<{
            files: FileInfoType[];
          }>;

          return files.map((file: FileInfoType) => {
            const { file_name = '', width = 0, height = 0 } = file;
            return { id: file_name, width, height };
          });
        });
    } catch (e: unknown) {
      console.error(e);
      return [];
    }
  };
}
