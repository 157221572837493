/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ILocation, IWixWindow } from '@wix/yoshi-flow-editor';
import {
  DISH_ITEM_MODAL_TITLE,
  DISPATCH_MODAL_TITLE,
  ERROR_MODAL_TITLE,
  LIGHTBOX_QUERY_PARAM_KEY,
} from '../appConsts/consts';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import type { DishModalProps, DispatchModalProps } from '../types/widgetsProps';
import type { ADD_TO_CART_ERRORS, CartLineItem, ICartService } from './cartService';
import { state } from '../states/RootState';

export interface IModalService {
  openDishModal: (data: DishModalProps) => Promise<any>;
  openDispatchModal: (data?: DispatchModalProps) => Promise<any>;
  openErrorModal: (data?: any) => Promise<any>;
  closeModal: (window: IWixWindow, data: any) => Promise<any>;
}

export class ModalService implements IModalService {
  constructor(
    private window: IWixWindow,
    private fedopsLogger: FedopsLogger,
    private location: ILocation,
    private addQueryParamToUrl: boolean,
    private cartService: ICartService
  ) {}

  private addModalQueryParam = () => {
    if (this.addQueryParamToUrl) {
      this.location.queryParams.add({ [LIGHTBOX_QUERY_PARAM_KEY]: true.toString() });
    }
  };

  private removeModalQueryParam = () => {
    if (this.addQueryParamToUrl) {
      this.location.queryParams.remove([LIGHTBOX_QUERY_PARAM_KEY]);
      // cart icon get last refreshed num of items after queryParams changes
      this.cartService.reloadCart?.();
    }
  };

  openDishModal = async (data: DishModalProps) => {
    this.fedopsLogger.openItemModalStarted();
    this.addModalQueryParam();
    const modalValue = await this.window.openLightbox(DISH_ITEM_MODAL_TITLE, {
      data,
      onModalOpen: () => this.fedopsLogger.openItemModalEnded(),
      closeModal: (
        window: IWixWindow,
        props: Promise<{ cartItem?: CartLineItem; error?: ADD_TO_CART_ERRORS }>,
        cartLineItem?: CartLineItem
      ) => {
        return this.closeModal(window, props, cartLineItem);
      },
    });
    this.removeModalQueryParam();
    return modalValue;
  };

  openDispatchModal = async (data?: DispatchModalProps) => {
    this.fedopsLogger.openDispatchModalStarted();
    this.addModalQueryParam();
    const modalValue = await this.window.openLightbox(DISPATCH_MODAL_TITLE, {
      data,
      onModalOpen: () => this.fedopsLogger.openDispatchModalEnded(),
      closeModal: (window: IWixWindow, props?: DispatchModalProps) =>
        this.closeModal(window, props),
    });
    this.removeModalQueryParam();
    return modalValue;
  };

  openErrorModal = async (data?: any) => {
    this.fedopsLogger.openErrorModalStarted();
    this.addModalQueryParam();
    const modalValue = await this.window.openLightbox(ERROR_MODAL_TITLE, {
      data,
      onModalOpen: () => this.fedopsLogger.openErrorModalEnded(),
      closeModal: (window: IWixWindow, props?: object) => this.closeModal(window, props),
    });
    this.removeModalQueryParam();
    return modalValue;
  };

  closeModal = async (
    window: IWixWindow,
    data?: Promise<object> | object,
    additionaldata?: object
  ) => {
    // @ts-expect-error
    return window.lightbox.close({ data, additionaldata });
  };
}

export const setModalMethodsSpy = (spyModalMethods?: (keyof ModalService)[]) => {
  const spyMethods = new Map<keyof IModalService, jest.SpyInstance>();
  for (const method of spyModalMethods ?? []) {
    spyMethods.set(method, jest.spyOn(state.ModalService!, method));
  }
  return spyMethods;
};
