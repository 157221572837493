import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { settleApiCall } from './settleApiCall';

export type ReportError =
  | ((error: Error | string) => string | undefined)
  | ((ex: Error | ErrorEvent | string) => void);

export const getMonitoredApiCall = async <T>({
  callback,
  fedops,
  sentry,
  reportError,
  excludeErrorsFromSentry,
}: {
  callback: () => Promise<T>;
  fedops: { start: Function; end: Function };
  sentry?: ErrorMonitor;
  reportError?: ReportError;
  excludeErrorsFromSentry?: string[];
}) => {
  return new Promise<{ data?: T; error?: Error }>(async (resolve, reject) => {
    fedops.start();
    sentry?.addBreadcrumb({
      category: 'api',
      message: fedops.start.name,
    });
    const { data, error } = await settleApiCall<T>(callback);
    if (error) {
      if (!excludeErrorsFromSentry?.some((e) => error.message.indexOf(e) > -1)) {
        sentry ? sentry?.captureException(error) : reportError && reportError(error);
      }
      reject(error);
    } else {
      fedops.end();
    }

    resolve({ data, error });
  });
};
