import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';
import { FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { DispatchType } from '../../types/businessTypes';

const DISPATCH_TYPE_STATE = {
  selected: 'selected',
  default: 'default',
  disabled: 'disabled',
} as const;

const TRANSLATION_KEY = {
  [DispatchType.DELIVERY]: 'header_olo.delivery',
  [DispatchType.PICKUP]: 'header_olo.pickup',
};

const getDispatchTypeState = (
  dispatchType: DispatchType,
  availableDispatchTypes: DispatchType[] | undefined,
  selectedDispatchType: DispatchType
) => {
  if (!availableDispatchTypes?.includes(dispatchType)) {
    return DISPATCH_TYPE_STATE.disabled;
  } else if (selectedDispatchType === dispatchType) {
    return DISPATCH_TYPE_STATE.selected;
  } else {
    return DISPATCH_TYPE_STATE.default;
  }
};

export class FulfillmentPickerController {
  constructor(private $w: I$W) {}

  async initFulfillmentPicker(configuredDispatchTypes: DispatchType[] | undefined, t: TFunction) {
    const configs = [DispatchType.DELIVERY, DispatchType.PICKUP].map((dispatchType) => ({
      componentId: FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[dispatchType],
      collapsed: !configuredDispatchTypes?.includes(dispatchType),
      text: t(TRANSLATION_KEY[dispatchType]),
    }));
    configs.forEach((config) => {
      if (config.collapsed) {
        this.$w(config.componentId.multiStateBox).collapse();
      }
      this.$w(config.componentId.selectedText).text = config.text;
      this.$w(config.componentId.defaultText).text = config.text;
      this.$w(config.componentId.disabledText).text = config.text;
    });
  }

  async initDispatchState({
    availableDispatchTypes,
    selectedDispatchType,
    onDispatchTypeChange,
  }: Omit<FulfillmentPickerProps, 'configuredDispatchTypes'>) {
    [DispatchType.DELIVERY, DispatchType.PICKUP].forEach(async (dispatchType) => {
      const state = getDispatchTypeState(
        dispatchType,
        availableDispatchTypes,
        selectedDispatchType
      );

      const componentIds = FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[dispatchType];

      const boxElementId = componentIds[state];

      await this.$w(componentIds.multiStateBox).changeState(boxElementId.slice(1));

      if (state !== DISPATCH_TYPE_STATE.disabled) {
        this.$w(boxElementId).onClick(() => {
          onDispatchTypeChange(dispatchType);
        });
      }
    });
  }
}
