import type { DispatchModalState } from '../state/DispatchModalState';
import type { DatePickerProps } from '../dispatchModalBindings';
import type { DAY_OF_THE_WEEK, PreorderScheduling } from '../../../types/businessTypes';
import { SchedulingType } from '../../../types/businessTypes';
import { convertOptionToDispatchTime, getDropdownTimeOptions } from '../../../logic/timeUtils';
import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import { DateTime } from 'luxon';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { IBIReporterService } from '../../../services/biReporterService';
import { toJS } from 'mobx';
import { getFirstAvailableMinuteOfTheWeek } from './datePickerUtils';
import { SPECS } from '../../../appConsts/experiments';
import { getPreOrderTimeSlotsDropdownOptions } from '../dispatchModalUtils';

export function getDatePickerProps({
  state,
  timezone,
  t,
  schedulingType,
  locale,
  biReporterService,
  preorderOptions,
  experiments,
}: {
  state: DispatchModalState;
  timezone: string;
  t: TFunction;
  schedulingType: SchedulingType;
  locale: string;
  experiments: Experiments;
  biReporterService?: IBIReporterService;
  preorderOptions?: PreorderScheduling;
}): DatePickerProps {
  return {
    timeSlotsValue: () => state.selectedTimeOptionId,
    collapsed: () => {
      return !state.address || state.options.length < 1;
    },
    disabledDaysOfWeek: () => {
      const disabledDaysOfWeek: number[] = [];
      state.weeklyAvailability.forEach((day, index) => {
        if (day.length < 1) {
          disabledDaysOfWeek.push(index);
        }
      });
      return disabledDaysOfWeek;
    },
    timezone: () => timezone,
    minDate: () =>
      state.minDate?.setZone(timezone, { keepLocalTime: true, keepCalendarTime: true }).toJSDate(),
    maxDate: () =>
      state.maxDate?.setZone(timezone, { keepLocalTime: true, keepCalendarTime: true }).toJSDate(),
    onDateChange: (e) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.DAY_DROPDOWN_OPTION,
      });
      let date = DateTime.fromJSDate(e.target.value);
      const dateOptions =
        date.zone.name === timezone ? { keepLocalTime: true, keepCalendarTime: true } : {};
      date = date.setZone(timezone, dateOptions).startOf('day');
      state.setDate(date);
      const dayOfTheWeek = date.weekday % 7;
      let options: { value: string; label: string }[] = [];

      if (experiments.enabled(SPECS.newPreOrderTimeSlotsLogic)) {
        options = getPreOrderTimeSlotsDropdownOptions({
          timezone,
          date,
          t,
          locale,
          preOrderTimeWindows: state.preOrderTimeWindows,
        });
      } else {
        const firstAvailableMinuteOfTheWeek = getFirstAvailableMinuteOfTheWeek({
          minDate: state.minDate,
          timezone,
          chosenDate: date,
          preorderOptions,
        });

        if (dayOfTheWeek !== undefined) {
          const args = {
            weeklyAvailability: toJS(state.weeklyAvailability),
            timezone,
            locale,
            day: dayOfTheWeek as DAY_OF_THE_WEEK,
            prepTime: 0,
            t,
            firstAvailableMinuteOfTheWeek,
            isAsap: !preorderOptions,
            ...(state.timeWindow ? { range: state.timeWindow } : {}),
          };
          options = getDropdownTimeOptions(args);
        }
      }

      state.setOptions(options);
      options.length > 0 && state.setSelectedOption(options[0].value);
    },
    onDateClick: (evt) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.DAY_DROPDOWN,
      });
    },
    value: () => state.selectedDate?.setZone(timezone).toJSDate(),
    options: () => state.options,
    onTimeSlotChange: (evt) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN_OPTION,
      });
      state.setTime(convertOptionToDispatchTime(evt.target.value));
    },
    onTimeSlotClick: (evt) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN,
      });
    },
    label: () => t('menu_olo.dispatchModal.datePicker'),
  };
}
