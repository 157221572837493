import type {
  ModifierListItem,
  PriceVariant,
  ItemModifierGroup,
  ModifierGroup,
  ModifierGroupListItem,
  Modifier,
  ModifierAdditionalCharge,
} from '../types/modifiers';

export const getIdsToModifiersMap = (allModifiers: Modifier[]) => {
  return allModifiers?.reduce((acc, modifier) => {
    if (modifier.id) {
      acc[modifier.id] = modifier;
    }
    return acc;
  }, {} as Record<string, Modifier>);
};

export const getIdsToModifierGroupsMap = (allModifierGroups: ModifierGroup[]) => {
  return allModifierGroups?.reduce((acc, modifierGroup) => {
    if (modifierGroup.id) {
      acc[modifierGroup.id] = modifierGroup;
    }
    return acc;
  }, {} as Record<string, ModifierGroup>);
};

export const getPriceVariantsWithName = ({
  allModifiers,
  priceVariants,
}: {
  allModifiers: Record<string, Modifier>;
  priceVariants?: PriceVariant[];
}) => {
  return (priceVariants || [])?.reduce((acc, priceVariant) => {
    const selectedModifier = allModifiers[priceVariant.id];
    if (selectedModifier) {
      acc = acc.concat({
        id: priceVariant.id,
        name: selectedModifier?.name,
        price: priceVariant.price,
      });
    }
    return acc || [];
  }, [] as ModifierListItem[]);
};

export const getModifiersWithName = ({
  allModifiers,
  modifiers,
}: {
  allModifiers: Record<string, Modifier>;
  modifiers?: ModifierAdditionalCharge[];
}) => {
  return (modifiers || [])?.reduce((acc, modifier) => {
    const selectedModifier = allModifiers[modifier.id as string];
    if (selectedModifier) {
      acc = acc.concat({
        id: modifier.id,
        name: selectedModifier?.name,
        price: modifier.price,
      });
    }
    return acc || [];
  }, [] as ModifierListItem[]);
};

export const getVariantById = ({
  priceVariantsInItem,
  modifiersListItem,
  variantId,
}: {
  priceVariantsInItem?: PriceVariant[];
  modifiersListItem?: ModifierListItem[];
  variantId: string;
}) => {
  if (priceVariantsInItem) {
    return priceVariantsInItem.find((priceVariant) => priceVariant.id === variantId);
  }
  if (modifiersListItem) {
    return modifiersListItem.find((modifierItem) => modifierItem.id === variantId);
  }
};

export const getVariantWithMinimalPrice = ({
  priceVariantsInItem,
  modifiersListItem,
}: {
  priceVariantsInItem?: PriceVariant[];
  modifiersListItem?: ModifierListItem[];
}) => {
  if (priceVariantsInItem) {
    return priceVariantsInItem.reduce(
      (prev, curr) => (Number(prev.price) < Number(curr.price) ? prev : curr),
      {} as PriceVariant
    );
  }
  if (modifiersListItem) {
    return modifiersListItem.reduce(
      (prev, curr) => (Number(prev.price) < Number(curr.price) ? prev : curr),
      {} as ModifierListItem
    );
  }
};

export const getPriceVariantsIds = (priceVariants: PriceVariant[]) => {
  return priceVariants?.map((priceVariant) => {
    return priceVariant.id;
  });
};

export const getModifiersIds = (modifierGroups: ModifierGroup[]): string[] => {
  const modifierIdsByModifierGroups = modifierGroups.map(
    (modifierGroup) => modifierGroup.modifiers
  );
  const modifiersObjects = modifierIdsByModifierGroups.flat(1);
  return modifiersObjects.map((modifier) => modifier?.id) as string[];
};

export const getModifierGroupsIds = (modifierGroups: ItemModifierGroup[]) => {
  return modifierGroups.map((modifierGroup) => {
    return modifierGroup.id;
  });
};

export const getModifierGroupsListItems = ({
  modifiers,
  modifierGroups,
  itemModifierGroups,
}: {
  modifiers: Record<string, Modifier>;
  modifierGroups: Record<string, ModifierGroup>;
  itemModifierGroups: ItemModifierGroup[];
}) => {
  return itemModifierGroups
    .filter((itemModifierGroup) => !!itemModifierGroup.id)
    .map((itemModifierGroup) => {
      const modifierGroup = modifierGroups[itemModifierGroup.id];
      return {
        id: itemModifierGroup.id ?? '',
        name: modifierGroup?.name,
        rule: modifierGroup?.rule,
        modifiers: getModifiersWithName({
          allModifiers: modifiers,
          modifiers: modifierGroup?.modifiers,
        }),
      } as ModifierGroupListItem;
    }) as ModifierGroupListItem[];
};

export const sumAdditionalCharges = (selectedModifiers: Record<string, ModifierListItem[]>) => {
  const modifiers = Object.values(selectedModifiers).flat(1);
  const additionalChargesPerModifier = modifiers.map((modifier) => Number(modifier.price) ?? 0);
  return additionalChargesPerModifier.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
};
