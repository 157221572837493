import type { MinMaxRange } from '../../../types/businessTypes';
import { SameDayScheduling } from '../../../types/businessTypes';
import { EMPTY_DISPATCH_TIME } from '../../../api/consts';
import { EMPTY_WEEKLY_AVAILABILITY } from '../../../appConsts/consts';
import type { DispatchForm } from './DispatchModalState';

export const DEFAULT_PREP_TIME: MinMaxRange = { min: 0, max: 0 };

export const DEFAULT_PREORDER_TIME_WINDOW_DURATION = 15;

export const DEFAULT_DISPATCH_FORM: DispatchForm = {
  selectedSchedulingType: SameDayScheduling.SAME_DAY,
  selectedDayOfTheWeek: 0,
  availableFulfillments: [],
  selectedTimeOptionId: '',
  selectedTime: EMPTY_DISPATCH_TIME,
  weeklyAvailability: EMPTY_WEEKLY_AVAILABILITY,
  prepTime: DEFAULT_PREP_TIME,
};
