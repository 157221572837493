import { ErrorType, type DispatchModalState } from '../state/DispatchModalState';
import type { DispatchModalForm } from '../../../utils/dispatchModalUtils';
import type { SaveButtonProps } from '../dispatchModalBindings';
import { getDispatchFormToSave } from '../dispatchModalUtils';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { IBIReporterService } from '../../../services/biReporterService';
import { LiveSiteDispatchModalClosedTriggerName } from '@wix/restaurants-bi';

export function getSaveButtonProps(
  state: DispatchModalState,
  onSave: (form: DispatchModalForm) => void,
  closeModal: () => void,
  t: TFunction,
  biReporterService?: IBIReporterService
): SaveButtonProps {
  return {
    onClick: () => {
      if (state.error || !state.address) {
        if (!state.address) {
          const errorMessage = t('menu_olo.dispatchModal.error.noAddress');
          state.setError({
            type: ErrorType.NO_ADDRESS,
            message: errorMessage,
          });
          biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
            dispatchType: state.dispatchType,
            errorType: ErrorType.NO_ADDRESS,
            errorMessage,
          });
        }
      } else {
        onSave(getDispatchFormToSave(state));
        biReporterService?.reportOloLiveSiteDispatchModalClosedBiEvent({
          dispatchType: state.dispatchType,
          triggerName: LiveSiteDispatchModalClosedTriggerName.SAVE_BUTTON,
        });
        closeModal();
      }
    },
    label: () => t('menu_olo.dispatchModal.saveButton'),
  };
}
