import type { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import type { WarmupDataManager } from 'root/utils/WarmupDataManager';
import { DispatchType } from 'root/types/businessTypes';
import type {
  DispatchesInfo,
  DispatchState,
  Operation,
  Address,
  TimeSlot,
} from 'root/types/businessTypes';
import type { FulfillmentMethod } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';
import { state as rootState } from 'root/states/RootState';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import type { ReportError } from '@wix/yoshi-flow-editor';

type MonitoredResponse<T> = { data?: T; error?: Error } | undefined;

const getConsistentValue = (values: (string | null | undefined)[]) => {
  return values.length === 0 || values.some((value) => value !== values[0])
    ? undefined
    : values[0] ?? undefined;
};

const processFulfillments = async (
  allFulfillmentMethods: Promise<MonitoredResponse<FulfillmentMethod[]>>
) => {
  return allFulfillmentMethods.then((res) => {
    const fulfillments = res?.data ?? [];
    const pickupFulfillment = fulfillments.find(
      (fulfillment) => fulfillment.type === 'PICKUP' && fulfillment.enabled
    );
    const deliveryFulfillments = fulfillments.filter(
      (fulfillment) => fulfillment.type === 'DELIVERY' && fulfillment.enabled
    );
    const isPickupConfigured = !!pickupFulfillment;
    const isDeliveryConfigured = deliveryFulfillments.length > 0;
    const dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

    if (isPickupConfigured) {
      const pickupAddress = pickupFulfillment?.pickupOptions?.address
        ? (pickupFulfillment.pickupOptions.address as Address)
        : undefined;

      dispatchesInfo[DispatchType.PICKUP] = {
        address: pickupAddress,
        minOrder: pickupFulfillment?.minimumOrderAmount ?? undefined,
      };
    }

    if (isDeliveryConfigured) {
      const deliveryFee = getConsistentValue(deliveryFulfillments.map((f) => f.fee));
      const freeFulfillmentPriceThreshold = getConsistentValue(
        deliveryFulfillments.map((f) => f.deliveryOptions?.freeDeliveryThreshold)
      );

      const minOrder = getConsistentValue(deliveryFulfillments.map((f) => f.minimumOrderAmount));

      dispatchesInfo[DispatchType.DELIVERY] = {
        minOrder,
        deliveryFee,
        freeFulfillmentPriceThreshold,
      };
    }
    return { dispatchesInfo, isPickupConfigured, isDeliveryConfigured };
  });
};

const processFirstAvailableTimeSlots = async (
  firstAvailableTimeSlotPromise: Promise<MonitoredResponse<TimeSlot[]>>
) => {
  return firstAvailableTimeSlotPromise.then((res) => {
    const timeSlots = res?.data ?? [];
    const dispatchesInfo = timeSlots.reduce((acc, timeSlot) => {
      acc[timeSlot.dispatchType] = {
        selectedTimeSlot: timeSlot,
        minDate: timeSlot.startTime ?? undefined,
      };
      return acc;
    }, {} as DispatchesInfo);
    return { dispatchesInfo };
  });
};

export const initDispatchState = async (
  fulfillmentsClient: FulfillmentsClient,
  warmupData: WarmupDataManager,
  operation: Operation,
  timezone: string,
  fedopsLogger?: FedopsLogger,
  reportError?: ReportError
): Promise<DispatchState> => {
  const getMonitoredAllFulfillmentsClient = () =>
    getMonitoredApiCall({
      callback: () => fulfillmentsClient.fetchAllFulfillments(operation.fulfillmentIds),
      fedops: {
        start: fedopsLogger?.fetchAllFulfillmentsStarted ?? (() => {}),
        end: fedopsLogger?.fetchAllFulfillmentsEnded ?? (() => {}),
      },
      reportError,
    });

  const allFulfillmentsPromise = getMonitoredAllFulfillmentsClient();
  const { address } = rootState.PersistDataService?.getSelectedDispatchDetails(timezone) ?? {};

  const getMonitoredFirstAvailableTimeSlotClient = () =>
    getMonitoredApiCall({
      callback: () => fulfillmentsClient.fetchFirstAvailableTimeSlot(address),
      fedops: {
        start: fedopsLogger?.fetchFirstAvailableTimeSlotStarted ?? (() => {}),
        end: fedopsLogger?.fetchFirstAvailableTimeSlotEnded ?? (() => {}),
      },
      reportError,
    });

  // TODO: uncomment this when we finish QA session
  // const allFulfillmentsPromise = warmupData.manageData<MonitoredResponse<FulfillmentMethod[]>>(
  //   getMonitoredAllFulfillmentsClient,
  //   'allFulfillments'
  // );

  // const firstAvailableTimeSlotPromise = warmupData.manageData<MonitoredResponse<TimeSlot[]>>(
  //   getMonitoredFirstAvailableTimeSlotClient,
  //   'firstAvailableTimeSlots'
  // );
  const firstAvailableTimeSlotPromise = getMonitoredFirstAvailableTimeSlotClient();

  const [allFulfillmentsProcessResult, firstAvailableTimeSlotsProcessResult] = await Promise.all([
    processFulfillments(allFulfillmentsPromise),
    processFirstAvailableTimeSlots(firstAvailableTimeSlotPromise),
  ]);

  const isPickupSelected =
    DispatchType.PICKUP in firstAvailableTimeSlotsProcessResult.dispatchesInfo ||
    (Object.keys(firstAvailableTimeSlotsProcessResult.dispatchesInfo).length === 0 &&
      allFulfillmentsProcessResult.isPickupConfigured);

  const dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

  if (allFulfillmentsProcessResult.isPickupConfigured) {
    dispatchesInfo[DispatchType.PICKUP] = {
      ...allFulfillmentsProcessResult.dispatchesInfo[DispatchType.PICKUP],
      ...firstAvailableTimeSlotsProcessResult.dispatchesInfo[DispatchType.PICKUP],
    };
  }

  if (allFulfillmentsProcessResult.isDeliveryConfigured) {
    dispatchesInfo[DispatchType.DELIVERY] = {
      ...allFulfillmentsProcessResult.dispatchesInfo[DispatchType.DELIVERY],
      ...firstAvailableTimeSlotsProcessResult.dispatchesInfo[DispatchType.DELIVERY],
    };
  }

  return {
    selectedDispatchType: isPickupSelected ? DispatchType.PICKUP : DispatchType.DELIVERY,
    dispatchesInfo,
  };
};
