import type { Minute } from '../types/businessTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';

export const getASAPTimeString = (t: TFunction, prepTime: { min: Minute; max: Minute }) => {
  const timingType = prepTime.min === prepTime.max ? 'up_to' : 'range';

  switch (timingType) {
    case 'up_to': {
      return t('menu_olo.dispatch.time.upTo.minutes', {
        upTo: prepTime.max,
      });
    }
    case 'range': {
      return t('menu_olo.dispatch.time.range.minutes', {
        from: prepTime.min,
        until: prepTime.max,
      });
    }
  }
};
