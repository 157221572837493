import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  listAvailableFulfillmentOptions,
  listFirstAvailableTimeSlotForFulfillmentTypes,
  listAvailableDatesInRange,
  listAvailableTimeSlotsForDate,
} from '@wix/ambassador-restaurants-operations-v1-operation/http';
import { queryFulfillmentMethods } from '@wix/ambassador-restaurants-v1-fulfillment-method/http';
import type { ListAvailableFulfillmentOptionsResponse } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { FulfillmentOptionsProcessor } from './utils/FulfillmentOptionsProcessor';
import type { Address, DispatchType } from '../types/businessTypes';
import { state } from '../states/RootState';
import { DateTime } from 'luxon';
import { convertDate } from './utils/utils';
import { processFulfillmentTimeSlot } from './utils/TimeSlotProcessor';

export class FulfillmentsClient {
  constructor(private httpClient: IHttpClient, private operationId: string) {}

  private fetchAllAvailableFulfillments = async (address?: Address) => {
    try {
      const deliveryAddress: Address = {
        ...address,
        subdivisions: undefined,
      };

      const data = await this.httpClient
        .request(
          listAvailableFulfillmentOptions({
            operationId: this.operationId,
            deliveryAddress,
          })
        )
        .then((response) => response.data);
      return data;
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return {};
    }
  };

  fetchAllFulfillments = async (fulfillmentIds?: string[]) => {
    try {
      const data = await this.httpClient
        .request(
          queryFulfillmentMethods({
            query: {
              filter: {
                $and: [
                  {
                    id: {
                      $in: fulfillmentIds,
                    },
                  },
                ],
              },
            },
          })
        )
        .then((response) => response.data);

      return data?.fulfillmentMethods ?? [];
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
  };

  fetchFirstAvailableTimeSlot = async (deliveryAddress?: Address) => {
    try {
      const data = await this.httpClient
        .request(
          listFirstAvailableTimeSlotForFulfillmentTypes({
            operationId: this.operationId,
            deliveryAddress,
          })
        )
        .then((response) => response.data);

      return data?.timeSlots?.map(processFulfillmentTimeSlot) ?? [];
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
  };

  getAvailabilityInfo = async (deliveryAddress?: Address) => {
    const response: ListAvailableFulfillmentOptionsResponse =
      await this.fetchAllAvailableFulfillments(deliveryAddress);
    return FulfillmentOptionsProcessor.process(response);
  };

  fetchAvailableDatesInRange = async ({
    deliveryAddress,
    dispatchType,
    from,
    until,
  }: {
    deliveryAddress?: Address;
    dispatchType: DispatchType;
    from: Date;
    until: Date;
  }) => {
    try {
      const data = await this.httpClient
        .request(
          listAvailableDatesInRange({
            operationId: this.operationId,
            deliveryAddress,
            from: convertDate(from),
            until: convertDate(until),
          })
        )
        .then((response) => response.data);

      return (
        data?.availableDates
          ?.filter(
            ({ fulfilmentType }) => (fulfilmentType as unknown as DispatchType) === dispatchType
          )
          .map(
            ({ dates }) =>
              dates?.map(({ year, month, day }) =>
                DateTime.fromJSDate(new Date(year as number, month as number, day as number))
              ) ?? []
          )
          .flat() ?? []
      );
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
  };

  fetchAvailableTimeSlotsForDate = async ({
    deliveryAddress,
    dispatchType,
    date,
  }: {
    deliveryAddress?: Address;
    dispatchType: DispatchType;
    date: Date;
  }) => {
    try {
      const data = await this.httpClient
        .request(
          listAvailableTimeSlotsForDate({
            operationId: this.operationId,
            deliveryAddress,
            date: convertDate(date),
          })
        )
        .then((response) => response.data);

      return (data?.timeSlots ?? [])
        .filter((slot) => (slot.fulfilmentType as unknown as DispatchType) === dispatchType)
        .map((slot) => processFulfillmentTimeSlot(slot));
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
  };
}
