import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { listOperations } from '@wix/ambassador-restaurants-operations-v1-operation/http';
import type { Operation } from '../types/businessTypes';
import { OperationAdapter } from './utils/OperationAdapter';

export class OperationsClient {
  constructor(private httpClient: IHttpClient) {}

  getOperation = async (): Promise<Operation | undefined> => {
    try {
      return this.httpClient
        .request(listOperations({}))
        .then(({ data }) => OperationAdapter((data?.operations && data.operations[0]) || {}));
    } catch (e: unknown) {
      console.error(e);
      return undefined;
    }
  };
}
