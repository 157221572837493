import { toJS } from 'mobx';
import type {
  DeliveryFulfillmentOption,
  FulfillmentOption,
  PickupFulfillmentOption,
} from '../contexts/FulfillmentContext';
import type { DispatchState } from '../states/HeaderState';
import type { Address, DispatchTime, MinMaxRange, Operation } from '../types/businessTypes';
import { SameDayScheduling, DispatchType, SchedulingType } from '../types/businessTypes';
import type { DispatchModalProps } from '../types/widgetsProps';
import { state } from '../states/RootState';
import type { IBIReporterService } from '../services/biReporterService';
import { getTimeRangeFromDispatchTime } from 'root/logic/timeUtils';
import type { CartShippingDetails, TimeRangeForCart } from 'root/services/cartService';

export const getSchedulingTypeFromOperation = (operation: Operation): SchedulingType => {
  if (operation.operationType === 'ASAP') {
    if (operation.allowSameDayPreorder) {
      return SchedulingType.SAME_DAY;
    } else {
      return SchedulingType.ASAP;
    }
  }
  return SchedulingType.PRE_ORDER;
};

export function getDispatchModalProps({
  selectedDispatchType,
  pickupState,
  deliveryState,
  operation,
  onSaveDispatch,
  availableDispatchTypes,
  availablePickup,
  configuredDispatchTypes,
  biReporterService,
}: {
  selectedDispatchType: DispatchType;
  pickupState: DispatchState;
  deliveryState: DispatchState;
  configuredDispatchTypes: DispatchType[];
  availablePickup?: PickupFulfillmentOption;
  availableDispatchTypes?: DispatchType[];
  onSaveDispatch: (form: DispatchModalForm) => void;
  operation: Operation;
  biReporterService?: IBIReporterService;
}): DispatchModalProps {
  return {
    onSave: (form) => onSaveDispatch(form),
    operationType: operation.operationType,
    schedulingType: getSchedulingTypeFromOperation(operation),
    availablePickupDispatches: availablePickup ? [availablePickup] : [],
    operationId: operation.id || '',
    selectedDispatchType,
    preorderOptions: operation.preOrderOptions,
    showPicker: !!availableDispatchTypes && availableDispatchTypes.length > 1,
    configuredDispatchTypes,
    canOrderPickupNow: !!availablePickup?.canSubmitOrderForNow,
    [DispatchType.DELIVERY]: deliveryState,
    [DispatchType.PICKUP]: pickupState,
    biReporterService,
  };
}

export interface DispatchModalForm {
  selectedDispatchType: DispatchType;
  prepTime: MinMaxRange;
  selectedTime: DispatchTime;
  selectedSameDayScheduling?: SameDayScheduling;
  address?: Address;
  availableFulfillments: FulfillmentOption[];
}

export function getCartShippingDetailsByForm(
  form: DispatchModalForm,
  timezone: string,
  pickupAddress?: Address
): CartShippingDetails {
  const { selectedDispatchType, selectedSameDayScheduling, selectedTime, address } = form;
  return getCartShippingDetails({
    timezone,
    selectedDispatchType,
    selectedSameDayScheduling,
    selectedTime,
    pickupAddress,
    deliveryAddress: address,
  });
}

export function getCartShippingDetails({
  selectedDispatchType,
  selectedSameDayScheduling,
  selectedTime,
  pickupAddress,
  deliveryAddress,
  timezone,
}: {
  selectedDispatchType: DispatchType;
  timezone: string;
  selectedSameDayScheduling?: SameDayScheduling;
  selectedTime?: DispatchTime;
  pickupAddress?: Address;
  deliveryAddress?: Address;
}): CartShippingDetails {
  let timeRange: TimeRangeForCart | undefined;
  if (selectedTime && selectedSameDayScheduling !== SameDayScheduling.ASAP) {
    const dateTimeRanges = getTimeRangeFromDispatchTime(selectedTime, timezone);
    timeRange = {
      start: dateTimeRanges.from.toUTC().valueOf(),
      end: dateTimeRanges.until.toUTC().valueOf(),
    };
  }
  return {
    address:
      selectedDispatchType === DispatchType.PICKUP ? toJS(pickupAddress) : toJS(deliveryAddress),
    dispatchType: selectedDispatchType,
    timeRange,
  };
}

export function updateStateFromForm(form: DispatchModalForm) {
  const {
    dispatchTime,
    selectedDispatchType,
    selectedSameDayScheduling,
    minimumOrder,
    [DispatchType.DELIVERY]: deliveryParams,
    prepTime,
  } = parseDispatchForm(form);
  state.isAddressSelected = true;
  state.selectedDispatchType = selectedDispatchType;
  state[selectedDispatchType].minimumOrder = minimumOrder;
  state[selectedDispatchType].prepTime = prepTime;
  state[selectedDispatchType].selectedSameDayScheduling = selectedSameDayScheduling;
  state[selectedDispatchType].dispatchTime = dispatchTime;
  if (deliveryParams) {
    state[DispatchType.DELIVERY] = {
      ...state[DispatchType.DELIVERY],
      ...deliveryParams,
    };
  }

  const selectedDispatchDetails = {
    dispatchType: selectedDispatchType,
    dispatchTime: selectedSameDayScheduling !== SameDayScheduling.ASAP ? dispatchTime : undefined,
    address: deliveryParams?.address,
  };

  state.PersistDataService?.setSelectedDispatchDetails({
    selectedDispatchDetails,
    override: true,
  });

  resetNonSelectedDispatchState();
}

function resetNonSelectedDispatchState() {
  const nonSelectedTDispatchType =
    state.selectedDispatchType === DispatchType.DELIVERY
      ? DispatchType.PICKUP
      : DispatchType.DELIVERY;
  state[nonSelectedTDispatchType] = state.initialDispatchState[nonSelectedTDispatchType];
}

export function parseDispatchForm(form: DispatchModalForm) {
  const {
    selectedTime,
    selectedDispatchType,
    address,
    selectedSameDayScheduling,
    availableFulfillments,
    prepTime,
  } = form;

  let minimumOrder: number = 0;
  let dispatchCostEstimate: number = 0;
  let freeFulfillmentPriceThreshold;
  if (selectedDispatchType === DispatchType.DELIVERY) {
    const fulfillmentOptions = availableFulfillments as DeliveryFulfillmentOption[];
    minimumOrder = getMinOrder(fulfillmentOptions);
    dispatchCostEstimate = getDeliveryFee(fulfillmentOptions);
    freeFulfillmentPriceThreshold = getFreeFulfillmentPriceThreshold(fulfillmentOptions);
  } else {
    const [availableFulfillment] = availableFulfillments;
    minimumOrder = Number(availableFulfillment.minOrderPrice);
  }

  return {
    minimumOrder,
    prepTime,
    dispatchTime: selectedTime,
    selectedDispatchType,
    selectedSameDayScheduling,
    [DispatchType.DELIVERY]: address
      ? { address, dispatchCostEstimate, freeFulfillmentPriceThreshold }
      : undefined,
  };
}

export function getMinOrder(deliveryFulfillments: DeliveryFulfillmentOption[]) {
  const deliveryFulfillment = deliveryFulfillments[0] ?? {};
  const isMinOrderTheSame = deliveryFulfillments.every(
    (d) => d.minOrderPrice === deliveryFulfillment.minOrderPrice
  );

  return isMinOrderTheSame ? Number(deliveryFulfillment.minOrderPrice) || 0 : 0;
}

export function getDeliveryFee(deliveryFulfillments: DeliveryFulfillmentOption[]) {
  const deliveryFulfillment = deliveryFulfillments[0] || {};

  const isDeliveryFeeTheSame = deliveryFulfillments.every(
    (d) => d.deliveryFee === deliveryFulfillment.deliveryFee
  );

  return isDeliveryFeeTheSame ? Number(deliveryFulfillment.deliveryFee) || 0 : 0;
}

export function getFreeFulfillmentPriceThreshold(
  deliveryFulfillments: DeliveryFulfillmentOption[]
) {
  const deliveryFulfillment = deliveryFulfillments[0] || {};

  const isDeliveryFeeTheSame = deliveryFulfillments.every(
    (d) => d.deliveryFee === deliveryFulfillment.deliveryFee
  );
  if (isDeliveryFeeTheSame && deliveryFulfillment.deliveryFee === 0) {
    return 0;
  } else {
    const isFreeDeliveryPriceThresholdTheSame = deliveryFulfillments.every(
      (d) => d.freeFulfillmentPriceThreshold === deliveryFulfillment.freeFulfillmentPriceThreshold
    );
    const freeDeliveryPriceThreshold = Number(deliveryFulfillment.freeFulfillmentPriceThreshold);

    return isFreeDeliveryPriceThresholdTheSame && freeDeliveryPriceThreshold >= 0
      ? freeDeliveryPriceThreshold
      : undefined;
  }
}
