import type { TFunction } from '@wix/yoshi-flow-editor';
import type { ModifierGroupRule, ModifierRepeaterData, NewRule, Rule } from 'root/types/modifiers';
import { ModifierRuleType, RuleType } from 'root/types/modifiers';

interface ruleUtilsArgs {
  mandatory: boolean;
  minSelections: number;
  maxSelections?: number;
}
export const isSingleSelectRule = (
  rule: ModifierGroupRule,
  isModifierGroupNewRuleExperimentEnabled: boolean
) => {
  return isModifierGroupNewRuleExperimentEnabled
    ? (rule as NewRule)?.mandatory === true &&
        (rule as NewRule)?.minSelections === 1 &&
        (rule as NewRule)?.maxSelections === 1
    : !((rule as Rule)?.type === ModifierRuleType.MULTI_SELECT);
};

export const hasNoLimit = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections < 1 && (maxSelections ? maxSelections < 1 : true);

export const canChooseOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections === 0 && (maxSelections ? maxSelections === 1 : false);

export const hasToChooseOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory && minSelections === 1 && (maxSelections ? maxSelections === 1 : false);

export const hasToChooseX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? maxSelections && minSelections === maxSelections : false;

export const hasToChooseAtLeastOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? minSelections === 1 && maxSelections === undefined : false;

export const hasToChooseAtLeastX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? minSelections > 1 && maxSelections === undefined : false;

export const canChooseUpToX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections === 0 && (maxSelections ? maxSelections > 1 : false);

export const hasToChooseUpToX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory && minSelections === 1 && (maxSelections ? maxSelections > 1 : false);

export const hasToChooseBetweenXAndY = ({
  mandatory,
  minSelections,
  maxSelections,
}: ruleUtilsArgs) =>
  mandatory ? (maxSelections ? minSelections > 1 && maxSelections > minSelections : false) : false;

export const getModifierGroupRuleType = (modifierGroupRule: ModifierGroupRule) => {
  const mandatory = (modifierGroupRule as NewRule).mandatory ?? false;
  const minSelections = (modifierGroupRule as NewRule).minSelections ?? 0;
  const maxSelections = (modifierGroupRule as NewRule).maxSelections;
  const ruleFields = { mandatory, minSelections, maxSelections };
  if (hasNoLimit(ruleFields)) {
    return RuleType.NO_LIMIT;
  }
  if (hasToChooseOne(ruleFields) || canChooseOne(ruleFields)) {
    return RuleType.CHOOSE_ONE;
  }
  if (hasToChooseX(ruleFields)) {
    return RuleType.CHOOSE_X;
  }
  if (hasToChooseAtLeastOne(ruleFields)) {
    return RuleType.CHOOSE_AT_LEAST_ONE;
  }
  if (hasToChooseAtLeastX(ruleFields)) {
    return RuleType.CHOOSE_AT_LEAST_X;
  }
  if (hasToChooseUpToX(ruleFields) || canChooseUpToX(ruleFields)) {
    return RuleType.CHOOSE_UP_TO_X;
  }
  if (hasToChooseBetweenXAndY(ruleFields)) {
    return RuleType.CHOOSE_BETWEEN_X_AND_Y;
  }
  return RuleType.NO_LIMIT;
};

export const getModifierGroupLabel = (
  t: TFunction,
  modifierRepeaterData: ModifierRepeaterData,
  isModifierGroupNewRuleExperimentEnabled: boolean
) => {
  const modifierGroupName = modifierRepeaterData.name ?? '';
  const modifierGroupRule = modifierRepeaterData.rule ?? {};
  const minSelections = (modifierGroupRule as NewRule).minSelections ?? 0;
  const maxSelections = (modifierGroupRule as NewRule).maxSelections;
  if (!isModifierGroupNewRuleExperimentEnabled) {
    return modifierGroupName;
  }
  const type = getModifierGroupRuleType(modifierRepeaterData?.rule ?? {});
  switch (type) {
    case RuleType.NO_LIMIT:
      return modifierGroupName;
    case RuleType.CHOOSE_ONE:
      return t('itemModal.modifierGroup-label.choose-one', {
        modifierGroupName,
      });
    case RuleType.CHOOSE_X:
      return t('itemModal.modifierGroup-label.choose-x', {
        modifierGroupName,
        x: minSelections,
      });
    case RuleType.CHOOSE_AT_LEAST_ONE:
      return t('itemModal.modifierGroup-label.choose-at-least-one', { modifierGroupName });
    case RuleType.CHOOSE_AT_LEAST_X:
      return t('itemModal.modifierGroup-label.choose-at-least-x', {
        modifierGroupName,
        x: minSelections,
      });
    case RuleType.CHOOSE_UP_TO_X:
      return t('itemModal.modifierGroup-label.choose-up-to-x', {
        modifierGroupName,
        x: maxSelections,
      });
    case RuleType.CHOOSE_BETWEEN_X_AND_Y:
      return t('itemModal.modifierGroup-label.choose-between-x-and-y', {
        modifierGroupName,
        x: minSelections,
        y: maxSelections,
      });
    default:
      return modifierGroupName;
  }
};

export const getModifierGroupErrorText = (
  t: TFunction,
  modifierRepeaterData: ModifierRepeaterData,
  isModifierGroupNewRuleExperimentEnabled: boolean
) => {
  if (!isModifierGroupNewRuleExperimentEnabled) {
    return '';
  }
  const modifierGroupRule = modifierRepeaterData?.rule ?? {};
  const minSelections = (modifierGroupRule as NewRule).minSelections ?? 0;
  const maxSelections = (modifierGroupRule as NewRule).maxSelections;
  const type = getModifierGroupRuleType(modifierGroupRule);
  switch (type) {
    case RuleType.CHOOSE_ONE:
      return t('itemModal.modifierGroup-error.choose-one');
    case RuleType.CHOOSE_X:
      return t('itemModal.modifierGroup-error.choose-x', { x: minSelections });
    case RuleType.CHOOSE_AT_LEAST_ONE:
      return t('itemModal.modifierGroup-error.choose-at-least-one');
    case RuleType.CHOOSE_AT_LEAST_X:
      return t('itemModal.modifierGroup-error.choose-at-least-x', { x: minSelections });
    case RuleType.CHOOSE_UP_TO_X:
      return t('itemModal.modifierGroup-error.choose-up-to-x', { x: maxSelections });
    case RuleType.CHOOSE_BETWEEN_X_AND_Y:
      return t('itemModal.modifierGroup-error.choose-between-x-and-y', {
        x: minSelections,
        y: maxSelections,
      });
    default:
      return '';
  }
};
