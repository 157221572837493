import type { DispatchForm, DispatchModalState } from '../state/DispatchModalState';
import { ErrorType } from '../state/DispatchModalState';
import type { FulfillmentsClient } from '../../../api/fulfillmentsClient';
import type {
  AddressInputAddress,
  Operation,
  PreorderScheduling,
  Address,
  OperationType,
} from '../../../types/businessTypes';
import { DispatchType, SchedulingType } from '../../../types/businessTypes';
import type { AddressInputProps } from '../dispatchModalBindings';
import {
  convertAddressInputToAddress,
  convertAddressToAddressInput,
} from '../../Header/headerUtils';
import type { Experiments, ISiteApis, TFunction } from '@wix/yoshi-flow-editor';
import { toJS } from 'mobx';
import type { ProcessResult } from '../../../api/utils/FulfillmentOptionsProcessor';
import type { DispatchState } from '../../../states/HeaderState';
import { getDeliveryInitialDispatchState } from '../../../states/HeaderState';
import { getInitialDispatchForm } from '../state/initialStateLogic';
import { getWeeklyAvailabilityForFulfillmentOptionsMemo } from '../../../logic/timeUtils';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { IBIReporterService } from '../../../services/biReporterService';
import { getPreorderTimeWindows } from 'root/logic/getPreorderTimeWindows';
import { DEFAULT_PREORDER_TIME_WINDOW_DURATION } from 'root/components/DispatchModal/state/defaults';

export async function setAddressOptions({
  address,
  state,
  fulfillmentsClient,
  timezone,
  site,
  t,
  biReporterService,
  operation,
  experiments,
  schedulingType,
}: {
  address: Address;
  state: DispatchModalState;
  fulfillmentsClient: FulfillmentsClient;
  timezone: string;
  site: ISiteApis;
  t: TFunction;
  biReporterService?: IBIReporterService;
  operation: Partial<Operation>;
  experiments: Experiments;
  schedulingType?: SchedulingType;
}) {
  state.setAddress(address);

  if (address.geocode) {
    const response: ProcessResult = await fulfillmentsClient.getAvailabilityInfo(address);
    const delivery = response.availableDispatches[DispatchType.DELIVERY].fulfillments;
    if (!delivery.length) {
      const errorType = ErrorType.NO_FULFILLMENTS;
      const errorMessage = t('menu_olo.dispatchModal.errorMessage');
      biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        errorType,
        errorMessage,
      });
      state.setError({
        type: errorType,
        message: errorMessage,
      });
      return;
    }
    state.setError(undefined);
    updateStateOnValidAddressSelect({
      operation,
      response,
      state,
      timezone,
      site,
      t,
      preOrderOptions: operation.preOrderOptions,
      experiments,
      schedulingType,
    });
  } else {
    biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
      dispatchType: state.dispatchType,
      errorType: ErrorType.ADDRESS_NOT_FOUND,
    });
    state.setError({
      type: ErrorType.ADDRESS_NOT_FOUND,
    });
  }
}

function updateStateOnValidAddressSelect({
  response,
  state,
  site,
  timezone,
  preOrderOptions,
  t,
  operation,
  experiments,
  schedulingType,
}: {
  response: ProcessResult;
  state: DispatchModalState;
  site: ISiteApis;
  timezone: string;
  t: TFunction;
  preOrderOptions?: PreorderScheduling;
  operation: Partial<Operation>;
  experiments: Experiments;
  schedulingType?: SchedulingType;
}) {
  const delivery = response.availableDispatches[DispatchType.DELIVERY].fulfillments;
  const preOrderTimeWindows = getPreorderTimeWindows(
    response.availableDispatches,
    preOrderOptions?.timeWindowDuration || DEFAULT_PREORDER_TIME_WINDOW_DURATION
  ).DELIVERY;
  const newDeliveryState: DispatchState = getDeliveryInitialDispatchState({
    deliveryFulfillments: delivery,
    timezone,
    operation,
    weeklyAvailability: getWeeklyAvailabilityForFulfillmentOptionsMemo(delivery),
    prepTime: response.availableDispatches[DispatchType.DELIVERY].prepTime,
    availableDispatches: response.availableDispatches,
    overrides: {
      preOrderTimeWindows,
      dispatchTime: {
        ...state.selectedTime,
        date: state.selectedDate,
      },
    },
  });
  const newDeliveryForm: DispatchForm = getInitialDispatchForm({
    dispatchState: newDeliveryState,
    availableDispatches: delivery,
    preorderOptions: preOrderOptions,
    operationType: operation.operationType as OperationType,
    t,
    site,
    experiments,
    schedulingType: schedulingType || SchedulingType.SAME_DAY,
  });
  state.resetState({ ...newDeliveryForm, address: state.address });
}
export function getAddressInputProps(
  state: DispatchModalState,
  t: TFunction,
  schedulingType: SchedulingType,
  setCurrentDeliveryAddressAndOptions: (address?: Address) => void,
  biReporterService?: IBIReporterService
): AddressInputProps {
  return {
    onClick: (evt: $w.Event) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.ADDRESS_INPUT,
      });
    },
    onChange: async (evt: $w.Event) => {
      const address = convertAddressInputToAddress(evt.target.value as AddressInputAddress);
      setCurrentDeliveryAddressAndOptions(address);
    },
    value: () => convertAddressToAddressInput(toJS(state[DispatchType.DELIVERY].address)),
    label: () => t('menu_olo.dispatchModal.delivery.to'),
    placeholder: () => t('menu_olo.dispatchModal.delivery.addressInput.placeholder'),
  };
}
