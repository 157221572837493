import type { Address, AddressInputAddress, AsapScheduling } from '../../types/businessTypes';
import { DispatchType, SameDayScheduling } from '../../types/businessTypes';
import { convertMinutesToDisplayableTime } from '../../logic/timeUtils';
import type { HeaderState } from '../../states/HeaderState';
import type {
  AddressChangeProps,
  AddressInfoProps,
  DeliveryFeeProps,
  DispatchPickerProps,
  HeaderContainerProps,
  MinOrderProps,
  TimeChangeProps,
  TimeInfoProps,
} from './headerBindings';
import { state as rootState } from '../../states/RootState';
import type { Pubsub } from '../../utils/pubsub';
import type { DispatchModalProps } from '../../types/widgetsProps';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { getASAPTimeString } from '../../utils/operationUtils';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import type { FulfillmentMethod } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';
import { FulfillmentMethodType } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';

export const STATUS_INDICATOR_COLORS = {
  ONLINE: '#43B72A',
  OFFLINE: '#DF3131',
};

export const getDisplayStringFromAddress = (address: Address) => {
  return address.formattedAddress;
};

export function convertAddressInputToAddress(address: AddressInputAddress): Address {
  const { formatted, location, ...rest } = address;
  return {
    formattedAddress: formatted,
    geocode: location,
    ...rest,
  };
}
export function convertAddressToAddressInput(address?: Address): AddressInputAddress | undefined {
  if (!address) {
    return undefined;
  }
  const mutualFields = ['country', 'streetAddress', 'city', 'subdivision', 'postalCode'];
  const { formattedAddress, geocode } = address as Address;
  return mutualFields.reduce(
    (result, key) => {
      // @ts-expect-error
      result[key] = address[key];
      return result;
    },
    { formatted: formattedAddress || '', location: geocode }
  );
}

export function getHeaderContainerProps(pubsub: Pubsub): HeaderContainerProps {
  return {
    onViewportEnter: () => pubsub.publish('onHeaderViewportEnter'),
    onViewportLeave: () => pubsub.publish('onHeaderViewportLeave'),
  };
}

export function getDispatchPickerProps(
  configuredDispatchTypes: DispatchType[],
  dispatchModalProps: () => DispatchModalProps,
  isMemberLoggedIn?: boolean
): DispatchPickerProps {
  return {
    collapsed: () => configuredDispatchTypes.length === 0,
    data: () => ({
      configuredDispatchTypes,
      availableDispatchTypes: rootState.availableDispatchTypes,
      selectedDispatchType: rootState.selectedDispatchType,
      onDispatchTypeChange: (dispatchType: DispatchType) => {
        rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
          origin: LiveSiteClickFulfillmentOrigin.FULFILLMENT_TAB,
          dispatchType,
          isMemberLoggedIn,
        });
        rootState.ModalService?.openDispatchModal({
          ...dispatchModalProps(),
          selectedDispatchType: dispatchType,
        });
      },
    }),
  };
}

export function getStatusIndicatorProps(canAcceptOrders: boolean) {
  const backgroundColor = canAcceptOrders
    ? STATUS_INDICATOR_COLORS.ONLINE
    : STATUS_INDICATOR_COLORS.OFFLINE;
  return {
    style: {
      backgroundColor: () => backgroundColor,
    },
  };
}

export function getAcceptOrdersProps(canAcceptOrders: boolean, t: TFunction) {
  return {
    text: () =>
      canAcceptOrders
        ? t('header_olo.status.AcceptingOrders')
        : t('header_olo.status.NotAcceptingOrders'),
  };
}

export function getMinOrderProps(t: TFunction, formatCurrency: Function): MinOrderProps {
  return {
    collapsed: () => !getMinOrder(),
    text: () => {
      const minOrder = getMinOrder();
      const formattedMin = minOrder
        ? formatCurrency({
            value: minOrder.toString(),
            currency: rootState.currency,
          })
        : '';
      return t('header_olo.minOrder.exact', { amount: formattedMin });
    },
  };
}

function getMinOrder() {
  let minOrder;
  if (rootState.selectedDispatchType) {
    minOrder = rootState[rootState.selectedDispatchType].minimumOrder;
  } else {
    const fulfillments = getFulfillments();
    if (fulfillments.length > 0) {
      const minimumOrderAmount = fulfillments[0].minimumOrderAmount || 0;
      const isMinOrderTheSame = fulfillments.every(
        (d) => d.minimumOrderAmount === minimumOrderAmount
      );
      minOrder = isMinOrderTheSame ? Number(minimumOrderAmount) : 0;
    }
  }
  return minOrder;
}

function getFulfillments() {
  let fulfillments: FulfillmentMethod[] = [];
  const isPickupConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.PICKUP);
  const isDeliveryConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.DELIVERY);

  if (isPickupConfigured) {
    fulfillments = rootState.allFulfillments.filter((f) => f.type === FulfillmentMethodType.PICKUP);
  } else if (isDeliveryConfigured) {
    fulfillments = rootState.allFulfillments.filter(
      (f) => f.type === FulfillmentMethodType.DELIVERY && f.enabled
    );
  }
  return fulfillments;
}

export function getDeliveryFeeProps(t: TFunction, formatCurrency: Function): DeliveryFeeProps {
  return {
    freeDeliveryText: () => {
      const freeFulfillmentPriceThreshold = getFreeDeliveryInfo();

      return freeFulfillmentPriceThreshold !== undefined
        ? getFreeDeliveryText(freeFulfillmentPriceThreshold, t, formatCurrency)
        : '';
    },
    freeDeliveryCollapse: () => {
      const freeFulfillmentPriceThreshold = getFreeDeliveryInfo();

      return freeFulfillmentPriceThreshold === undefined;
    },
    collapsed: () => {
      const deliveryFee = getFee();
      const isFreeDelivery =
        rootState[rootState.selectedDispatchType]?.freeFulfillmentPriceThreshold === 0;
      return deliveryFee === 0 || isFreeDelivery;
    },
    text: () => {
      const dispatchCostEstimate = getFee();
      return dispatchCostEstimate
        ? getDeliveryFeeText(dispatchCostEstimate, t, formatCurrency)
        : '';
    },
  };
}

function getFee(): number {
  const isPickupConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.PICKUP);
  const isDeliveryConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.DELIVERY);

  let deliveryFee;
  if (rootState.selectedDispatchType === DispatchType.DELIVERY) {
    deliveryFee = rootState[rootState.selectedDispatchType].dispatchCostEstimate;
  } else if (!rootState.selectedDispatchType && !isPickupConfigured && isDeliveryConfigured) {
    const fulfillments = rootState.allFulfillments.filter(
      (f) => f.type === FulfillmentMethodType.DELIVERY && f.enabled
    );
    if (fulfillments.length > 0) {
      const fee = fulfillments[0].fee || 0;
      const isFeeTheSame = fulfillments.every((d) => d.fee === fee);
      deliveryFee = isFeeTheSame ? fee : 0;
    }
  }
  return Number(deliveryFee || 0);
}

function getFreeDeliveryInfo(): number | undefined {
  const isPickupConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.PICKUP);
  const isDeliveryConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.DELIVERY);

  let freeFulfillmentPriceThreshold;
  if (rootState.selectedDispatchType === DispatchType.DELIVERY) {
    freeFulfillmentPriceThreshold =
      rootState[rootState.selectedDispatchType].freeFulfillmentPriceThreshold;
  } else if (!rootState.selectedDispatchType && !isPickupConfigured && isDeliveryConfigured) {
    const fulfillments = rootState.allFulfillments.filter(
      (f) => f.type === FulfillmentMethodType.DELIVERY && f.enabled
    );
    if (fulfillments.length > 0) {
      const deliveryFulfillment = fulfillments[0] || {};

      const isDeliveryFeeTheSame = fulfillments.every((d) => d.fee === deliveryFulfillment.fee);
      if (isDeliveryFeeTheSame && Number(deliveryFulfillment.fee || 0) === 0) {
        freeFulfillmentPriceThreshold = 0;
      } else {
        const isFreeDeliveryPriceThresholdTheSame = fulfillments.every(
          (d) =>
            d.deliveryOptions?.freeDeliveryThreshold ===
            deliveryFulfillment.deliveryOptions?.freeDeliveryThreshold
        );
        const freeDeliveryPriceThreshold = Number(
          deliveryFulfillment.deliveryOptions?.freeDeliveryThreshold
        );

        freeFulfillmentPriceThreshold =
          isFreeDeliveryPriceThresholdTheSame && freeDeliveryPriceThreshold >= 0
            ? freeDeliveryPriceThreshold
            : undefined;
      }
    }
  }

  return freeFulfillmentPriceThreshold !== undefined
    ? Number(freeFulfillmentPriceThreshold)
    : undefined;
}

function getDeliveryFeeText(dispatchCostEstimate: number, t: TFunction, formatCurrency: Function) {
  const formatted = formatCurrency({
    value: dispatchCostEstimate.toString(),
    currency: rootState.currency,
  });

  return dispatchCostEstimate === 0
    ? t('header_olo.deliveryFee.free')
    : t('header_olo.deliveryFee.exact', { amount: formatted });
}

function getFreeDeliveryText(
  freeFulfillmentPriceThreshold: number,
  t: TFunction,
  formatCurrency: Function
) {
  const formatted = formatCurrency({
    value: freeFulfillmentPriceThreshold.toString(),
    currency: rootState.currency,
  });

  return freeFulfillmentPriceThreshold === 0
    ? t('header_olo.deliveryFee.free')
    : t('header_olo.deliveryFee.freeAbove', { amount: formatted });
}

export function getAddressInfoProps(t: TFunction): AddressInfoProps {
  const getAddress = () => {
    const isPickupConfigured = rootState.configuredDispatchTypes?.includes(DispatchType.PICKUP);
    if (rootState.selectedDispatchType) {
      return rootState[rootState.selectedDispatchType]?.address;
    } else if (isPickupConfigured) {
      const fulfillments = getFulfillments();
      return fulfillments[0]?.pickupOptions?.address;
    } else {
      return undefined;
    }
  };
  const getTranslatedPrefix = () =>
    rootState.selectedDispatchType === DispatchType.DELIVERY
      ? t('header_olo.address.delivery')
      : t('header_olo.address.pickup');

  return {
    collapsed: () => {
      const address = getAddress()?.formattedAddress;
      return (
        (!rootState.canAcceptOrders && rootState.selectedDispatchType === DispatchType.DELIVERY) ||
        rootState.configuredDispatchTypes?.length === 0 ||
        (rootState.selectedDispatchType !== DispatchType.DELIVERY && !address)
      );
    },
    text: () => {
      const translatedPrefix = getTranslatedPrefix();
      const address = getAddress();
      return translatedPrefix + (address?.formattedAddress || '');
    },
  };
}

export function getTimeInfoProps(
  state: HeaderState,
  t: TFunction,
  canAcceptOrders: boolean,
  timezone: string,
  locale: string,
  asapOptions?: AsapScheduling
): TimeInfoProps {
  return {
    collapsed: () => !canAcceptOrders,
    displayableTimeInfo: () => getTimeInfoText(state, t, timezone, locale, asapOptions),
  };
}

function getTimeInfoText(
  state: HeaderState,
  t: TFunction,
  timezone: string,
  locale: string,
  asapOptions?: AsapScheduling
) {
  let displayableTime;
  const prefix = t(`header_olo.time.${state.selectedDispatchType}`);
  const isASAP = asapOptions && state.selectedSameDayScheduling === SameDayScheduling.ASAP;
  if (isASAP) {
    displayableTime = getASAPTimeString(t, rootState[state.selectedDispatchType].prepTime);
    return { displayableTime, timeInfoText: `${prefix}${displayableTime}` };
  }
  displayableTime =
    state.dispatchTime &&
    convertMinutesToDisplayableTime({
      timezone,
      minute: state.dispatchTime,
      locale,
      justMinutes: false,
      t,
    });
  return displayableTime
    ? { displayableTime, timeInfoText: `${prefix}${displayableTime}` }
    : { timeInfoText: '' };
}

export function getAddressChangeProps(
  state: HeaderState,
  t: TFunction,
  dispatchModalProps: () => DispatchModalProps,
  isMemberLoggedIn?: boolean
): AddressChangeProps {
  return {
    label: () =>
      state.address
        ? t('header_olo.address.delivery.change')
        : t('header_olo.address.delivery.add'),
    onClick: () => {
      rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
        origin: LiveSiteClickFulfillmentOrigin.CHANGE_ADDRESS,
        isMemberLoggedIn,
      });
      rootState.ModalService?.openDispatchModal(dispatchModalProps());
    },
    collapsed: () => rootState.selectedDispatchType !== DispatchType.DELIVERY,
  };
}
export function getTimeChangeProps(
  t: TFunction,
  dispatchModalProps: () => DispatchModalProps,
  isMemberLoggedIn?: boolean
): TimeChangeProps {
  const { operationType, allowSameDayPreorder } = rootState.operation || {};
  const isAsapOnly = operationType === 'ASAP' && !allowSameDayPreorder;

  return {
    label: () => t('header_olo.time.change'),
    collapsed: () => isAsapOnly,
    onClick: () => {
      rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
        origin: LiveSiteClickFulfillmentOrigin.CHANGE_TIME,
        isMemberLoggedIn,
      });
      rootState.ModalService?.openDispatchModal(dispatchModalProps());
    },
  };
}
