import type model from './model';
import type { I$W, TFunction, IWixWindow } from '@wix/yoshi-flow-editor';
import { ITEM_MODAL_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { buildImgSrc, getAltText } from '../Dishes/utils';
import { LABELS_LIMIT } from '../../api/consts';
import type {
  ModifierGroupListItem,
  ModifierListItem,
  ModifierRepeaterData,
  NewRule,
} from '../../types/modifiers';
import type { DishModalProps } from '../../types/widgetsProps';
import type { ItemData } from '../../types/item';
import type {
  ADD_TO_CART_ERRORS,
  CartLineItem,
  CatalogReferenceModifierGroup,
  CatalogReferencePriceVariant,
} from '../../services/cartService';
import {
  getVariantById,
  getVariantWithMinimalPrice,
  sumAdditionalCharges,
} from '../../utils/modifiersUtils';
import { MODIFIER_RULE_STATES } from '../../types/businessTypes';
import { state, DEFAULT_QUANTITY } from '../../states/ItemModalState';
import type { ControllerParams } from '../../types/widgets';
import {
  getModifierGroupErrorText,
  getModifierGroupLabel,
  isSingleSelectRule,
} from 'root/utils/modifierGroupUtils';

type BindAll = ControllerParams<typeof model>['$bindAll'];
type Bind = ControllerParams<typeof model>['$bind'];

export class ItemModalController {
  constructor(
    private $bindAll: BindAll,
    private $bind: Bind,
    private $w: I$W,
    private t: TFunction,
    private formatCurrency: Function,
    private window: IWixWindow
  ) {}

  initEditItem = (cartItem: CartLineItem, priceVariants: ModifierListItem[] | undefined) => {
    const { quantity, options } = cartItem;
    const { specialRequests, priceVariant } = options ?? {};
    state.quantity = quantity || DEFAULT_QUANTITY;
    state.specialRequests = specialRequests ?? '';
    if (priceVariant?.id) {
      const selectedPriceVariant = getVariantById({
        modifiersListItem: priceVariants,
        variantId: priceVariant.id,
      });
      if (selectedPriceVariant) {
        state.selectedPriceVariant = selectedPriceVariant;
      }
    }
  };

  init(
    dishModalProps: DishModalProps,
    onModalOpen: () => void,
    closeModal: (
      window: IWixWindow,
      dataPromise: Promise<{ cartItem?: CartLineItem; error?: ADD_TO_CART_ERRORS }> | undefined,
      cartLineItem?: CartLineItem
    ) => void,
    priceVariants?: ModifierListItem[],
    modifierGroups?: ModifierGroupListItem[],
    isModifierGroupNewRuleExperimentEnabled?: boolean
  ) {
    const {
      item,
      cartService,
      biReporterService,
      operationId,
      canAcceptOrders,
      cartItem,
      menuId,
      sectionId,
    } = dishModalProps;

    if (item) {
      state.amount = item.price.amount;
      state.currency = item.price.currency;
      const isOutOfStockItem = !!item.orderSettings?.outOfStock;
      const isValidToContinue = canAcceptOrders && !isOutOfStockItem;

      if (cartItem) {
        this.initEditItem(cartItem, priceVariants);
      }

      if (priceVariants) {
        const variantWithMinimalPriceString = state.selectedPriceVariant.id
          ? state.selectedPriceVariant.price
          : getVariantWithMinimalPrice({
              modifiersListItem: priceVariants,
            })?.price;
        const variantWithMinimalPrice = Number(variantWithMinimalPriceString || 0);
        state.amount = variantWithMinimalPrice;
        state.basePrice = variantWithMinimalPrice;
      }

      const imageProps = item.image?.url
        ? {
            type: 'image',
            src: item.image && (buildImgSrc(item.image) as string),
            alt: getAltText({ itemName: item.name, t: this.t }),
          }
        : { type: 'image', src: '' };
      const acceptSpecialRequests = item.orderSettings?.acceptSpecialRequests;

      const hasLabels = item.labels?.length;
      const hasPriceVariants = Boolean(priceVariants?.length);
      const hasModifierGroups = Boolean(modifierGroups?.length);

      hasLabels && this.initLabels(item);
      priceVariants && this.initPriceVariants(priceVariants);
      modifierGroups && this.initModifiers(modifierGroups, isModifierGroupNewRuleExperimentEnabled);

      this.$w(ITEM_MODAL_COMPONENT_IDS.counterInput).hideNumberSpinner();

      this.$bindAll({
        [ITEM_MODAL_COMPONENT_IDS.counterContainer]: {
          collapsed: () => !isValidToContinue,
        },
        [ITEM_MODAL_COMPONENT_IDS.counterInput]: {
          value: () => state.quantity.toString(),
          onInput: (evt: $w.Event) => (state.quantity = Number(evt.target.value)),
          onChange: (evt: $w.Event) => {
            const quantity = Number(evt.target.value);
            state.quantity = quantity < 1 ? DEFAULT_QUANTITY : quantity;
          },
        },
        [ITEM_MODAL_COMPONENT_IDS.increaseButton]: {
          collapsed: () => !isValidToContinue,
          onClick: () => state.quantity++,
        },
        [ITEM_MODAL_COMPONENT_IDS.decreaseButton]: {
          collapsed: () => !isValidToContinue,
          disabled: () => state.quantity === 1,
          onClick: () => state.quantity--,
        },
        [ITEM_MODAL_COMPONENT_IDS.title]: {
          text: () => item.name || '',
        },
        [ITEM_MODAL_COMPONENT_IDS.subTitle]: {
          text: () => item.description || '',
          collapsed: () => !item.description,
        },
        [ITEM_MODAL_COMPONENT_IDS.addToCart]: {
          onClick: async () => {
            const selectedModifierGroups = this.getModifierGroupsOptions(state.selectedModifiers);
            const shouldAddToCart =
              isModifierGroupNewRuleExperimentEnabled && modifierGroups
                ? this.validateSelectedModifierGroups(modifierGroups)
                : true;
            let cartResponsePromise;
            if (shouldAddToCart) {
              const options = {
                priceVariant: this.getPriceVariantOptions(state.selectedPriceVariant),
                modifierGroups: selectedModifierGroups,
                operationId,
                menuSectionId: sectionId,
                menuId,
                specialRequests: state.specialRequests !== '' ? state.specialRequests : undefined,
              };
              const cartLineItem = {
                catalogItemId: item._id ?? item.id,
                quantity: state.quantity,
                options,
              };
              cartResponsePromise = cartService?.addItemToCart(
                cartLineItem,
                cartItem?.id ?? undefined
              );
              closeModal(this.window, cartResponsePromise, cartLineItem);
            }
            const numSelectedModifiers =
              selectedModifierGroups?.reduce(
                (prevValue, currValue) => prevValue + (currValue.modifiers?.length || 0),
                0
              ) || 0;

            const value = await cartResponsePromise;

            biReporterService?.reportOloLiveSiteAddDishToCartBiEvent({
              itemName: item.name,
              itemId: item._id ?? item.id,
              menuId,
              sectionId,
              num_labels: item.labels?.length || 0,
              labelsList: JSON.stringify(item.labels || []),
              amountOfItems: state.quantity,
              numSelectedModifiers,
              priceVariationName: state.selectedPriceVariant.name,
              selectedModifiersList: JSON.stringify(state.selectedModifiers),
              itemPrice: state.amount * state.quantity,
              isSuccess: Boolean(!value?.error),
            });
          },
          label: () =>
            this.getAddToCartButtonText(
              item,
              state.quantity,
              state.amount,
              state.currency,
              isValidToContinue,
              !!cartItem
            ),
          disabled: () => !isValidToContinue,
        },
        [ITEM_MODAL_COMPONENT_IDS.close]: {
          onClick: async () => closeModal(this.window, undefined),
        },
        [ITEM_MODAL_COMPONENT_IDS.proGallery]: {
          items: () => [imageProps],
          collapsed: () => !item.image?.url,
        },
        [ITEM_MODAL_COMPONENT_IDS.labelsContainer]: {
          hidden: () => !hasLabels,
          collapsed: () => !hasLabels,
        },
        [ITEM_MODAL_COMPONENT_IDS.specialRequest]: {
          placeholder: () => this.t('itemModal.specialRequests.placeholder'),
          label: () => this.t('itemModal.specialRequests.label'),
          collapsed: () => !acceptSpecialRequests,
          disabled: () => !isValidToContinue,
          onChange: (evt: $w.Event) => (state.specialRequests = evt.target.value),
          value: () => state.specialRequests,
        },
        [ITEM_MODAL_COMPONENT_IDS.quantityText]: {
          text: () => this.t('itemModal.quantity.text'),
        },
        [ITEM_MODAL_COMPONENT_IDS.priceVariantsSingleSelect]: {
          collapsed: () => !hasPriceVariants,
        },
        [ITEM_MODAL_COMPONENT_IDS.modifiersContainer]: {
          collapsed: () => !hasModifierGroups,
        },
      });
      onModalOpen();
      const numOfModifiers = modifierGroups?.reduce(
        (prevValue, currValue) => prevValue + (currValue.modifiers?.length || 0),
        0
      );
      biReporterService?.reportOloLiveSiteItemModalOpenedBiEvent({
        itemName: item.name,
        itemId: item._id ?? item.id,
        menuId,
        sectionId,
        minItemPrice: state.amount || 0,
        num_labels: item.labels?.length || 0,
        numModifiers: numOfModifiers || 0,
        numModifiersGroups: modifierGroups?.length || 0,
        numPriceVariations: priceVariants?.length || 0,
      });
    }
  }

  getFormattedPriceVariantLabel(name: string, price: string) {
    return `${name} (${price})`;
  }

  getFormattedModifierLabel(name: string, price: string, isAdditionalCharge?: boolean) {
    return isAdditionalCharge ? `${name} (+${price})` : name;
  }

  getAddToCartButtonText(
    item: ItemData,
    quantity: number,
    amount: number,
    currency: string,
    isValidToContinue: boolean,
    editMode: boolean
  ): string {
    const isOutOfStockItem = item.orderSettings?.outOfStock;
    const formattedPrice = this.formatCurrency({
      value: quantity * amount,
      currency,
    });

    if (isOutOfStockItem) {
      return this.t('itemModal.outOfStock.addToCartButton', {
        price: formattedPrice,
      });
    }

    if (!isValidToContinue) {
      return this.t('itemModal.notValid.addToCartButton', {
        price: formattedPrice,
      });
    }

    if (editMode) {
      return this.t('itemModal.editMode.addToCartButton', {
        price: formattedPrice,
      });
    }

    return this.t('itemModal.addToCartButton', { price: formattedPrice });
  }

  initLabels(item: ItemData) {
    for (let i = 0; i < LABELS_LIMIT; i++) {
      const currentLabel = item.labels?.[i];
      const currentLabelIconElement = ITEM_MODAL_COMPONENT_IDS.labelIcon(i + 1);
      const currentLabelNameElement = ITEM_MODAL_COMPONENT_IDS.labelName(i + 1);

      this.$bindAll({
        [currentLabelIconElement]: {
          collapsed: () => !currentLabel,
        },
        [currentLabelNameElement]: {
          collapsed: () => !currentLabel,
        },
      });

      if (currentLabel) {
        const iconSrc = currentLabel.icon?.url ? { src: () => currentLabel.icon?.url } : {};

        this.$bindAll({
          [currentLabelIconElement]: {
            ...iconSrc,
            collapsed: () => !currentLabel?.icon?.url,
          },
          [currentLabelNameElement]: {
            text: () => currentLabel.name,
            collapsed: () => !currentLabel.name,
          },
        });
      }
    }
  }

  getSelectedPriceVariantIndex = (
    priceVariants: ModifierListItem[],
    selectedPriceVariant: string
  ) => {
    const selectedIndex = priceVariants.findIndex(({ id }) => id === selectedPriceVariant);
    return selectedIndex > 0 ? selectedIndex : 0;
  };

  getModifiersOptions(modifiers: ModifierListItem[], currency: string) {
    return modifiers?.map((modifier) => {
      const isAdditionalCharge = Number(modifier.price) > 0;
      const formattedPrice = this.formatCurrency({
        value: modifier.price,
        currency,
      });
      const label = this.getFormattedModifierLabel(
        modifier.name ?? '',
        formattedPrice,
        isAdditionalCharge
      );
      return {
        label,
        value: modifier.id,
      };
    });
  }

  initPriceVariants(priceVariants: ModifierListItem[]) {
    if (!state.selectedPriceVariant.id) {
      state.selectedPriceVariant =
        priceVariants &&
        (getVariantWithMinimalPrice({
          modifiersListItem: priceVariants,
        }) as ModifierListItem);
    }
    const priceVariantsOptions = priceVariants.map((priceVariant) => {
      const formattedPrice = this.formatCurrency({
        value: priceVariant.price,
        currency: state.currency,
      });
      const label = this.getFormattedPriceVariantLabel(priceVariant.name ?? '', formattedPrice);
      return {
        label,
        value: priceVariant.id,
      };
    });
    const currentPriceVariantElement = ITEM_MODAL_COMPONENT_IDS.priceVariantsSingleSelect;

    this.$bindAll({
      [currentPriceVariantElement]: {
        collapsed: () => !priceVariants,
      },
    });

    if (priceVariants) {
      this.$bindAll({
        [currentPriceVariantElement]: {
          options: () => priceVariantsOptions,
          label: () => this.t('menu-olo.item-modal.price-variants'),
          required: () => true,
          onChange: (event: $w.Event) => {
            state.selectedPriceVariant =
              priceVariants.find((priceVariant) => {
                return priceVariant.id === event.target.value;
              }) ?? {};
            const price = priceVariants.find((priceVariant) => {
              return priceVariant.id === state.selectedPriceVariant.id;
            })?.price;
            state.amount = state.additionalCharge + Number(price);
            state.basePrice = Number(price);
          },
          selectedIndex: () =>
            this.getSelectedPriceVariantIndex(priceVariants, state.selectedPriceVariant.id ?? ''),
        },
      });
    }
  }

  getModifierListItem(modifiers: ModifierListItem[], value: string) {
    return modifiers.find((modifier) => value === modifier.id);
  }

  calculateAddToCartButtonPrice() {
    const sum = sumAdditionalCharges(state.selectedModifiers);
    state.amount = state.amount - state.additionalCharge + sum;
    state.additionalCharge = sum;
  }

  getSelectedIndex(modifierRepeaterData: ModifierRepeaterData, isMultiSelectItem: boolean) {
    const firstModifier = modifierRepeaterData.modifiers?.[0];
    if (firstModifier && modifierRepeaterData.id) {
      state.selectedModifiers = {
        ...state.selectedModifiers,
        [modifierRepeaterData.id]: [firstModifier],
      };
    }
    this.calculateAddToCartButtonPrice();
    return isMultiSelectItem ? [0] : 0;
  }

  initModifiers(
    modifierGroups?: ModifierGroupListItem[],
    isModifierGroupNewRuleExperimentEnabled?: boolean
  ) {
    this.$bind(ITEM_MODAL_COMPONENT_IDS.modifiersContainer, {
      data: () =>
        modifierGroups?.map((modifierRepeater) => ({
          _id: modifierRepeater.id,
          ...modifierRepeater,
        })) || [],
      item: (modifierRepeaterData: ModifierRepeaterData, bindItem: Bind) => {
        const isMultiSelectItem = !isSingleSelectRule(
          modifierRepeaterData.rule ?? {},
          isModifierGroupNewRuleExperimentEnabled ?? false
        );
        const selectionComponentId = isMultiSelectItem
          ? ITEM_MODAL_COMPONENT_IDS.multiSelect
          : ITEM_MODAL_COMPONENT_IDS.singleSelect;
        const modifiersOptions = this.getModifiersOptions(
          modifierRepeaterData.modifiers ?? [],
          state.currency
        );
        const isRequiredGroup = modifierRepeaterData.rule?.mandatory;
        if (isMultiSelectItem) {
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersMultiState, {
            currentState: () => MODIFIER_RULE_STATES.multiSelect,
          });
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersError, {
            collapsed: () => !state.modifiersErrors[modifierRepeaterData.id],
            hidden: () => !state.modifiersErrors[modifierRepeaterData.id],
          });
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersErrorText, {
            text: () =>
              getModifierGroupErrorText(
                this.t,
                modifierRepeaterData,
                isModifierGroupNewRuleExperimentEnabled ?? false
              ),
          });
        }
        bindItem(selectionComponentId, {
          options: () => modifiersOptions,
          required: () => !!isRequiredGroup,
          label: () =>
            getModifierGroupLabel(
              this.t,
              modifierRepeaterData,
              isModifierGroupNewRuleExperimentEnabled ?? false
            ),
          onChange: (event: $w.Event) => {
            const modifierListItems = isMultiSelectItem
              ? event.target.value.map((value: string) =>
                  this.getModifierListItem(modifierRepeaterData?.modifiers || [], value)
                )
              : [
                  this.getModifierListItem(
                    modifierRepeaterData?.modifiers || [],
                    event.target.value
                  ),
                ];

            state.selectedModifiers = {
              ...state.selectedModifiers,
              [modifierRepeaterData?.id]: modifierListItems,
            };
            this.calculateAddToCartButtonPrice();
          },
          selectedIndex: isRequiredGroup
            ? () => this.getSelectedIndex(modifierRepeaterData, isMultiSelectItem) as number
            : undefined,
          selectedIndices: isRequiredGroup
            ? () => this.getSelectedIndex(modifierRepeaterData, isMultiSelectItem) as number[]
            : undefined,
        });
      },
    });
  }

  getPriceVariantOptions(selectedPriceVariant: ModifierListItem) {
    return selectedPriceVariant?.id
      ? ({
          id: state.selectedPriceVariant.id,
          formattedPrice: this.formatCurrency({
            value: state.selectedPriceVariant.price,
            currency: state.currency,
          }),
        } as CatalogReferencePriceVariant)
      : undefined;
  }

  getModifierGroupsOptions(selectedModifiers: Record<string, ModifierListItem[]>) {
    const modifierGroups = Object.keys(selectedModifiers)?.map((modifierGroupId) => {
      return {
        id: modifierGroupId,
        modifiers: selectedModifiers[modifierGroupId]?.map((modifier) => {
          return {
            id: modifier.id,
            formattedPrice:
              Number(modifier?.price) > 0
                ? this.formatCurrency({
                    value: modifier?.price,
                    currency: state.currency,
                  })
                : undefined,
          };
        }),
      };
    });
    return modifierGroups.length > 0
      ? (modifierGroups as CatalogReferenceModifierGroup[])
      : undefined;
  }

  validateSelectedModifierGroups(modifierGroups: ModifierGroupListItem[]) {
    let isValid = true;
    for (const modifierGroupId of Object.keys(state.selectedModifiers)) {
      const modifierGroup = modifierGroups?.find((group) => group.id === modifierGroupId);
      const minSelections = (modifierGroup?.rule as NewRule)?.minSelections ?? 0;
      const maxSelections = (modifierGroup?.rule as NewRule)?.maxSelections;
      const numOfModifiers = state.selectedModifiers[modifierGroupId]?.length;
      const isLessThanMinSelections = numOfModifiers < minSelections;
      const isGreaterThanMaxSelections = maxSelections ? numOfModifiers > maxSelections : false;
      if (isLessThanMinSelections || isGreaterThanMaxSelections) {
        state.modifiersErrors[modifierGroupId] = true;
        isValid = false;
      } else {
        state.modifiersErrors[modifierGroupId] = false;
      }
    }
    return isValid;
  }
}
