import { MAX_FILTER_OPERATOR_LENGTH } from '../consts';

// Split the modifierIds array to chunks of 'MAX_FILTER_OPERATOR_LENGTH' items each (the limit on operators in a query)
export const getModifierChunksByFilterOperatorLimit = (modifierIds?: string[]) => {
  return modifierIds?.reduce((resultArray: string[][], modifierId, modifierIdx) => {
    const chunkIndex = Math.floor(modifierIdx / MAX_FILTER_OPERATOR_LENGTH);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(modifierId);

    return resultArray;
  }, []);
};
