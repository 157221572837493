import type { TFunction } from '@wix/yoshi-flow-editor';
import { ItemModalController } from './itemModalController';
import model from './model';
import {
  getIdsToModifierGroupsMap,
  getIdsToModifiersMap,
  getModifierGroupsIds,
  getPriceVariantsIds,
  getPriceVariantsWithName,
  getModifierGroupsListItems,
} from '../../utils/modifiersUtils';
import { doesArrayDataExist } from '../../utils/utils';
import { modifiersState } from '../../states/ModifiersState';
import { PopulatedModifiersClient } from '../../api/PopulatedModifiersClient';
import { SPECS } from 'root/appConsts/experiments';
import { registerToUrlChange } from 'root/utils/navigationUtils';

export default model.createController(({ $w, $bindAll, $bind, $widget, flowAPI }) => {
  const { formatCurrency, controllerConfig, translations, experiments, reportError } = flowAPI;

  const window = controllerConfig.wixCodeApi.window;

  const lightboxContext = window.lightbox.getContext() || {};
  const { data = {}, onModalOpen, closeModal } = lightboxContext;
  const t = translations.t as TFunction;

  const itemModalController = new ItemModalController(
    $bindAll,
    $bind,
    $w,
    t,
    formatCurrency,
    window
  );

  const item = data?.item;
  const priceVariantsIds: string[] =
    item?.priceVariants && getPriceVariantsIds(item?.priceVariants);

  const modifierGroupsIds: string[] =
    item?.modifierGroups && getModifierGroupsIds(item?.modifierGroups);
  const shouldFetchModifiersData =
    doesArrayDataExist(priceVariantsIds) || doesArrayDataExist(modifierGroupsIds);

  const isModifierGroupNewRuleExperimentEnabled = experiments.enabled(SPECS.modifierGroupNewRule);

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);

      if (shouldFetchModifiersData) {
        try {
          await PopulatedModifiersClient(flowAPI).getAll({
            priceVariantsIds,
            modifierGroupsIds,
          });

          const idsToModifiersMap = getIdsToModifiersMap(modifiersState.modifiers);
          const idsToModifierGroupsMap = getIdsToModifierGroupsMap(modifiersState.modifierGroups);
          const priceVariants =
            priceVariantsIds &&
            getPriceVariantsWithName({
              allModifiers: idsToModifiersMap,
              priceVariants: item?.priceVariants,
            });
          const modifierGroups =
            modifierGroupsIds &&
            getModifierGroupsListItems({
              modifiers: idsToModifiersMap,
              modifierGroups: idsToModifierGroupsMap,
              itemModifierGroups: item?.modifierGroups,
            });
          itemModalController.init(
            data,
            onModalOpen,
            closeModal,
            priceVariants,
            modifierGroups,
            isModifierGroupNewRuleExperimentEnabled
          );
        } catch (e) {
          reportError(new Error(`modifiers error ${e}`));
        }
      } else {
        itemModalController.init(data, onModalOpen, closeModal);
      }
    },
    exports: {},
  };
});
