import type { DateTime } from 'luxon';
import { Duration } from 'luxon';
import type { AvailableDispatchesInfo } from 'root/api/utils/FulfillmentOptionsProcessor';
import { DispatchType } from 'root/types/businessTypes';
import type { DispatchTime, Minute } from 'root/types/businessTypes';
import { DayOfWeek } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type {
  TimeOfDayRange,
  DayOfWeekAvailability,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, roundTo5 } from './timeUtils';
import { DEFAULT_TIMEZONE } from 'root/api/consts';

type DateTimeRange = {
  start: DateTime;
  end: DateTime;
};

type AvailabilityRangesPerDay = {
  dayRange: DateTimeRange;
  availabilityRanges: DateTimeRange[];
};

type TimeWindowRangesPerDay = {
  dayRange: DateTimeRange;
  timeWindowRanges: DateTimeRange[];
};

export type FulfillmentTimeWindows = {
  [key in DispatchType]: DispatchTime[];
};

const timeOfDayRangeToDateTimeRange =
  (date: DateTime) =>
  (slot: TimeOfDayRange): DateTimeRange => {
    const defaultTimeOfDay = { hours: 0, minutes: 0 };
    const defaultTimeOfDayRange = { startTime: defaultTimeOfDay, endTime: defaultTimeOfDay };
    const safeSlot = { ...defaultTimeOfDayRange, ...slot };
    const start = date.set({ hour: safeSlot.startTime.hours, minute: safeSlot.startTime.minutes });
    const end = date.set({ hour: safeSlot.endTime.hours, minute: safeSlot.endTime.minutes });
    return { start, end };
  };

const splitRangeToDayRanges = (range: DateTimeRange): DateTimeRange[] => {
  const startDay = range.start.startOf('day');
  const endDay = range.end.startOf('day');
  const daysDiff = endDay.diff(startDay, 'days').days;

  const daysArray = Array.from({ length: daysDiff + 1 }, (_, i) => startDay.plus({ days: i }));

  return daysArray.map((day, index) => ({
    start: index === 0 ? range.start : day,
    end: index === daysDiff ? range.end : day.plus({ days: 1 }).startOf('day'),
  }));
};

const roundTo5Minutes = (date: DateTime): DateTime => date.set({ minute: roundTo5(date.minute) });

const splitDateTimeRangeToTimeSlotRanges = (
  range: DateTimeRange,
  timeWindowDuration: Minute
): DateTimeRange[] => {
  const duration = Duration.fromObject({ minutes: timeWindowDuration });
  const totalDuration = range.end.diff(range.start, 'minutes').minutes;
  const numberOfWindows = Math.ceil(totalDuration / timeWindowDuration);

  return Array.from({ length: numberOfWindows }, (_, index) => {
    const start = range.start.plus(duration.mapUnits((d) => d * index));
    const end = start.plus(duration) > range.end ? range.end : start.plus(duration);
    return { start: roundTo5Minutes(start), end: roundTo5Minutes(end) };
  });
};

const dateTimetoDayOfWeek = (date: DateTime): DayOfWeek =>
  [
    DayOfWeek.SUN,
    DayOfWeek.MON,
    DayOfWeek.TUE,
    DayOfWeek.WED,
    DayOfWeek.THU,
    DayOfWeek.FRI,
    DayOfWeek.SAT,
  ][date.weekday % 7] as DayOfWeek;

const dateTimeToWeeklyMinutes = (date: DateTime): Minute => {
  const dayOfWeek = date.weekday % 7;
  return dayOfWeek * MINUTES_IN_DAY + date.hour * MINUTES_IN_HOUR + date.minute;
};

const getTimeRanges = (
  availabilitiesPerDay: AvailabilityRangesPerDay[],
  timeWindowDuration: Minute
): TimeWindowRangesPerDay[] => {
  const timeWindowsPerDay = availabilitiesPerDay.map((day) => ({
    dayRange: day.dayRange,
    timeWindowRanges: day.availabilityRanges
      .flatMap((range) => splitDateTimeRangeToTimeSlotRanges(range, timeWindowDuration))
      .filter(
        (timeWindowRange) =>
          timeWindowRange.start >= day.dayRange.start && timeWindowRange.end <= day.dayRange.end
      ),
  }));
  return timeWindowsPerDay;
};

const getAvailabilityRangesPerDay = (
  availabilityTemplates: DayOfWeekAvailability[],
  dayRanges: DateTimeRange[]
): AvailabilityRangesPerDay[] =>
  dayRanges
    .map((range) => {
      const dayOfWeek = dateTimetoDayOfWeek(range.start);
      const daySlots = availabilityTemplates.filter((s) => s.dayOfWeek === dayOfWeek);
      return daySlots.map((slot) => ({
        dayRange: range,
        availabilityRanges: slot.timeRanges?.map(timeOfDayRangeToDateTimeRange(range.start)) || [],
      }));
    })
    .flat();

const getFulfillmentTimeSlots = (
  dispatches: AvailableDispatchesInfo[DispatchType],
  timeSlotDuration: Minute
): DispatchTime[] => {
  // get absolute start and end date-times
  const { fulfillments, startAndEndTime, timezone } = dispatches;

  // irrelevant/empty case
  if (!startAndEndTime || fulfillments.length === 0) {
    return [];
  }

  const localizedStartAndEndTime = {
    start: startAndEndTime.start.setZone(timezone ?? DEFAULT_TIMEZONE),
    end: startAndEndTime.end.setZone(timezone ?? DEFAULT_TIMEZONE),
  };

  // get list of daily ranges between start and end dates
  // NOTE: only the start and end days are partial, the rest are full days
  const dayRanges = splitRangeToDayRanges(localizedStartAndEndTime);

  // match avaialbility ranges to daily ranges
  const availabilityTemplates = fulfillments.flatMap(
    (fulfillment) => fulfillment.availabilityTimeSlots || []
  ); // { dayOfWeek, timeOfDay[] } templates
  const availabilityRangesPerDay: AvailabilityRangesPerDay[] = getAvailabilityRangesPerDay(
    availabilityTemplates,
    dayRanges
  );

  // split slots to time windows, according to operation's scheduling settings, and filter only those who fit into daily range
  const timeRanges: TimeWindowRangesPerDay[] = getTimeRanges(
    availabilityRangesPerDay,
    timeSlotDuration
  );

  // convert time window ranges to DispatchTimes
  const dispatchTimeSlots: DispatchTime[] = timeRanges
    .flatMap((day) => day.timeWindowRanges)
    .map((range) => ({
      from: dateTimeToWeeklyMinutes(range.start),
      until: dateTimeToWeeklyMinutes(range.end),
      date: range.start,
    }));

  return dispatchTimeSlots;
};

/**
 * Calculates the time windows for pre-order dispatches
 *
 * @param {AvailableDispatchesInfo} dispatches - PICKUP and DELIVERY dispatches
 * @param {Minute} timeSlotDuration - duration of of preopder time slot
 * @returns {FulfillmentTimeWindows} DispatchTimes per fulfillment type
 */
export const getPreorderTimeWindows = (
  dispatches: AvailableDispatchesInfo,
  timeSlotDuration: Minute
): FulfillmentTimeWindows => {
  return {
    [DispatchType.DELIVERY]: getFulfillmentTimeSlots(
      dispatches[DispatchType.DELIVERY],
      timeSlotDuration
    ),
    [DispatchType.PICKUP]: getFulfillmentTimeSlots(
      dispatches[DispatchType.PICKUP],
      timeSlotDuration
    ),
  };
};
