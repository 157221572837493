import model from './model';
import { NavigationMenuController } from './navigationMenuController';
import { hasDataChanged } from '../../utils/utils';

export default model.createController(({ $bind, $bindAll, $w, $widget, flowAPI }) => {
  const {
    environment: { isMobile },
  } = flowAPI;
  const navigationMenuController = new NavigationMenuController($w, $bind, $bindAll, isMobile);

  $widget.onPropsChanged((prevProps, nextProps) => {
    const shouldInit = hasDataChanged({
      prevData: prevProps.data?.menus,
      nextData: nextProps.data?.menus,
    });
    if (shouldInit) {
      navigationMenuController.init(nextProps.data);
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      navigationMenuController.init($widget.props.data);
    },
    exports: {},
  };
});
