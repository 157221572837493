import { DateTime } from 'luxon';
import type { Address, DispatchTime } from '../../types/businessTypes';
import { DispatchType } from '../../types/businessTypes';
import { PersistStorageKeys, PersistStorageService } from '../persistStorageService';

export type SelectedDispatchDetails = {
  dispatchType: DispatchType;
  dispatchTime?: DispatchTime;
  address?: Address;
};

export interface IPersistDataService {
  getSelectedDispatchDetails: (timezone?: string) => SelectedDispatchDetails | undefined;
  setSelectedDispatchDetails: (params: {
    selectedDispatchDetails: SelectedDispatchDetails;
    timezone?: string;
    override?: boolean;
  }) => void;
}

export const PersistDataService = (storage: {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
}): IPersistDataService => {
  const persistStorageService = PersistStorageService(storage);
  const returnObject = {
    getSelectedDispatchDetails: (timezone?: string) => {
      try {
        const dispatchDetails = persistStorageService.getItem<SelectedDispatchDetails>(
          PersistStorageKeys.dispatchDetails
        );
        if (dispatchDetails?.dispatchTime?.date) {
          dispatchDetails.dispatchTime.date = DateTime.fromJSDate(
            new Date(dispatchDetails.dispatchTime.date.toString())
          ).setZone(timezone, { keepLocalTime: true, keepCalendarTime: true });
        }
        return dispatchDetails;
      } catch (e) {
        console.error('getSelectedDispatchDetails error :>> ', e);
        return;
      }
    },
    setSelectedDispatchDetails: ({
      selectedDispatchDetails,
      timezone,
      override,
    }: {
      selectedDispatchDetails: SelectedDispatchDetails;
      timezone?: string;
      override?: boolean;
    }) => {
      const currentDispatchDetails = override
        ? undefined
        : returnObject.getSelectedDispatchDetails(timezone);

      const shouldUpdateStorage =
        override ||
        !!currentDispatchDetails?.address ||
        selectedDispatchDetails.dispatchType === DispatchType.PICKUP; // TODO - formattedAddress is not supported by ecom, https://jira.wixpress.com/browse/EE-49062

      const response =
        shouldUpdateStorage &&
        persistStorageService.setItem<SelectedDispatchDetails>(PersistStorageKeys.dispatchDetails, {
          ...currentDispatchDetails,
          ...selectedDispatchDetails,
        });
      return response;
    },
  };
  return returnObject;
};
