import type { DispatchModalState } from '../state/DispatchModalState';
import { SchedulingType, SameDayScheduling, TimeState } from '../../../types/businessTypes';
import type { SchedulingPickerProps } from '../dispatchModalBindings';
import { getTimeState } from '../dispatchModalUtils';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { getASAPTimeString } from '../../../utils/operationUtils';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { IBIReporterService } from '../../../services/biReporterService';

export function getSchedulingPickerProps(
  state: DispatchModalState,
  schedulingType: SchedulingType,
  canOrderForNow: boolean,
  t: TFunction,
  timezone: string,
  biReporterService?: IBIReporterService
): SchedulingPickerProps {
  return {
    collapsed: () => {
      const timeState = getTimeState(state, schedulingType, canOrderForNow, timezone);
      return timeState === TimeState.SAME_DAY_LATER;
    },
    value: () => state.selectedSchedulingType,
    disabled: () => !!state.error,
    options: () => [
      {
        value: SameDayScheduling.ASAP,
        label: getASAPTimeString(t, state[state.selectedDispatchType].prepTime),
      },
      {
        value: SameDayScheduling.SAME_DAY,
        label: t('menu_olo.dispatch.time.LaterToday'),
      },
    ],
    onChange: (evt: $w.Event) => {
      const selectedSchedulingType = evt.target.value;
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: selectedSchedulingType === SchedulingType.PRE_ORDER,
        buttonType:
          selectedSchedulingType === SchedulingType.ASAP
            ? LiveSiteClicksInsideDispatchModalButtonType.ASAP_RADIO_BUTTON
            : LiveSiteClicksInsideDispatchModalButtonType.SCHEDULE_FOR_LATER_RADIO_BUTTON,
      });

      state.setSchedulingType(selectedSchedulingType);
      if (selectedSchedulingType === SameDayScheduling.SAME_DAY) {
        state.setSelectedOption(state.selectedTimeOptionId || state.options[0].value);
      }
    },
    label: () => t('menu_olo.dispatchModal.delivery.when'),
  };
}
