import type { DispatchModalState } from '../state/DispatchModalState';
import { SchedulingType } from '../../../types/businessTypes';
import type { TimeSlotsProps } from '../dispatchModalBindings';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { IBIReporterService } from '../../../services/biReporterService';

export function getTimeSlotsProps(
  state: DispatchModalState,
  schedulingType: SchedulingType,
  t: TFunction,
  biReporterService?: IBIReporterService
): TimeSlotsProps {
  return {
    label: () => t('menu_olo.dispatchModal.timePicker'),
    collapsed: () => {
      return !state.isSameDay || state.options.length < 1;
    },
    value: () => state.selectedTimeOptionId,
    options: () => {
      return state.options || [];
    },
    onChange: (evt: $w.Event) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN_OPTION,
      });

      state.setSelectedOption(evt.target.value);
    },
    onClick: (evt: $w.Event) => {
      biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
        dispatchType: state.dispatchType,
        isPreorder: schedulingType === SchedulingType.PRE_ORDER,
        buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN,
      });
    },
  };
}
