import type {
  Item,
  ItemTpa,
  Menu,
  MenuTpa,
  Section,
  SectionTpa,
  Label,
  LabelTpa,
  PopulatedMenu,
  PopulatedSection,
} from '../../types/menusTypes';
import type {
  PriceVariant,
  PriceVariantTpa,
  ItemModifierGroupTpa,
  ItemModifierGroup,
} from '../../types/modifiers';
import type { Money } from '../../types/businessTypes';

export const getItemLabels = ({ item, labelsList }: { item: ItemTpa; labelsList: Label[] }) => {
  return labelsList?.filter((label) => item?.labels?.find((l) => l.id === label.id)) || [];
};

export const convertItemModifierGroups = (
  modifierGroups: ItemModifierGroupTpa[]
): ItemModifierGroup[] => {
  return modifierGroups.map((modifierGroup) => {
    return {
      id: modifierGroup.id ?? '',
    };
  });
};

export const convertPriceVariantsToPriceVariantType = (
  priceVariants: PriceVariantTpa[]
): PriceVariant[] => {
  return priceVariants.map((priceVariant) => {
    return {
      id: priceVariant.modifierId,
      price: priceVariant.price,
    } as PriceVariant;
  });
};

export const convertItemsToItemType = async ({
  items,
  labelsList,
  currency,
  imagesData = [],
}: {
  items: ItemTpa[];
  labelsList: Label[];
  currency: string;
  imagesData?: { id: string; width: number; height: number }[];
}): Promise<Item[]> => {
  const updatedItems: Item[] = [];
  await items.forEach(async (item) => {
    const itemLabelsList = getItemLabels({ item, labelsList });
    const price: Money = {
      amount: Number(item.price) || 0,
      currency,
    };
    const priceVariants =
      item?.priceVariants?.priceVariants &&
      convertPriceVariantsToPriceVariantType(item?.priceVariants?.priceVariants);
    const modifierGroups = item?.modifierGroups && convertItemModifierGroups(item?.modifierGroups);

    if (item.image && item.image.id && !(item.image.width || item.image.height)) {
      const imgData = imagesData.find((img) => img.id === item.image?.id);

      item.image.width = item.image.width || imgData?.width;
      item.image.height = item.image.height || imgData?.height;
    }

    if (item.id) {
      updatedItems.push({
        id: item.id,
        name: item.name,
        description: item.description,
        price,
        priceVariants,
        labels: itemLabelsList,
        image: item.image,
        additionalImages: item.additionalImages,
        visible: !!item.visible,
        orderSettings: item.orderSettings,
        modifierGroups,
        revision: item.revision || '0',
      });
    }
  });

  return updatedItems;
};

export const convertLabelsToLabelType = ({ labelsList }: { labelsList: LabelTpa[] }): Label[] => {
  const updatedLabels: Label[] = [];
  labelsList.forEach((label) => {
    if (label.id) {
      updatedLabels.push({
        id: label.id,
        name: label.name,
        icon: label.icon,
      });
    }
  });

  return updatedLabels;
};

export const convertSectionsToSectionType = ({
  sections,
}: {
  sections: SectionTpa[];
}): Section[] => {
  const updatedSections: Section[] = [];
  sections.forEach((section) => {
    if (section.id) {
      updatedSections.push({
        id: section.id,
        name: section.name,
        description: section.description,
        image: section.image,
        additionalImages: section.additionalImages,
        visible: !!section.visible,
        itemIds: section.itemIds || [],
        revision: section.revision || '0',
      });
    }
  });

  return updatedSections;
};

export const convertMenusToMenuType = ({ menus = [] }: { menus?: MenuTpa[] }): Menu[] => {
  const updatedMenus: Menu[] = [];
  menus.forEach((menu) => {
    if (menu.id) {
      updatedMenus.push({
        id: menu.id,
        name: menu.name,
        description: menu.description,
        visible: !!menu.visible,
        sectionIds: menu.sectionIds || [],
        orderIdx: menu.orderIdx || undefined,
        revision: menu.revision || '0',
      });
    }
  });

  return updatedMenus;
};

export const convertDataTypes = async ({
  currency,
  menusTpa = [],
  sectionsTpa = [],
  itemsTpa = [],
  labelsTpa = [],
  imagesData,
}: {
  currency: string;
  menusTpa?: MenuTpa[];
  sectionsTpa?: SectionTpa[];
  itemsTpa?: ItemTpa[];
  labelsTpa?: LabelTpa[];
  imagesData?: { id: string; width: number; height: number }[];
}) => {
  const labels = convertLabelsToLabelType({
    labelsList: labelsTpa,
  });

  const menus = convertMenusToMenuType({
    menus: menusTpa,
  });

  const sections = convertSectionsToSectionType({
    sections: sectionsTpa,
  });

  const items = await convertItemsToItemType({
    labelsList: labels,
    items: itemsTpa,
    currency,
    imagesData,
  });

  return { menus, sections, items, labels };
};

export const populateMenu = (menu: Menu, sections: Section[], items: Item[]): PopulatedMenu => {
  const itemMap = new Map();
  const sectionMap = new Map();

  items.forEach((item) => itemMap.set(item.id, item));
  sections.forEach((section) => sectionMap.set(section.id, section));

  const menuSections = menu.sectionIds?.map((sectionId) => sectionMap.get(sectionId));

  const sectionsWithItems = menuSections?.map((section) => {
    const sectionItems = section?.itemIds?.map((itemId: string) => itemMap.get(itemId));

    return {
      ...section,
      items: sectionItems,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};

export const convertDate = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
};
