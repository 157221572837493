import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  getModifier,
  queryModifiers,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier/http';
import type {
  CursorQuery,
  Modifier,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier/types';
import { getModifierChunksByFilterOperatorLimit } from './utils/modifierChunksDivider';

export class ModifiersClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  queryAllModifiers = async (modifierIds?: string[]) => {
    try {
      const modifierIdsChunks: string[][] | undefined =
        getModifierChunksByFilterOperatorLimit(modifierIds);

      // Build a query from the above chunks
      let query: CursorQuery = {
        filter: {
          $or: modifierIdsChunks?.map((modifierIdsChunk) => ({
            id: {
              $in: modifierIdsChunk,
            },
          })),
        },
      };

      const allModifiers: Modifier[] = [];
      let hasNext = true;

      while (hasNext) {
        const response = await this.httpClient.request(
          queryModifiers({
            query,
          })
        );

        allModifiers.push(...response.data.modifiers!);

        hasNext = !!response.data.pagingMetadata?.hasNext;
        query = {
          cursorPaging: {
            cursor: response.data.pagingMetadata?.cursors?.next,
          },
        };
      }

      return allModifiers;
    } catch (e: unknown) {
      console.error(e);
      return [];
    }
  };

  getModifierById = async (modifierId: string) => {
    try {
      return this.httpClient.request(getModifier({ modifierId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
