import model from './model';
import { PopulatedMenuClient } from '../../api/PopulatedMenuClient';
import { OLOController } from './oloController';
import { CartService } from '../../services/cartService';
import { state } from '../../states/RootState';
import type { LineItem, Cart } from '@wix/ambassador-ecom-v1-cart/types';
import type { Item, PopulatedMenu } from '../../types/menusTypes';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import { FedopsLogger } from '../../utils/monitoring/FedopsLogger';
import { ModalService } from '../../services/modalService';
import { OperationsClient } from '../../api/operationClient';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { ItemData } from '../../types/item';
import { SPECS } from '../../appConsts/experiments';
import { getCartItemById } from '../../utils/cartUtils';
import { BIReporterService } from '../../services/biReporterService';
import { type Operation } from '../../types/businessTypes';
import { PersistDataService } from 'root/services/persistDataService';
import { getSiteCurrency } from '../../utils/currency';
import { initDispatchState } from 'root/states/initDispatchState';
import { FulfillmentsClient } from '../../api/fulfillmentsClient';
import { dispatchState } from 'root/states/DispatchState';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const {
    translations,
    httpClient,
    experiments,
    bi,
    fedops,
    environment,
    controllerConfig,
    errorMonitor,
    reportError,
  } = flowAPI;
  const { wixCodeApi, platformAPIs, compId } = controllerConfig;
  const t = translations.t as TFunction;
  const timezone =
    controllerConfig.wixCodeApi.site.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fedopsLogger = new FedopsLogger(fedops as FedopsLoggerType);
  state.PersistDataService = PersistDataService(platformAPIs.storage.session);
  state.biReporterService = BIReporterService({
    biLogger: bi,
    environment,
    widgetInstanceId: compId,
  });
  state.CartService = CartService({
    httpClient,
    fedopsLogger,
    sentry: errorMonitor,
    experiments,
    metaSiteId: platformAPIs.bi?.metaSiteId,
    wixAPI: wixCodeApi,
    biReporterService: state.biReporterService,
  });
  state.ModalService = state.ModalService
    ? state.ModalService
    : new ModalService(
        wixCodeApi.window,
        fedopsLogger,
        wixCodeApi.location,
        environment.isMobile,
        state.CartService
      );

  const oloController = new OLOController($bind, $w, t);

  fedopsLogger.loadOloPageStarted();
  state.biReporterService?.reportRestaurantsUouPageStartedLoadingBiEvent();

  const warmupData = new WarmupDataManager(wixCodeApi.window.warmupData, environment.isSSR);

  state.currency = getSiteCurrency(flowAPI);

  const fetchPopulatedMenus = () => PopulatedMenuClient(flowAPI, state.currency).getAll();

  const fetchOperation = () => new OperationsClient(flowAPI.httpClient).getOperation();

  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall({
      callback: fetchPopulatedMenus,
      fedops: { start: fedopsLogger.fetchMenusDataStarted, end: fedopsLogger.fetchMenusDataEnded },
      reportError,
    });

  const getMonitoredOperationClient = () =>
    getMonitoredApiCall({
      callback: fetchOperation,
      fedops: {
        start: fedopsLogger.fetchOperationDataStarted,
        end: fedopsLogger.fetchOperationDataEnded,
      },
      reportError,
    });

  const menusPromise = warmupData.manageData<{ data?: PopulatedMenu[]; error?: Error } | undefined>(
    getMonitoredPopulatedMenuClient,
    'populatedMenus'
  );

  const operationPromise = warmupData
    .manageData<{ data?: Operation; error?: Error } | undefined>(
      getMonitoredOperationClient,
      'operation'
    )
    .then(async (operationData) => {
      if (experiments.enabled(SPECS.newHeaderAndDispatchModal) && operationData?.data?.id) {
        const fulfillmentClient = new FulfillmentsClient(httpClient, operationData.data.id);
        // TODO: persist data service
        const _dispatchState = await initDispatchState(
          fulfillmentClient,
          warmupData,
          operationData.data,
          timezone,
          fedopsLogger,
          reportError
        );
        dispatchState.init(_dispatchState);
      }
      return operationData?.data;
    });

  const cartPromise = state.CartService?.getCurrentCart();

  const initCartDetails = (cart?: Cart) => {
    if (!cart) {
      return;
    }
    const { lineItems } = cart;
    lineItems && setCartLineItems({ lineItems });
  };

  const setCartLineItems = ({ lineItems = [] }: { lineItems: LineItem[] }) => {
    state.cartLineItems = new Map();
    lineItems.forEach((cartItem) => {
      const { quantity, catalogReference } = cartItem;
      const { options, catalogItemId: itemId } = catalogReference ?? {};
      const { menuId, menuSectionId } = options ?? {};
      const cartLineItemsKey = `${menuId}_${menuSectionId}_${itemId}`;
      const cartLineItems = state.cartLineItems.get(cartLineItemsKey) ?? [];
      return state.cartLineItems.set(cartLineItemsKey as string, [
        ...cartLineItems,
        {
          id: cartItem.id ?? '',
          catalogItemId: itemId as string,
          quantity,
          options,
        },
      ]);
    });
  };

  const retryOnEmptyMenus = async (counter = 0) => {
    if (counter < 5) {
      let resolveFn: (data?: PopulatedMenu[]) => void;
      const menusRetryPromise = new Promise<PopulatedMenu[] | undefined>(
        (resolve) => (resolveFn = resolve)
      );

      setTimeout(async () => {
        const { data } = (await getMonitoredPopulatedMenuClient()) || {};
        resolveFn(data);
      }, 2000);

      const menus = (await menusRetryPromise) || [];
      if (!menus?.length) {
        retryOnEmptyMenus(counter + 1);
      } else {
        fedopsLogger.setMenusDataStarted();
        oloController.setMenus(menus);
        fedopsLogger.setMenusDataEnded();
        oloController.setNavigationMenu(menus);
      }
    }
  };

  const getItemById = (itemId: string, menus: PopulatedMenu[]) => {
    let editItem: Item | undefined;
    if (itemId) {
      for (const menu of menus) {
        for (const section of menu.sections) {
          editItem = section.items?.find((item) => item.id === itemId);
          if (editItem) {
            return { editItem, sectionId: section.id, menuId: menu.id };
          }
        }
      }
    }
  };

  const openEditItemModal = async (cartItemId: string, menus: PopulatedMenu[]) => {
    const cartItem = getCartItemById(state.cartLineItems, cartItemId);
    const {
      editItem = undefined,
      sectionId = undefined,
      menuId = undefined,
    } = cartItem?.catalogItemId ? getItemById(cartItem.catalogItemId, menus) ?? {} : {};

    return (
      editItem &&
      cartItem &&
      state.ModalService?.openDishModal({
        item: editItem as ItemData,
        cartService: state.CartService,
        operationId: state.operation?.id,
        canAcceptOrders: state.canAcceptOrders,
        cartItem,
        sectionId,
        menuId,
      })
    );
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      try {
        const [menusData, operation, cart] = await Promise.all([
          menusPromise,
          operationPromise,
          cartPromise,
        ]);
        const { data: menus = [] } = menusData || {};
        initCartDetails(cart);
        operation && oloController.setHeader(operation);
        state.operation = operation;

        state.CartService?.onChange(async (_lineItems: LineItem[]) => {
          // menuId & sectioId not exists in lineItems argument
          const { lineItems: cartLineItems } = (await state.CartService?.getCurrentCart()) ?? {};
          cartLineItems && setCartLineItems({ lineItems: cartLineItems });
        });

        const editCartItemEnabled = experiments.enabled(SPECS.editCartItem);

        const { cartItemId = undefined } = editCartItemEnabled
          ? controllerConfig.wixCodeApi.location.query
          : {};

        if (cartItemId) {
          openEditItemModal(cartItemId, menus);
        }

        oloController.setNavigationMenu(menus);
        if (menus.length) {
          fedopsLogger.setMenusDataStarted();
          oloController.setMenus(menus);
          fedopsLogger.setMenusDataEnded();
        } else {
          // TODO: set menus empty state
          !environment.isViewer && retryOnEmptyMenus();
        }
        const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser?.loggedIn;

        state.biReporterService?.reportOloLiveSiteOloPageLoadedBiEvent({
          isMemberLoggedIn,
          menus,
        });
        fedopsLogger.loadOloPageEnded();
        state.biReporterService?.reportRestaurantsUouPageFinishedLoadingBiEvent();
      } catch (e) {
        // TODO: set menus error state
        state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    },
    exports: {},
  };
});
