import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import type { Interaction } from './types';

export class FedopsLogger {
  constructor(private fedops: FedopsLoggerType<Interaction>) {}

  restaurantsOrdersFirstInstallStarted = () =>
    this.fedops.interactionStarted('restaurants-orders-first-install');

  restaurantsOrdersFirstInstallEnded = () =>
    this.fedops.interactionEnded('restaurants-orders-first-install');

  openOloElementsPanelStarted = () => this.fedops.interactionStarted('open-olo-elements-panel');

  openOloElementsPanelEnded = () => this.fedops.interactionEnded('open-olo-elements-panel');

  openHeaderElementsPanelStarted = () =>
    this.fedops.interactionStarted('open-header-elements-panel');

  openHeaderElementsPanelEnded = () => this.fedops.interactionEnded('open-header-elements-panel');

  openHeaderSettingsPanelStarted = () =>
    this.fedops.interactionStarted('open-header-settings-panel');

  openHeaderSettingsPanelEnded = () => this.fedops.interactionEnded('open-header-settings-panel');

  openItemLayoutPanelStarted = () => this.fedops.interactionStarted('open-item-layout-panel');

  openItemLayoutPanelEnded = () => this.fedops.interactionEnded('open-item-layout-panel');

  setMenusDataStarted = () => this.fedops.interactionStarted('set-menus-data');

  setMenusDataEnded = () => this.fedops.interactionEnded('set-menus-data');

  loadOloPageStarted = () => this.fedops.interactionStarted('load-olo-page');

  loadOloPageEnded = () => this.fedops.interactionEnded('load-olo-page');

  fetchMenusDataStarted = () => this.fedops.interactionStarted('fetch-menus-data');

  fetchMenusDataEnded = () => this.fedops.interactionEnded('fetch-menus-data');

  fetchOperationDataStarted = () => this.fedops.interactionStarted('fetch-operation-data');
  fetchOperationDataEnded = () => this.fedops.interactionEnded('fetch-operation-data');

  addItemsToCartCallStarted = () => this.fedops.interactionStarted('add-item-to-cart');

  addItemsToCartCallEnded = () => this.fedops.interactionEnded('add-item-to-cart');

  removeItemFromCartCallStarted = () => this.fedops.interactionStarted('remove-item-from-cart');

  removeItemFromCartCallEnded = () => this.fedops.interactionEnded('remove-item-from-cart');

  getCartItemsStarted = () => this.fedops.interactionStarted('get-cart-items');

  getCartItemsEnded = () => this.fedops.interactionEnded('get-cart-items');

  addItemsToCartOverallActionStarted = () =>
    this.fedops.interactionStarted('add-item-to-cart-overall-action');

  addItemsToCartOverallActionEnded = () =>
    this.fedops.interactionEnded('add-item-to-cart-overall-action');

  setShippingMethodStarted = () => this.fedops.interactionStarted('set-shipping-method');

  setShippingMethodEnded = () => this.fedops.interactionEnded('set-shipping-method');

  updateCartStarted = () => this.fedops.interactionStarted('update-cart');

  updateCartEnded = () => this.fedops.interactionEnded('update-cart');

  openItemModalStarted = () => this.fedops.interactionStarted('open-item-modal');

  openItemModalEnded = () => this.fedops.interactionEnded('open-item-modal');

  openDispatchModalStarted = () => this.fedops.interactionStarted('open-dispatch-modal');

  openDispatchModalEnded = () => this.fedops.interactionEnded('open-dispatch-modal');

  openErrorModalStarted = () => this.fedops.interactionStarted('open-error-modal');

  openErrorModalEnded = () => this.fedops.interactionEnded('open-error-modal');

  openManageMenusDashboardStarted = () =>
    this.fedops.interactionStarted('open-manage-menus-dashboard');

  openManageMenusDashboardEnded = () => this.fedops.interactionEnded('open-manage-menus-dashboard');

  openOrdersSettingsDashboardStarted = () =>
    this.fedops.interactionStarted('open-orders-settings-dashboard');

  openOrdersSettingsDashboardEnded = () =>
    this.fedops.interactionEnded('open-orders-settings-dashboard');

  openItemModalElementsPanelStarted = () =>
    this.fedops.interactionStarted('open-item-modal-elements-panel');

  openItemModalElementsPanelEnded = () =>
    this.fedops.interactionEnded('open-item-modal-elements-panel');

  openDishesElementsPanelStarted = () =>
    this.fedops.interactionStarted('open-dishes-elements-panel');

  openDishesElementsPanelEnded = () => this.fedops.interactionEnded('open-dishes-elements-panel');

  fetchAllModifierGroupsStarted = () => this.fedops.interactionStarted('fetch-all-modifier-groups');

  fetchAllModifierGroupsEnded = () => this.fedops.interactionEnded('fetch-all-modifier-groups');

  fetchAllModifiersStarted = () => this.fedops.interactionStarted('fetch-all-modifiers');

  fetchAllModifiersEnded = () => this.fedops.interactionEnded('fetch-all-modifiers');

  fetchAllFulfillmentsStarted = () => this.fedops.interactionStarted('fetch-all-fulfillments');

  fetchAllFulfillmentsEnded = () => this.fedops.interactionEnded('fetch-all-fulfillments');

  fetchFirstAvailableTimeSlotStarted = () =>
    this.fedops.interactionStarted('fetch-first-available-time-slot');

  fetchFirstAvailableTimeSlotEnded = () =>
    this.fedops.interactionEnded('fetch-first-available-time-slot');
}
