import { toJS } from 'mobx';
import type { TFunction, I$W } from '@wix/yoshi-flow-editor';
import type { Bind } from '@wix/velocycle-mobx';
import { state } from '../../states/RootState';
import { LABELS_LIMIT } from '../../api/consts';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  OUT_OF_STOCK_DISHES_COMPONENT_IDS,
} from '../../appConsts/blocksIds';
import type { Item } from '../../types/menusTypes';
import { buildImgSrc, getAltText, isItemValidInCart } from './utils';
import type { DishesWidgetState } from '../../types/businessTypes';
import { DISHES_WIDGET_STATES, DISH_STATES, DispatchType } from '../../types/businessTypes';
import type { ItemData } from '../../types/item';
import { getVariantWithMinimalPrice } from '../../utils/modifiersUtils';
import type { DispatchModalForm } from '../../utils/dispatchModalUtils';
import {
  getCartShippingDetailsByForm,
  getDispatchModalProps,
  updateStateFromForm,
} from '../../utils/dispatchModalUtils';
import { ADD_TO_CART_ERRORS } from '../../services/cartService';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';

export class DishesController {
  constructor(
    private $bind: Bind<I$W>,
    private $w: I$W,
    private t: TFunction,
    private formatCurrency: Function,
    private timezone: string,
    private isMobile: boolean,
    private isMemberLoggedIn?: boolean
  ) {}

  async openDishModal(itemData: ItemData, menuId: string, sectionId: string) {
    const dishModalRes = await state.ModalService?.openDishModal({
      item: itemData,
      cartService: state.CartService,
      biReporterService: state.biReporterService,
      operationId: state.operation?.id,
      canAcceptOrders: state.canAcceptOrders,
      menuId,
      sectionId,
    });

    const { data: dataPromise, additionaldata } = dishModalRes ?? {};
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemData._id}`;
    const cartLineItems = state.cartLineItems.get(cartLineItemsKey) ?? [];
    additionaldata && state.cartLineItems.set(cartLineItemsKey, [...cartLineItems, additionaldata]);

    const data = await dataPromise;

    if (data?.error === ADD_TO_CART_ERRORS.MIXED_CART) {
      state.ModalService?.openErrorModal({
        title: this.t('cart.mixed-vertical-cart-error.title'),
        content: this.t('cart.mixed-vertical-cart-error.content'),
        closeButtonLabel: this.t('cart.mixed-vertical-cart-error.button'),
      });
      state.cartLineItems.set(cartLineItemsKey, cartLineItems);
      return;
    }

    if (data && !data.cartItem) {
      state.cartLineItems.set(cartLineItemsKey, cartLineItems);
      state.ModalService?.openErrorModal({
        title: this.t('addToCart.error-modal.general-error.title'),
        content: this.t('addToCart.error-modal.general-error.content'),
        closeButtonLabel: this.t('addToCart.error-modal.general-error.button'),
      });
    }
  }

  async openDispatchModal() {
    const [availablePickup] = state.availableDispatches?.[DispatchType.PICKUP].fulfillments ?? [];
    state.ModalService?.openDispatchModal(
      getDispatchModalProps({
        selectedDispatchType: state.selectedDispatchType,
        pickupState: toJS(state[DispatchType.PICKUP]),
        deliveryState: toJS(state[DispatchType.DELIVERY]),
        configuredDispatchTypes: state.configuredDispatchTypes!,
        availableDispatchTypes: state.availableDispatchTypes!,
        onSaveDispatch: this.onSaveDispatch,
        operation: state.operation!,
        availablePickup,
        biReporterService: state.biReporterService,
      })
    );
  }

  onSaveDispatch = (form: DispatchModalForm) => {
    updateStateFromForm(form);

    state.CartService &&
      state.CartService.setShippingDetails(
        getCartShippingDetailsByForm(form, this.timezone, state[DispatchType.PICKUP]?.address)
      );
  };

  init(items: Item[], isEditor: boolean, menuId: string, sectionId: string) {
    if (items.length > 0) {
      this.switchState(DISHES_WIDGET_STATES.dishes);
      if (isEditor) {
        this.restoreItemCounterInEditor(menuId, sectionId);
      }

      this.$bind(DISHES_WIDGET_COMPONENT_IDS.repeaterItems, {
        data: () =>
          items.map(({ id, ...rest }) => ({
            _id: id,
            ...rest,
          })),
        item: (itemData: ItemData, bindItem: Bind<I$W>) => {
          const isOutOfStockItem = itemData.orderSettings?.outOfStock;
          const componentIds = isOutOfStockItem
            ? OUT_OF_STOCK_DISHES_COMPONENT_IDS
            : DISHES_WIDGET_COMPONENT_IDS;
          const amount = itemData.priceVariants
            ? getVariantWithMinimalPrice({
                priceVariantsInItem: itemData.priceVariants,
              })?.price
            : itemData.price.amount;
          const { currency } = itemData.price;
          const formattedPrice = this.formatCurrency({
            value: (amount ?? 0).toString(),
            currency,
          });

          bindItem(componentIds.itemContainer, {
            onClick: () => {
              state.biReporterService?.reportOloLiveSiteClickOnItemBiEvent({
                itemName: itemData.name,
                itemId: itemData._id,
                menuId,
                sectionId,
                minItemPrice: Number(amount || 0),
              });
              if (state.isAddressSelected) {
                this.openDishModal(itemData, menuId, sectionId);
              } else {
                state.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
                  origin: LiveSiteClickFulfillmentOrigin.CLICK_ITEM_NO_ADDRESS,
                  isMemberLoggedIn: this.isMemberLoggedIn,
                });
                this.openDispatchModal();
              }
            },
          });
          bindItem(componentIds.itemTitle, {
            text: () => itemData.name,
            hidden: () => !itemData.name,
            collapsed: () => this.isMobile && !itemData.name,
          });

          bindItem(componentIds.itemDescription, {
            text: () => itemData.description,
            hidden: () => !itemData.description,
            collapsed: () => this.isMobile && !itemData.description,
          });

          bindItem(componentIds.itemPrice, {
            text: () =>
              isOutOfStockItem
                ? this.t('menu_olo.OutOfStock.itemPrice', {
                    price: formattedPrice,
                  })
                : formattedPrice,
          });

          const imageProps = itemData.image?.url
            ? {
                src: () => itemData.image && buildImgSrc(itemData.image),
                alt: () => getAltText({ itemName: itemData.name, t: this.t }),
              }
            : {};

          bindItem(componentIds.itemImage, {
            ...imageProps,
            collapsed: () => !itemData.image?.url,
          });

          const hasLabels = itemData.labels?.length;

          bindItem(componentIds.labelContainer, {
            hidden: () => !this.isMobile && !hasLabels,
            collapsed: () => this.isMobile && !hasLabels,
          });

          if (itemData.orderSettings?.outOfStock) {
            bindItem(DISHES_WIDGET_COMPONENT_IDS.dishStateMultiStateBox, {
              currentState: () => DISH_STATES.outofStock,
            });
          }

          const cartLineItemsKey = `${menuId}_${sectionId}_${itemData._id}`;

          hasLabels && this.initLabels(itemData, bindItem, componentIds.label);
          bindItem(componentIds.itemCounter, {
            collapsed: () => {
              const cartItems = state.cartLineItems.get(cartLineItemsKey);
              return !isItemValidInCart(cartItems);
            },
            hidden: () => {
              const cartItems = state.cartLineItems.get(cartLineItemsKey);
              return !isItemValidInCart(cartItems);
            },
          });

          bindItem(componentIds.itemCounterValue, {
            text: () => {
              const cartItems = state.cartLineItems.get(cartLineItemsKey);
              const value =
                cartItems?.reduce(
                  (previousValue, currentValue) => previousValue + (currentValue?.quantity || 0),
                  0
                ) || 0;
              return value.toString();
            },
          });
        },
      });
    } else {
      this.switchState(DISHES_WIDGET_STATES.dishesEmpty);
      this.$bind(DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateTitle, {
        text: () => this.t('menu_olo.emptyState.title'),
      });
      this.$bind(DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateSubtitle, {
        text: () => this.t('menu_olo.emptyState.subTitle'),
      });
    }
  }

  initLabels(itemData: ItemData, bindItem: Bind<I$W>, getLabelElement: (idx: number) => string) {
    for (let i = 0; i < LABELS_LIMIT; i++) {
      const currentLabel = itemData.labels?.[i];
      const currentLabelElement = getLabelElement(i + 1);

      bindItem(currentLabelElement, {
        collapsed: () => !currentLabel,
      });

      if (currentLabel) {
        const iconSrc = currentLabel.icon?.url ? { src: () => currentLabel.icon?.url } : {};

        bindItem(currentLabelElement, {
          ...iconSrc,
          collapsed: () => !currentLabel?.icon?.url,
        });
      }
    }
  }

  switchState(dishState: DishesWidgetState) {
    const multiStateBox = this.$w(DISHES_WIDGET_COMPONENT_IDS.dishesWidgetMultiStateBox);
    multiStateBox.changeState(dishState);
  }

  deleteItemCounterInEditor() {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isOutOfStockItem = !!itemData.orderSettings?.outOfStock;
        const dishesWidgetIds = isOutOfStockItem
          ? OUT_OF_STOCK_DISHES_COMPONENT_IDS
          : DISHES_WIDGET_COMPONENT_IDS;
        $item(dishesWidgetIds.itemCounter).delete();
      }
    );
  }

  restoreItemCounterInEditor(menuId: string, sectionId: string) {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isOutOfStockItem = !!itemData.orderSettings?.outOfStock;
        const dishesWidgetIds = isOutOfStockItem
          ? OUT_OF_STOCK_DISHES_COMPONENT_IDS
          : DISHES_WIDGET_COMPONENT_IDS;

        const shouldRestoreBadge = this.hasItemsInCart(menuId, sectionId, itemData._id);
        shouldRestoreBadge && $item(dishesWidgetIds.itemCounter).restore();
      }
    );
  }

  hasItemsInCart(menuId: string, sectionId: string, itemId: string) {
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemId}`;
    const cartItems = state.cartLineItems.get(cartLineItemsKey);

    const hasItemInCart = isItemValidInCart(cartItems);
    return hasItemInCart;
  }
}
