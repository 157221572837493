import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  convertModifierGroupToModifierGroupType,
  convertModifierToModifierType,
} from '../utils/modifiersConvertUtils';
import { ModifierGroupsClient } from './modifierGroupsClient';
import { ModifiersClient } from './modifiersClient';
import { modifiersState } from '../states/ModifiersState';
import { getModifiersIds } from '../utils/modifiersUtils';
import { SPECS } from 'root/appConsts/experiments';
import { doesArrayDataExist } from 'root/utils/utils';
import type { Modifier, ModifierGroup } from 'root/types/modifiers';
import { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';

export const PopulatedModifiersClient = (flowAPI: PlatformControllerFlowAPI) => {
  const modifierGroupsClient = new ModifierGroupsClient(flowAPI.httpClient);
  const modifiersClient = new ModifiersClient(flowAPI.httpClient);
  const isModifierGroupsNewRuleExperimentEnabled = flowAPI.experiments.enabled(
    SPECS.modifierGroupNewRule
  );
  const fedops = new FedopsLogger(flowAPI.fedops as FedopsLoggerType);

  return {
    getAll: async ({
      priceVariantsIds,
      modifierGroupsIds,
    }: {
      priceVariantsIds?: string[];
      modifierGroupsIds?: string[];
    }) => {
      const modifierIds: string[] = [];
      const variantIds: string[] = [];
      const modifiers: Modifier[] = [];
      const modifierGroups: ModifierGroup[] = [];

      if (doesArrayDataExist(modifierGroupsIds)) {
        const modifierGroupsResponse = await getMonitoredApiCall({
          callback: () => modifierGroupsClient.fetchAllModifierGroups(modifierGroupsIds),
          fedops: {
            start: fedops.fetchAllModifierGroupsStarted,
            end: fedops.fetchAllModifierGroupsEnded,
          },
          reportError: flowAPI.reportError,
        });

        modifierGroups.push(
          ...convertModifierGroupToModifierGroupType({
            modifierGroups: modifierGroupsResponse.data?.modifierGroups || [],
            isModifierGroupsNewRuleExperimentEnabled,
          })
        );

        modifierIds.push(...getModifiersIds(modifierGroups));
      }

      if (doesArrayDataExist(priceVariantsIds)) {
        variantIds.push(...(priceVariantsIds || []));
      }

      const seenModifierIds: Record<string, boolean> = {};
      const modifierIdsWithoutDuplications = modifierIds.filter((modifierId) => {
        if (seenModifierIds[modifierId]) {
          return false;
        } else {
          seenModifierIds[modifierId] = true;
          return true;
        }
      });

      const seenVariantIds: Record<string, boolean> = {};
      const variantIdsWithoutDuplications = variantIds.filter((variantId) => {
        // Check if somehow this the variant already included in modifiers
        if (seenModifierIds[variantId] || seenVariantIds[variantId]) {
          return false;
        } else {
          seenVariantIds[variantId] = true;
          return true;
        }
      });

      // Fetch all modifiers if exist
      if (doesArrayDataExist(modifierIdsWithoutDuplications)) {
        const modifiersResponse = await getMonitoredApiCall({
          callback: () => modifiersClient.queryAllModifiers(modifierIdsWithoutDuplications),
          fedops: { start: fedops.fetchAllModifiersStarted, end: fedops.fetchAllModifiersEnded },
          reportError: flowAPI.reportError,
        });
        modifiers.push(
          ...convertModifierToModifierType({
            modifiers: modifiersResponse.data || [],
          })
        );
      }

      // Fetch all variants if exist
      if (doesArrayDataExist(variantIdsWithoutDuplications)) {
        const modifiersResponse = await getMonitoredApiCall({
          callback: () => modifiersClient.queryAllModifiers(variantIdsWithoutDuplications),
          fedops: { start: fedops.fetchAllModifiersStarted, end: fedops.fetchAllModifiersEnded },
          reportError: flowAPI.reportError,
        });
        modifiers.push(
          ...convertModifierToModifierType({
            modifiers: modifiersResponse.data || [],
          })
        );
      }

      modifiersState.modifiers = modifiers;
      modifiersState.modifierGroups = modifierGroups;
    },
  };
};
