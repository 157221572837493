import { initState } from '@wix/velocycle-mobx';
import { Pubsub } from '../utils/pubsub';
import type { IModalService } from '../services/modalService';
import type { CartLineItem, ICartService } from '../services/cartService';
import type { Operation } from '../types/businessTypes';
import { DispatchType } from '../types/businessTypes';
import { DEFAULT_CURRENCY_CODE, EMPTY_DISPATCH_TIME } from '../api/consts';
import type { DispatchState } from './HeaderState';
import type { AvailableDispatchesInfo } from '../api/utils/FulfillmentOptionsProcessor';
import { DEFAULT_PREP_TIME } from '../components/DispatchModal/state/defaults';
import type { IBIReporterService } from '../services/biReporterService';
import type { IPersistDataService } from '../services/persistDataService';
import type { FulfillmentTimeWindows } from 'root/logic/getPreorderTimeWindows';
import type { FulfillmentMethod } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';

export const DEFAULT_DISPATCH_STATES: {
  [DispatchType.DELIVERY]: DispatchState;
  [DispatchType.PICKUP]: DispatchState;
} = {
  [DispatchType.PICKUP]: {
    dispatchTime: EMPTY_DISPATCH_TIME,
    selectedSameDayScheduling: undefined,
    minimumOrder: 0,
    prepTime: DEFAULT_PREP_TIME,
  },
  [DispatchType.DELIVERY]: {
    dispatchTime: EMPTY_DISPATCH_TIME,
    selectedSameDayScheduling: undefined,
    minimumOrder: 0,
    prepTime: DEFAULT_PREP_TIME,
  },
};

export type RootState = {
  cartLineItems: Map<string, CartLineItem[]>;
  pubsub: Pubsub;
  ModalService?: IModalService;
  CartService?: ICartService;
  PersistDataService?: IPersistDataService;
  biReporterService?: IBIReporterService;
  operation?: Operation;
  currency: string;
  canAcceptOrders: boolean;
  selectedDispatchType: DispatchType;
  [DispatchType.DELIVERY]: DispatchState;
  [DispatchType.PICKUP]: DispatchState;
  isAddressSelected: boolean;
  configuredDispatchTypes?: DispatchType[];
  availableDispatches?: AvailableDispatchesInfo;
  availableDispatchTypes: DispatchType[];
  initialDispatchState: {
    [DispatchType.DELIVERY]: DispatchState;
    [DispatchType.PICKUP]: DispatchState;
  };
  initialTimeSlotText: string;
  preorderTimeWindows?: FulfillmentTimeWindows;
  allFulfillments: FulfillmentMethod[];
};

export const { state } = initState<RootState>({
  cartLineItems: new Map(),
  pubsub: new Pubsub(),
  currency: DEFAULT_CURRENCY_CODE,
  canAcceptOrders: true,
  selectedDispatchType: DispatchType.DELIVERY,
  ...DEFAULT_DISPATCH_STATES,
  [DispatchType.PICKUP]: DEFAULT_DISPATCH_STATES[DispatchType.PICKUP],
  [DispatchType.DELIVERY]: DEFAULT_DISPATCH_STATES[DispatchType.DELIVERY],
  isAddressSelected: false,
  availableDispatchTypes: [],
  initialDispatchState: DEFAULT_DISPATCH_STATES,
  initialTimeSlotText: '',
  allFulfillments: [],
});
