import model from './model';
import { DishesController } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { hasDataChanged } from '../../utils/utils';
import type { DishesWidgetProps } from '../../types/menusTypes';
import { DEFAULT_TIMEZONE } from 'root/api/consts';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { controllerConfig, translations, environment, formatCurrency } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;
  const timezone = wixCodeApi.site.timezone || DEFAULT_TIMEZONE;

  const dishesController = new DishesController(
    $bind,
    $w,
    t,
    formatCurrency,
    timezone,
    isMobile,
    isMemberLoggedIn
  );

  $widget.onPropsChanged((prevProps, nextProps) => {
    const shouldInit = hasDataChanged({
      prevData: prevProps.data?.items,
      nextData: nextProps.data?.items,
    });

    if (shouldInit) {
      const { items, menuId, sectionId }: DishesWidgetProps = nextProps.data;
      dishesController.init(items, isEditor, menuId, sectionId);
    }
  });
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { items, menuId, sectionId }: DishesWidgetProps = $widget.props.data;
      if (isEditor && items.length > 0) {
        dishesController.deleteItemCounterInEditor();
      }
      dishesController.init(items, isEditor, menuId, sectionId);
    },
    exports: {},
  };
});
