import type { DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { SchedulingType } from 'root/types/businessTypes';
import type { DispatchType, _DispatchTime } from 'root/types/businessTypes';
import type { DurationRange } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { HeaderStore } from 'root/states/HeaderStore';
import type { TimeRangeForCart } from 'root/services/cartService';
import type { ASAPOptions } from './types';
import { state as rootState } from 'root/states/RootState';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';

export const fullDateOptions: DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const wrapTimeInfoWithPrefix = (
  timeInfo: string,
  dispatchType: DispatchType,
  t: TFunction
) => {
  const prefix = t(`header_olo.time.${dispatchType}`);
  return `${prefix}${timeInfo}`;
};

export function isTomorrow(date: DateTime, timezone: string) {
  const tomorrow = DateTime.local({ zone: timezone }).startOf('day').plus({ days: 1 });
  return date.startOf('day').valueOf() === tomorrow.valueOf();
}

export function isToday(date: DateTime, timezone: string) {
  const today = DateTime.local({ zone: timezone }).startOf('day');
  return date.startOf('day').valueOf() === today.valueOf();
}

const getRangeString = ({ from, until, t }: { from: string; until: string; t: TFunction }) =>
  t('menu_olo.dispatch.time.simpleRange', { from, until });

export function getTimeString(time: _DispatchTime, t: TFunction, useFullDateOptions = false) {
  if (time.from.valueOf() !== time.until.valueOf()) {
    return getRangeString({
      from: useFullDateOptions
        ? time.from.toLocaleString(fullDateOptions)
        : time.from.toLocaleString(DateTime.TIME_SIMPLE),
      until: time.until.toLocaleString(DateTime.TIME_SIMPLE),
      t,
    });
  }
  return useFullDateOptions
    ? time.from.toLocaleString(fullDateOptions)
    : time.from.toLocaleString(DateTime.TIME_SIMPLE);
}

export function convertTimeToTimeString({
  time,
  t,
  timezone,
  dispatchType,
}: {
  time: _DispatchTime;
  timezone: string;
  t: TFunction;
  dispatchType: DispatchType;
}) {
  let timeString = '';
  if (isToday(time.from, timezone)) {
    timeString = t('menu_olo.dispatch.time.today', {
      time: getTimeString(time, t),
    });
  } else if (isTomorrow(time.from, timezone)) {
    timeString = t('menu_olo.dispatch.time.tomorrow', {
      time: getTimeString(time, t),
    });
  } else {
    timeString = getTimeString(time, t, true);
  }
  return wrapTimeInfoWithPrefix(timeString, dispatchType, t);
}

export const STATUS_INDICATOR_COLORS = {
  ONLINE: '#43B72A',
  OFFLINE: '#DF3131',
};

export const getASAPString = (
  exactTime: number | undefined,
  timeRange: DurationRange | undefined,
  t: TFunction,
  dispatchType: DispatchType
) => {
  let timeString = '';

  if (exactTime) {
    timeString = t('menu_olo.dispatch.time.upTo.minutes', {
      upTo: exactTime,
    });
  } else if (timeRange) {
    timeString = t('menu_olo.dispatch.time.range.minutes', {
      from: timeRange.minDuration,
      until: timeRange.maxDuration,
    });
  }

  return wrapTimeInfoWithPrefix(timeString, dispatchType, t);
};

export const resolveTimeString = ({
  asapOptions: { isASAP, exactTime, timeRange } = {} as ASAPOptions,
  selectedTime,
  t,
  timezone,
  dispatchType,
}: {
  asapOptions?: ASAPOptions;
  selectedTime?: _DispatchTime;
  t: TFunction;
  timezone: string;
  dispatchType: DispatchType;
}) => {
  if (isASAP && !!(exactTime || timeRange)) {
    return getASAPString(exactTime, timeRange, t, dispatchType);
  } else if (selectedTime) {
    return convertTimeToTimeString({
      time: selectedTime,
      t,
      timezone,
      dispatchType,
    });
  }
  return '';
};

export function getCartShippingDetailsFromHeaderStore(headerStore: HeaderStore) {
  let timeRange: TimeRangeForCart | undefined;

  if (headerStore.schedulingType !== SchedulingType.ASAP && headerStore.selectedTime) {
    timeRange = {
      start: headerStore.selectedTime.from.toUTC().valueOf(),
      end: headerStore.selectedTime.until.toUTC().valueOf(),
    };
  }
  return {
    address: headerStore.selectedAddress,
    dispatchType: headerStore.dispatchType,
    timeRange,
  };
}

export const getOnDispatchTypeChange =
  (headerStore: HeaderStore, isMemberLoggedIn?: boolean) => (dispatchType: DispatchType) => {
    rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
      origin: LiveSiteClickFulfillmentOrigin.FULFILLMENT_TAB,
      dispatchType,
      isMemberLoggedIn,
    });
    // TODO: Open dispatchModal
    headerStore.updateDispatchState(dispatchType, {});
  };
