import model from './model';
import { HeaderController } from './headerController';
import { FulfillmentsClient } from '../../api/fulfillmentsClient';
import type { Operation } from '../../types/businessTypes';
import { DispatchType } from '../../types/businessTypes';
import type { HeaderWidgetProps } from '../../types/menusTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { state as rootState } from '../../states/RootState';
import { getPreorderTimeWindows } from 'root/logic/getPreorderTimeWindows';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import type { FulfillmentMethod } from '@wix/ambassador-restaurants-v1-fulfillment-method/types';
import { SPECS } from 'root/appConsts/experiments';
import type { IHeaderController } from './types';
import { HeaderController as NewHeaderController } from './newHeaderController';

const emptyPreorderTimeWindows = {
  [DispatchType.PICKUP]: [],
  [DispatchType.DELIVERY]: [],
};

const DEFAULT_PREORDER_TIME_WINDOW_DURATION = 15;

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const { formatCurrency, translations, experiments, controllerConfig, environment } = flowAPI;
  const t = translations.t as TFunction;
  const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser.loggedIn;
  const warmupData = new WarmupDataManager(
    controllerConfig.wixCodeApi.window.warmupData,
    environment.isSSR
  );

  const useNewHeaderController = experiments.enabled(SPECS.newHeaderAndDispatchModal);
  let headerController: IHeaderController;
  let initHeaderController: (props: {
    operation: Operation;
    data: HeaderWidgetProps;
  }) => Promise<void>;

  if (useNewHeaderController) {
    headerController = new NewHeaderController(
      $bindAll,
      $w,
      t,
      formatCurrency,
      controllerConfig.wixCodeApi.site,
      isMemberLoggedIn,
      environment.isSSR
    );

    initHeaderController = async (props: { operation: Operation; data: HeaderWidgetProps }) => {
      if (shouldInitHeaderTexts(props)) {
        headerController.initHeaderTexts({ ...props.data, t });
      }

      const operation = props.operation;

      if (operation?.id) {
        headerController.init({ operation });
      }
    };
  } else {
    headerController = new HeaderController(
      $bindAll,
      $w,
      t,
      formatCurrency,
      controllerConfig.wixCodeApi.site,
      isMemberLoggedIn,
      experiments,
      environment.isSSR
    );

    initHeaderController = async (props: { operation: Operation; data: HeaderWidgetProps }) => {
      if (shouldInitHeaderTexts(props)) {
        headerController.initHeaderTexts({ ...props.data, t });
      }

      try {
        const operation = props.operation;
        if (operation?.id) {
          const fulfillmentsClient = new FulfillmentsClient(flowAPI.httpClient, operation.id);

          const allFulfillmentsPromise = warmupData.manageData<FulfillmentMethod[]>(
            () => fulfillmentsClient.fetchAllFulfillments(operation.fulfillmentIds),
            'allFulfillments'
          );
          const allFulfillmentsData = await Promise.resolve(allFulfillmentsPromise);
          rootState.allFulfillments = allFulfillmentsData || [];
          const { address } =
            rootState.PersistDataService?.getSelectedDispatchDetails(
              controllerConfig.wixCodeApi.site.timezone
            ) ?? {};

          const { configuredDispatchTypes, availableDispatches } =
            await fulfillmentsClient.getAvailabilityInfo(address);
          rootState.configuredDispatchTypes = configuredDispatchTypes;
          rootState.availableDispatches = availableDispatches;
          rootState.preorderTimeWindows =
            operation.operationType === 'PRE_ORDER'
              ? getPreorderTimeWindows(
                  availableDispatches,
                  operation.preOrderOptions?.timeWindowDuration ??
                    DEFAULT_PREORDER_TIME_WINDOW_DURATION
                )
              : emptyPreorderTimeWindows;

          rootState.availableDispatchTypes = [];
          if (availableDispatches[DispatchType.PICKUP].fulfillments?.length > 0) {
            rootState.availableDispatchTypes.push(DispatchType.PICKUP);
          }
          if (availableDispatches[DispatchType.DELIVERY].fulfillments?.length > 0) {
            rootState.availableDispatchTypes.push(DispatchType.DELIVERY);
          }

          headerController.init({
            operation,
            configuredDispatchTypes,
            availableDispatches,
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error ', e);
      }
    };
  }

  const shouldInitHeaderTexts = (props: { data: HeaderWidgetProps }) => {
    const { headerTitle, headerDescription }: HeaderWidgetProps = props.data;
    return headerTitle || headerDescription;
  };
  const shouldInitHeader = (
    props: { operation: Operation; data: HeaderWidgetProps },
    newProps: { operation: Operation; data: HeaderWidgetProps }
  ) => !props.operation && newProps.operation;

  $widget.onPropsChanged((_, nextProps) => {
    if (shouldInitHeader(_, nextProps)) {
      initHeaderController(nextProps);
    }
    if (shouldInitHeaderTexts(nextProps)) {
      headerController.initHeaderTexts({ ...nextProps.data, t });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      headerController.deleteFulfillmentContainer();
      headerController.deleteBadgesContainer();
      headerController.initHeaderTexts({ ...$widget.props.data, t });
      await initHeaderController($widget.props);
    },
    exports: {},
  };
});
