import type { DispatchesInfo, DispatchState, DispatchInfo } from 'root/types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { makeAutoObservable } from 'mobx';

class _DispatchState {
  selectedDispatchType: DispatchType = DispatchType.PICKUP;
  dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

  constructor() {
    makeAutoObservable(this);
  }

  get dispatchInfo(): DispatchInfo {
    return this.dispatchesInfo[this.selectedDispatchType] ?? {};
  }

  init(dispatchState: DispatchState) {
    Object.assign(this, dispatchState);
  }

  update(dispatchType: DispatchType, dispatchInfo: DispatchInfo) {
    const currentDispatchInfo = this.dispatchesInfo[dispatchType];
    this.dispatchesInfo[dispatchType] = { ...currentDispatchInfo, ...dispatchInfo };
    this.selectedDispatchType = dispatchType;
  }

  get configuredDispatchTypes() {
    return Object.keys(this.dispatchesInfo) as DispatchType[];
  }

  get availableDispatchTypes() {
    return this.configuredDispatchTypes.filter(
      (dispatchType) => !!this.dispatchesInfo[dispatchType].selectedTimeSlot
    );
  }

  isDispatchAvailable(dispatchType: DispatchType) {
    return this.availableDispatchTypes.includes(dispatchType);
  }

  isDispatchConfigured(dispatchType: DispatchType) {
    return this.configuredDispatchTypes.includes(dispatchType);
  }
}

export const dispatchState = new _DispatchState();
