import model from './model';
import { FulfillmentPickerController } from './FulfillmentPickerController';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';
import type { TFunction } from '@wix/yoshi-flow-editor';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const t = flowAPI.translations.t as TFunction;
  const fulfillmentPickerController = new FulfillmentPickerController($w);

  $widget.onPropsChanged((_, nextProps) => {
    fulfillmentPickerController.initDispatchState(nextProps.data);
    fulfillmentPickerController.initFulfillmentPicker(nextProps.data.configuredDispatchTypes, t);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { configuredDispatchTypes, ...props } = $widget.props.data as FulfillmentPickerProps;
      fulfillmentPickerController.initFulfillmentPicker(configuredDispatchTypes, t);
      fulfillmentPickerController.initDispatchState(props);
    },
    exports: {},
  };
});
