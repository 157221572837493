import type { I$W, ISiteApis, TFunction } from '@wix/yoshi-flow-editor';
import { HEADER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { FulfillmentContextProps } from '../../contexts/FulfillmentContext';
import { getInitialHeaderState, HeaderState } from '../../states/HeaderState';
import {
  getAcceptOrdersProps,
  getAddressChangeProps,
  getAddressInfoProps,
  getDeliveryFeeProps,
  getDispatchPickerProps,
  getHeaderContainerProps,
  getMinOrderProps,
  getStatusIndicatorProps,
  getTimeChangeProps,
  getTimeInfoProps,
} from './headerUtils';
import { state as rootState } from '../../states/RootState';
import { bindHeader } from './headerBindings';
import { BusinessOpenStatus, DispatchType } from '../../types/businessTypes';
import type { DispatchModalForm } from '../../utils/dispatchModalUtils';
import {
  getCartShippingDetails,
  getCartShippingDetailsByForm,
  getDispatchModalProps,
  updateStateFromForm,
} from '../../utils/dispatchModalUtils';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '../../api/consts';
import type { BindAll, IHeaderController } from './types';
import { SPECS } from 'root/appConsts/experiments';
import { toJS } from 'mobx';

export class HeaderController implements IHeaderController {
  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private t: TFunction,
    private formatCurrency: Function,
    private site: ISiteApis,
    private isMemberLoggedIn: boolean | undefined,
    private experiments: { enabled(key: string): boolean },
    private isSSR: boolean
  ) {}

  init({ configuredDispatchTypes, operation, availableDispatches }: FulfillmentContextProps) {
    const timezone = this.site.timezone || DEFAULT_TIMEZONE;
    const locale = this.site.regionalSettings || DEFAULT_LOCALE;
    const state = new HeaderState(
      getInitialHeaderState({
        operation,
        availableDispatches,
        timezone,
        saveDispatchDetails: this.experiments.enabled(SPECS.saveDispatchDetails),
      })
    );
    const availablePickup = availableDispatches[DispatchType.PICKUP].fulfillments[0];
    const onSaveDispatch = (form: DispatchModalForm) => {
      updateStateFromForm(form);

      rootState.CartService &&
        rootState.CartService.setShippingDetails(
          getCartShippingDetailsByForm(form, timezone, rootState[DispatchType.PICKUP]?.address)
        );
    };

    // eslint-disable-next-line no-console
    console.log('headerController isSSR :>> ', this.isSSR);
    if (!this.isSSR && state.openStatus === BusinessOpenStatus.Open) {
      const { selectedSameDayScheduling, dispatchTime } = state;
      const shippingDetails = getCartShippingDetails({
        timezone,
        selectedDispatchType: rootState.selectedDispatchType,
        selectedSameDayScheduling,
        selectedTime: dispatchTime,
        pickupAddress: rootState[DispatchType.PICKUP]?.address,
        deliveryAddress: rootState[DispatchType.DELIVERY]?.address,
      });
      rootState.CartService?.setShippingDetails(shippingDetails);
    }

    const dispatchModalProps = () =>
      getDispatchModalProps({
        selectedDispatchType: rootState.selectedDispatchType,
        pickupState: toJS(rootState[DispatchType.PICKUP]),
        deliveryState: toJS(rootState[DispatchType.DELIVERY]),
        configuredDispatchTypes,
        availableDispatchTypes: rootState.availableDispatchTypes,
        onSaveDispatch,
        operation,
        availablePickup,
        biReporterService: rootState.biReporterService,
      });

    const timeInfoProps = getTimeInfoProps(
      state,
      this.t,
      rootState.canAcceptOrders,
      timezone,
      locale,
      operation.asapOptions
    );

    const shouldRestoreFulfillmentContainer =
      !!timeInfoProps.displayableTimeInfo().displayableTime ||
      state.openStatus === BusinessOpenStatus.Closed;

    rootState.initialTimeSlotText = timeInfoProps.displayableTimeInfo().timeInfoText;
    const minOrderInfoProps = getMinOrderProps(this.t, this.formatCurrency);
    const deliveryFeeProps = getDeliveryFeeProps(this.t, this.formatCurrency);

    if (shouldRestoreFulfillmentContainer) {
      this.restoreFulfillmentContainer();
    }

    this.restoreBadgesContainer();

    bindHeader({
      $bindAll: this.$bindAll,
      componentsProps: {
        timeChange: getTimeChangeProps(this.t, dispatchModalProps, this.isMemberLoggedIn),
        addressChange: getAddressChangeProps(
          state,
          this.t,
          dispatchModalProps,
          this.isMemberLoggedIn
        ),
        deliveryFee: deliveryFeeProps,
        timeInfo: timeInfoProps,
        addressInfo: getAddressInfoProps(this.t),
        statusIndicator: getStatusIndicatorProps(rootState.canAcceptOrders),
        acceptOrders: getAcceptOrdersProps(rootState.canAcceptOrders, this.t),
        minOrder: minOrderInfoProps,
        headerContainer: getHeaderContainerProps(rootState.pubsub),
        dispatchPicker: getDispatchPickerProps(
          configuredDispatchTypes,
          dispatchModalProps,
          this.isMemberLoggedIn
        ),
      },
    });
  }

  initHeaderTexts({
    t,
    headerTitle,
    headerDescription,
  }: {
    t: TFunction;
    headerTitle: string;
    headerDescription: string;
  }) {
    this.$bindAll({
      [HEADER_WIDGET_COMPONENT_IDS.headerTitle]: {
        text: () => {
          return headerTitle || t('header_olo.default.title');
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.headerDescription]: {
        text: () => headerDescription || t('header_olo.default.description'),
      },
    });
  }

  deleteElement(selector: string) {
    this.$w(selector).delete();
  }

  restoreElement(selector: string) {
    this.$w(selector).restore();
  }

  deleteFulfillmentContainer() {
    this.deleteElement(HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer);
  }

  restoreFulfillmentContainer() {
    this.restoreElement(HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer);
  }

  deleteBadgesContainer() {
    this.deleteElement(HEADER_WIDGET_COMPONENT_IDS.badgesContainer);
  }

  restoreBadgesContainer() {
    this.restoreElement(HEADER_WIDGET_COMPONENT_IDS.badgesContainer);
  }
}
