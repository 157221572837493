import { DISPATCH_MODAL_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { AddressInputAddress, SameDayScheduling } from '../../types/businessTypes';
import type { I$W } from '@wix/yoshi-flow-editor';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';
import type { BindAll } from '@wix/velocycle-mobx';

export interface BindDispatchModalProps {
  $bindAll: BindAll<I$W>;
  componentsProps: {
    timeSlots: TimeSlotsProps;
    saveButton: SaveButtonProps;
    schedulingPicker: SchedulingPickerProps;
    closeButton: CloseButtonProps;
    errorMessage: ErrorMessageProps;
    addressInput: AddressInputProps;
    datePicker: DatePickerProps;
    ASAPText: TextElementProps;
    dispatchPicker: DispatchPickerProps;
    pickupAddress: TextElementProps;
    pickupHeader: TextElementProps;
    modalTitle: TextElementProps;
  };
}
export function bindDispatchModal(props: BindDispatchModalProps) {
  const { $bindAll, componentsProps } = props;
  const {
    timeSlots,
    saveButton,
    ASAPText,
    schedulingPicker,
    closeButton,
    errorMessage,
    addressInput,
    datePicker,
    dispatchPicker,
    pickupAddress,
    pickupHeader,
    modalTitle,
  } = componentsProps;
  $bindAll({
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeSlots]: {
      collapsed: () => timeSlots.collapsed(),
      value: () => timeSlots.value(),
      options: () => timeSlots.options(),
      onChange: timeSlots.onChange,
      onClick: timeSlots.onClick,
      label: () => timeSlots.label(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.saveButton]: {
      onClick: () => saveButton.onClick(),
      label: () => saveButton.label(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.addressInput]: {
      onChange: addressInput.onChange,
      onClick: addressInput.onClick,
      value: () => addressInput.value(),
      label: () => addressInput.label(),
      placeholder: () => addressInput.placeholder(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapText]: {
      text: () => ASAPText.text(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.closeButton]: {
      onClick: closeButton.onClick,
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorMessage]: {
      text: () => errorMessage.text(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorContainer]: {
      collapsed: () => errorMessage.collapsed(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePickerContainer]: {
      collapsed: () => datePicker.collapsed(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePicker]: {
      onChange: datePicker.onDateChange,
      onClick: datePicker.onDateClick,
      disabledDaysOfWeek: () => datePicker.disabledDaysOfWeek(),
      timeZone: () => datePicker.timezone(),
      value: () => datePicker.value(),
      label: () => datePicker.label(),
      minDate: () => datePicker.minDate(),
      maxDate: () => datePicker.maxDate(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateTimeSlots]: {
      options: () => datePicker.options(),
      onChange: datePicker.onTimeSlotChange,
      onClick: datePicker.onTimeSlotClick,
      label: () => timeSlots.label(),
      value: () => datePicker.timeSlotsValue(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.schedulingPicker]: {
      collapsed: () => schedulingPicker.collapsed(),
      onChange: schedulingPicker.onChange,
      disabled: () => schedulingPicker.disabled(),
      options: () => schedulingPicker.options(),
      value: () => schedulingPicker.value(),
      label: () => schedulingPicker.label(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
      collapsed: () => dispatchPicker.collapsed(),
      data: () => dispatchPicker.data(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupAddress]: {
      text: () => pickupAddress.text(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupHeader]: {
      text: () => pickupHeader.text(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.title]: {
      text: () => modalTitle.text(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapTime]: {
      text: () => schedulingPicker.label(),
    },
    [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.preOrderTime]: {
      text: () => schedulingPicker.label(),
    },
  });
}

export interface TimeSlotsProps {
  collapsed: () => boolean;
  value: () => string;
  options: () => { label: string; value: string }[];
  onChange: (evt: $w.Event) => void;
  onClick: (evt: $w.Event) => void;
  label: () => string;
}

export interface SaveButtonProps {
  onClick: () => void;
  label: () => string;
}

export interface SchedulingPickerProps {
  collapsed: () => boolean;
  value: () => SameDayScheduling | undefined;
  disabled: () => boolean;
  options: () => { label: string; value: string }[];
  onChange: (evt: $w.Event) => void;
  label: () => string;
}

export interface CloseButtonProps {
  onClick: () => void;
}

export interface DispatchPickerProps {
  collapsed: () => boolean;
  data: () => FulfillmentPickerProps;
}

export interface ErrorMessageProps {
  collapsed: () => boolean;
  text: () => string | undefined;
}

export interface AddressInputProps {
  onChange: (evt: $w.Event) => Promise<void>;
  onClick: (evt: $w.Event) => void;
  value: () => AddressInputAddress | undefined;
  label: () => string;
  placeholder: () => string;
}

export interface DatePickerProps {
  disabledDaysOfWeek: () => number[];
  timezone: () => string;
  onDateChange: (evt: $w.Event) => void;
  onDateClick: (evt: $w.Event) => void;
  onTimeSlotChange: (evt: $w.Event) => void;
  onTimeSlotClick: (evt: $w.Event) => void;
  value: () => Date | undefined;
  timeSlotsValue: () => string;
  options: () => { label: string; value: string }[] | undefined;
  label: () => string;
  minDate: () => Date | undefined;
  maxDate: () => Date | undefined;
  collapsed: () => boolean;
}

export interface TextElementProps {
  text: () => string;
}
