import { makeAutoObservable } from 'mobx';
import type { Experiments, ISiteApis, TFunction } from '@wix/yoshi-flow-editor';
import type {
  Address,
  DAY_OF_THE_WEEK,
  DispatchTime,
  MinMaxRange,
  Minute,
  WeeklyAvailability,
} from '../../../types/businessTypes';
import { DispatchType, SameDayScheduling } from '../../../types/businessTypes';
import type { DispatchModalProps } from '../../../types/widgetsProps';
import { convertOptionToDispatchTime } from '../../../logic/timeUtils';
import type { FulfillmentOption } from '../../../contexts/FulfillmentContext';
import { DEFAULT_DISPATCH_FORM } from './defaults';
import { getInitialDispatchForm } from './initialStateLogic';
import type { DateTime } from 'luxon';

export enum ErrorType {
  ADDRESS_NOT_FOUND,
  NO_ADDRESS,
  NO_FULFILLMENTS,
}

export interface DispatchForm {
  availableFulfillments: FulfillmentOption[];
  selectedSchedulingType?: SameDayScheduling;
  prepTime: MinMaxRange;
  selectedTimeOptionId: string;
  selectedTime: DispatchTime;
  selectedDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  selectedDayOfTheWeek?: DAY_OF_THE_WEEK;
  options?: { value: string; label: string }[];
  error?: {
    type: ErrorType;
    message?: string;
  };
  address?: Address;
  weeklyAvailability: WeeklyAvailability;
  preOrderTimeWindows?: DispatchTime[];
}

export interface DispatchFormState {
  timeWindow: Minute | undefined;
  selectedDispatchType: DispatchType;
  [DispatchType.PICKUP]: DispatchForm;
}

export class DispatchModalState implements DispatchFormState {
  selectedDispatchType = DispatchType.PICKUP;
  timeWindow: Minute | undefined = undefined;
  [DispatchType.DELIVERY]: DispatchForm = DEFAULT_DISPATCH_FORM;
  [DispatchType.PICKUP]: DispatchForm = DEFAULT_DISPATCH_FORM;

  constructor(props: DispatchModalProps, site: ISiteApis, t: TFunction, experiments: Experiments) {
    const {
      selectedDispatchType,
      preorderOptions,
      operationType,
      [DispatchType.PICKUP]: pickupState,
      [DispatchType.DELIVERY]: deliveryState,
      availablePickupDispatches,
      schedulingType,
    } = props;
    this.selectedDispatchType = selectedDispatchType;
    this.timeWindow = props.preorderOptions?.timeWindowDuration;
    this[DispatchType.DELIVERY] = getInitialDispatchForm({
      dispatchState: deliveryState,
      availableDispatches: [],
      preorderOptions,
      operationType,
      site,
      t,
      experiments,
      schedulingType,
    });
    this[DispatchType.PICKUP] = getInitialDispatchForm({
      dispatchState: pickupState,
      availableDispatches: availablePickupDispatches,
      preorderOptions,
      operationType,
      site,
      t,
      experiments,
      schedulingType,
    });
    makeAutoObservable(this);
  }

  get selectedForm(): DispatchForm {
    return this[this.selectedDispatchType];
  }

  resetState(state: DispatchForm) {
    this[this.selectedDispatchType] = state;
  }

  get dispatchType(): DispatchType {
    return this.selectedDispatchType;
  }

  get selectedSchedulingType(): SameDayScheduling {
    return this.selectedForm.selectedSchedulingType || SameDayScheduling.ASAP;
  }

  get selectedTime(): DispatchTime {
    return this.selectedForm.selectedTime;
  }

  get selectedDate(): DateTime | undefined {
    return this.selectedForm.selectedDate;
  }

  get minDate(): DateTime | undefined {
    return this.selectedForm.minDate;
  }

  get maxDate(): DateTime | undefined {
    return this.selectedForm.maxDate;
  }

  get selectedDayOfTheWeek(): DAY_OF_THE_WEEK {
    return this.selectedForm.selectedDayOfTheWeek || 0;
  }

  get options(): { value: string; label: string }[] {
    return this.selectedForm.options || [];
  }

  get selectedTimeOptionId() {
    return this.selectedForm.selectedTimeOptionId;
  }

  get error(): {
    type: ErrorType;
    message?: string;
  } | null {
    return this.selectedForm.error || null;
  }

  get address(): Address | undefined {
    return this.selectedForm.address;
  }

  get state(): DispatchFormState {
    return this;
  }

  get availableFulfillments(): FulfillmentOption[] {
    return this.selectedForm.availableFulfillments;
  }

  get preOrderTimeWindows(): DispatchTime[] | undefined {
    return this.selectedForm.preOrderTimeWindows;
  }

  get isDelivery(): boolean {
    return this.selectedDispatchType === DispatchType.DELIVERY;
  }

  get weeklyAvailability(): WeeklyAvailability {
    return this.selectedForm.weeklyAvailability;
  }

  setWeeklyAvailability(weeklyAvailability: WeeklyAvailability): void {
    this.selectedForm.weeklyAvailability = weeklyAvailability;
  }

  get isPickup(): boolean {
    return this.selectedDispatchType === DispatchType.PICKUP;
  }

  get isAsap(): boolean {
    return this.selectedSchedulingType === SameDayScheduling.ASAP;
  }

  get isSameDay(): boolean {
    return this.selectedSchedulingType === SameDayScheduling.SAME_DAY;
  }

  setDispatchType(dispatchType: DispatchType): void {
    this.selectedDispatchType = dispatchType;
  }

  setSchedulingType(schedulingType: SameDayScheduling | undefined): void {
    this.selectedForm.selectedSchedulingType = schedulingType;
  }

  setDayOfTheWeek(dayOfTheWeek: DAY_OF_THE_WEEK): void {
    this.selectedForm.selectedDayOfTheWeek = dayOfTheWeek;
  }

  setTime(time: DispatchTime): void {
    this.selectedForm.selectedTime = time;
  }

  setDate(date?: DateTime): void {
    this.selectedForm.selectedDate = date;
  }

  setOptions(options: { value: string; label: string }[] | undefined): void {
    this.selectedForm.options = options;
  }

  setSelectedOption(optionId: string): void {
    this.selectedForm.selectedTimeOptionId = optionId;
    this.selectedForm.selectedTime = convertOptionToDispatchTime(optionId);
  }

  setError(error: { type: ErrorType; message?: string } | undefined): void {
    this.selectedForm.error = error;
  }

  setMaxDate(date: DateTime | undefined): void {
    this.selectedForm.maxDate = date;
  }

  setMinDate(date: DateTime | undefined): void {
    this.selectedForm.minDate = date;
  }

  setAvailableFulfillments(availableFulfillments: FulfillmentOption[]) {
    this.selectedForm.availableFulfillments = availableFulfillments;
  }

  setAddress(address: Address): void {
    this.selectedForm.address = address;
  }
}
