import type { LineItem } from '@wix/ambassador-ecom-v1-cart/types';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { CartLineItem } from '../services/cartService';
import { arrayToStringWithSeparator } from './utils';
import { DispatchType, SameDayScheduling } from '../types/businessTypes';
import type { SelectedDispatchDetails } from 'root/services/persistDataService/persistDataService';
import { getDispatchTimeFromTimeRange } from 'root/logic/timeUtils';

export const isCartContainMixedItems = (cartItems: LineItem[]) => {
  return cartItems?.some((i) => i.catalogReference?.appId !== APP_DEF_IDS.orders);
};

export const findFullCartItem = (cartItems: LineItem[], item: CartLineItem) => {
  const catalogLineItems =
    cartItems?.filter((i) => i.catalogReference?.catalogItemId === item.catalogItemId) ?? [];

  const currentLineItemsIds = cartItems?.map((cartItem) => cartItem.id);
  return (
    catalogLineItems?.find((i) => currentLineItemsIds?.every((id) => id !== i.id)) ??
    catalogLineItems[0]
  );
};

const ASAP_CODE = 'ASAP';
const SHIPPING_CODE_SEPARATOR = '|';
export const getShippingOptionCode = (
  dispatchType: DispatchType,
  timeRange:
    | {
        start: number;
        end: number;
      }
    | undefined
) => {
  const timeValues = timeRange ? [timeRange.start, timeRange.end] : [ASAP_CODE];

  const shippingOptionCodeValues = [dispatchType, ...timeValues];
  return arrayToStringWithSeparator(shippingOptionCodeValues, SHIPPING_CODE_SEPARATOR);
};

export const getCartItemById = (cartLineItems: Map<string, CartLineItem[]>, cartItemId: string) => {
  for (const [, lineItems] of cartLineItems.entries()) {
    const cartItem = lineItems.find((lineItem) => lineItem.id === cartItemId);

    if (cartItem) {
      return cartItem;
    }
  }
};

export const convertShippingOptionToDispatchDetails = (
  selectedShippingOption: string | undefined,
  timezone: string | undefined
) => {
  if (selectedShippingOption) {
    const [dispatchType, fromDate, untilDate] = selectedShippingOption.split('|');
    const dispatchTime =
      fromDate !== SameDayScheduling.ASAP
        ? getDispatchTimeFromTimeRange(Number(fromDate), Number(untilDate), timezone)
        : undefined;

    const selectedDispatchDetails: SelectedDispatchDetails = {
      dispatchType: DispatchType[dispatchType as keyof typeof DispatchType],
      dispatchTime,
    };
    return selectedDispatchDetails;
  }
};
