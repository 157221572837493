import model from './model';
import { DispatchModalController } from './dispatchModalController';
import type { DispatchModalProps } from '../../types/widgetsProps';
import type { IWixWindow } from '@wix/yoshi-flow-editor';
import { registerToUrlChange } from 'root/utils/navigationUtils';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { controllerConfig, experiments } = flowAPI;
  const { window } = controllerConfig.wixCodeApi;
  const lightboxContext = window.lightbox.getContext() || {};
  const {
    data: props,
    onModalOpen,
    closeModal,
  }: {
    data?: DispatchModalProps;
    onModalOpen: () => void;
    closeModal: (window: IWixWindow, data?: DispatchModalProps) => void;
  } = lightboxContext;
  const dispatchModalController = new DispatchModalController(
    $bindAll,
    $w,
    props,
    flowAPI,
    window,
    experiments
  );

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);
      dispatchModalController.init(onModalOpen, closeModal);
    },
    exports: {},
  };
});
