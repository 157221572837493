import type { DateTime } from 'luxon';
import type {
  AddressLocation,
  CommonAddress,
  StreetAddress,
} from '@wix/ambassador-wix-atlas-service-web/types';
import { SchedulingType as SchedulingTypeAPI } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { FulfillmentDetails } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { MinutesRange } from '../logic/MinutesRange';

type DispatchAvailabilities = 'ASAP' | 'PRE_ORDER' | 'SAME_DAY';

export type DispatchMethod = {
  dispatchAvailabilities: Set<DispatchAvailabilities>;
  dispatchType: DispatchType;
};

export type MinMaxRange = {
  min: Minute;
  max: Minute;
};
export type BusinessProperties = {
  currency: string;
  address: string;
};

export type Money = {
  amount: number;
  currency: string;
};

export const BusinessOpenStatus = {
  Open: 'Open',
  Closed: 'Closed',
  ClosingSoon: 'ClosingSoon',
  OpeningSoon: 'OpeningSoon',
};

export type DispatchTime = {
  from: MinuteOfTheWeek;
  until: MinuteOfTheWeek;
  date?: DateTime;
};

export type _DispatchTime = {
  from: DateTime;
  until: DateTime;
};

export type TimingType = 'up_to' | 'range' | 'exact';
type BusinessOpenStatusKeys = keyof typeof BusinessOpenStatus;

export type BusinessOpenStatusOptions = (typeof BusinessOpenStatus)[BusinessOpenStatusKeys];

export const TimingOption = { LATER_TODAY: 'LATER_TODAY', ASAP: 'ASAP' };

export type Address = CommonAddress;
export type AddressInputAddress = {
  formatted: string;
  location?: AddressLocation;
  streetAddress?: StreetAddress;
  city?: string;
  subdivision?: string;
  country?: string;
  postalCode?: string;
};

export enum SchedulingType {
  ASAP = 'ASAP',
  SAME_DAY = 'SAME_DAY',
  PRE_ORDER = 'PRE_ORDER',
}
export enum SameDayScheduling {
  ASAP = 'ASAP',
  SAME_DAY = 'SAME_DAY',
}

export enum DispatchType {
  /** Pickup fulfillment */
  PICKUP = 'PICKUP',
  /** Delivery fulfillment */
  DELIVERY = 'DELIVERY',
}

export type WeeklyAvailability = MinutesRange[][];

export type OLOWidgetState = 'errorState' | 'olo';
export type MenuState = 'menuEmptystate' | 'menu';
export type SectionState = 'sectionEmptystate' | 'sections';
export type DishesWidgetState = 'dishesEmpty' | 'dishes';
export type DishState = 'outofStock' | 'regular';
export type ModifierRuleState = 'box14' | 'box13';

export const MENU_STATES: Record<string, MenuState> = {
  menuEmptystate: 'menuEmptystate',
  menu: 'menu',
};

export const SECTION_STATES: Record<string, SectionState> = {
  sectionEmptystate: 'sectionEmptystate',
  sections: 'sections',
};

export const DISHES_WIDGET_STATES: Record<string, DishesWidgetState> = {
  dishesEmpty: 'dishesEmpty',
  dishes: 'dishes',
};

export const DISH_STATES: Record<string, DishState> = {
  outofStock: 'outofStock',
  regular: 'regular',
};

export const MODIFIER_RULE_STATES: Record<string, ModifierRuleState> = {
  singleSelect: 'box14',
  multiSelect: 'box13',
};

type DispatchPickerType = 'delivery' | 'pickup';

// TODO: remove after dispatch modal refactor
export const DISPATCH_MODAL_DISPATCH_STATE: Record<DispatchType, DispatchPickerType> = {
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
};

export enum TimeState {
  ASAP_ONLY = 'asap',
  SAME_DAY = 'sameDay',
  SAME_DAY_LATER = 'sameDayLater',
  PRE_ORDER = 'preorder',
}

export const OPERATION_SCHEDULING_TYPE = SchedulingTypeAPI;

export type AsapScheduling = {
  maxInMinutes?: Minute;
  rangeInMinutes?: MinMaxRange;
};

export type PreorderScheduling = {
  timeWindowDuration: Minute;
  timeInAdvance: MinMaxRange;
};
export type MinuteOfTheWeek = number;

export type Minute = number;
export type Timestamp = number;
export type OperationType = 'PRE_ORDER' | 'ASAP';

export type Operation = {
  id: string;
  enabled: boolean;
  fulfillmentIds: string[];
  asapOptions?: AsapScheduling;
  allowSameDayPreorder?: boolean;
  operationType: OperationType;
  preOrderOptions?: PreorderScheduling;
};

export type DAY_OF_THE_WEEK = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type TimeSlot = {
  startTime: DateTime;
  endTime: DateTime;
  dispatchType: DispatchType;
  startsNow?: boolean;
  fulfillmentDetails: FulfillmentDetails;
};

export type DispatchInfo = {
  address?: Address;
  selectedTimeSlot?: TimeSlot;
  minOrder?: string;
  deliveryFee?: string;
  freeFulfillmentPriceThreshold?: string;
  minDate?: DateTime;
};

export type DispatchesInfo = Record<DispatchType, DispatchInfo>;

export type DispatchState = {
  selectedDispatchType: DispatchType;
  dispatchesInfo: DispatchesInfo;
};
