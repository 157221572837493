import {
  oloLiveSiteOloPageLoaded,
  restaurantsUouPageFinishedLoading,
  restaurantsUouPageStartedLoading,
  restaurantsOloLiveSiteClickOnFulfillment,
  restaurantsOloLiveSiteDispatchModalOpened,
  oloLiveSiteClickOnItem,
  oloLiveSiteItemModalOpened,
  oloLiveSiteAddDishToCart,
  restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuBar,
  restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuDropdownAndOtherMenusInside,
  restaurantsOloLiveSiteClicksInsideDispatchModal,
  restaurantsOloLiveSiteErrorDisplayedInDispatchModal,
  restaurantsOloLiveSiteDispatchModalClosed,
  oloGenericDebugEvent,
} from '@wix/bi-logger-restaurants/v2';
import type { SchedulingType } from '../types/businessTypes';
import { DispatchType } from '../types/businessTypes';
import type { PopulatedMenu } from '../types/menusTypes';
import { state } from '../states/RootState';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type {
  OloPageLoadedParams,
  OloPageStartedLoadingParams,
  OloPageFinishedLoadingParams,
  LiveSiteClickFulfillmentOrigin,
  OloLiveSiteClickOnFulfillmentParams,
  OloLiveSiteDispatchModalOpenedParams,
  OloLiveSiteClickOnItemParams,
  OloLiveSiteItemModalOpenedParams,
  OloLiveSiteAddDishToCartParams,
  OloLiveSiteClicksOnMenuBarParams,
  LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName,
  OloLiveSiteClicksOnMenuDropdownAndOptionInsideParams,
  OloLiveSiteClicksInsideDispatchModalParams,
  LiveSiteClicksInsideDispatchModalButtonType,
  OloLiveSiteErrorDisplayedInDispatchModalParams,
  LiveSiteDispatchModalClosedTriggerName,
  OloLiveSiteDispatchModalClosedParams,
  OloGenericDebugParams,
} from '@wix/restaurants-bi';
import {
  LivesiteFulfillmentType,
  LivesitePageLoadModeType,
  LivesitePageLoadStatusDisplayedType,
  LivesiteClickFulfillmentStatusDisplayedType,
  LiveSiteDispatchModalOpenedSchedulingType,
  PageName,
  WidgetName,
  Product,
} from '@wix/restaurants-bi';
import type { EditorSubType } from '@wix/editor-platform-sdk-types';
import { DEFAULT_ADDRESS, PAGE_DATA } from '../appConsts/consts';
import {
  getAmountOfItemsInCart,
  getAmountOfSectionsAndItems,
  getHostingEnvironment,
  getErrorType,
} from '../utils/biUtils';
import type { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';
import type { ErrorType } from '../components/DispatchModal/state/DispatchModalState';

type ItemModalCommonParams = {
  itemId: string;
  itemName?: string;
  menuId?: string;
  num_labels?: number;
  sectionId?: string;
};

type LiveSiteOloPageLoadedParams = {
  isMemberLoggedIn: boolean;
  menus: PopulatedMenu[];
};

type ClickOnFulfillmentParams = {
  origin: LiveSiteClickFulfillmentOrigin;
  dispatchType?: DispatchType;
  isMemberLoggedIn?: boolean;
};

type DispatchModalOpenedParams = {
  schedulingType: SchedulingType;
  isAsapDisplayed?: boolean;
  isPreorderDisplayed?: boolean;
  availableDispatchTypes?: DispatchType[];
  currentFulfillment: DispatchType;
  operationId: string;
};

type ClickOnItemParams = ItemModalCommonParams & {
  minItemPrice: number;
};

type ItemModalOpenedParams = ItemModalCommonParams & {
  minItemPrice: number;
  numModifiers?: number;
  numModifiersGroups?: number;
  numPriceVariations?: number;
};

type AddDishToCartParams = ItemModalCommonParams & {
  labelsList: string;
  itemPrice: number;
  numSelectedModifiers: number;
  numModifiersGroups?: number;
  numPriceVariations?: number;
  amountOfItems: number;
  priceVariationName?: string;
  isSuccess: boolean;
  selectedModifiersList?: string;
};

type ClicksOnMenuDropdownAndOptionParams = {
  buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName;
  currentMenuId?: string;
  currentMenuName?: string;
  currentMenuNumber?: number;
  menuId?: string;
  menuName?: string;
  menuNumber?: number;
  numTotalMenus?: number;
  operationId?: string;
};

type ClicksOnMenuBarParams = {
  currentSectionNumber?: number;
  numTotalSections?: number;
  operationId?: string;
  sectionId?: string;
  sectionName?: string;
};

type ClicksInsideDispatchModalParams = {
  dispatchType: DispatchType;
  isPreorder?: boolean;
  buttonType: LiveSiteClicksInsideDispatchModalButtonType;
};

type ErrorDisplayedInDispatchModalParams = {
  dispatchType: DispatchType;
  errorType: ErrorType;
  errorMessage?: string;
};

type DispatchModalClosedParams = {
  dispatchType: DispatchType;
  triggerName: LiveSiteDispatchModalClosedTriggerName;
};

type GenericDebugEventParams = {
  subjectType?: string;
  value?: object;
};

export interface IBIReporterService {
  reportOloLiveSiteOloPageLoadedBiEvent: ({
    isMemberLoggedIn,
    menus,
  }: LiveSiteOloPageLoadedParams) => void;
  reportRestaurantsUouPageStartedLoadingBiEvent: () => void;
  reportRestaurantsUouPageFinishedLoadingBiEvent: () => void;
  reportOloLiveSiteClickOnFulfillmentBiEvent: ({
    origin,
    dispatchType,
    isMemberLoggedIn,
  }: ClickOnFulfillmentParams) => void;
  reportOloLiveSiteDispatchModalOpenedBiEvent: ({
    schedulingType,
    isAsapDisplayed,
    availableDispatchTypes = [],
    isPreorderDisplayed,
    currentFulfillment,
    operationId,
  }: DispatchModalOpenedParams) => void;
  reportOloLiveSiteClickOnItemBiEvent: ({
    itemId,
    itemName = '',
    menuId,
    sectionId,
    minItemPrice,
  }: ClickOnItemParams) => void;
  reportOloLiveSiteItemModalOpenedBiEvent: ({
    itemId,
    itemName,
    menuId,
    sectionId,
    minItemPrice,
    num_labels,
    numModifiers = 0,
    numModifiersGroups = 0,
    numPriceVariations = 0,
  }: ItemModalOpenedParams) => void;
  reportOloLiveSiteAddDishToCartBiEvent: ({
    itemId,
    itemName,
    menuId,
    sectionId,
    num_labels,
    numSelectedModifiers,
    priceVariationName,
    selectedModifiersList,
    amountOfItems,
    itemPrice,
    isSuccess,
    labelsList,
  }: AddDishToCartParams) => void;
  reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent: ({
    buttonName,
    currentMenuId,
    currentMenuName,
    currentMenuNumber,
    operationId = '',
    numTotalMenus,
    menuNumber,
    menuName,
    menuId,
  }: ClicksOnMenuDropdownAndOptionParams) => void;
  reportOloLiveSiteClicksOnMenuBarBiEvent: ({
    currentSectionNumber,
    numTotalSections,
    operationId = '',
    sectionId,
    sectionName,
  }: ClicksOnMenuBarParams) => void;
  reportOloLiveSiteClicksInsideDispatchModalBiEvent: ({
    dispatchType,
    isPreorder,
    buttonType,
  }: ClicksInsideDispatchModalParams) => void;
  reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent: ({
    dispatchType,
    errorType,
    errorMessage = '',
  }: ErrorDisplayedInDispatchModalParams) => void;
  reportOloLiveSiteDispatchModalClosedBiEvent: ({
    dispatchType,
    triggerName,
  }: DispatchModalClosedParams) => void;
  reportOloGenericDebugBiEvent: ({ subjectType, value }: GenericDebugEventParams) => void;
}

export const BIReporterService = ({
  biLogger,
  environment,
  editorSubType,
  resolution,
  widgetInstanceId = '',
}: {
  biLogger?: VisitorLogger | null;
  environment: PlatformControllerFlowAPI['environment'];
  editorSubType?: EditorSubType;
  resolution?: {
    width: number;
    height: number;
  };
  widgetInstanceId?: string;
}): IBIReporterService => {
  const biReporterObject = {
    reportOloLiveSiteOloPageLoadedBiEvent: ({
      isMemberLoggedIn,
      menus,
    }: LiveSiteOloPageLoadedParams) => {
      const { isPreview } = environment;
      const { numOfItems, numOfSections } = getAmountOfSectionsAndItems(menus);
      const isDelivery = state.selectedDispatchType === DispatchType.DELIVERY;

      const eventParams: OloPageLoadedParams = {
        cartState: getAmountOfItemsInCart(),
        defaultSelectedFulfillment: LivesiteFulfillmentType[state.selectedDispatchType],
        defaultSelectedTimeSlot: state.initialTimeSlotText,
        deliveryFee: state[state.selectedDispatchType]?.dispatchCostEstimate,
        isDeliveryAvailable: state.availableDispatchTypes?.includes(DispatchType.DELIVERY),
        isDeliveryConfig: state.configuredDispatchTypes?.includes(DispatchType.DELIVERY),
        isDeafultAddress: !isDelivery
          ? state[DispatchType.PICKUP]?.address?.formattedAddress === DEFAULT_ADDRESS
          : undefined,
        isMemberLoggedIn,
        minOrderDisplayed: state[state.selectedDispatchType]?.minimumOrder?.toString(),
        isPickupAvailable: state.availableDispatchTypes?.includes(DispatchType.PICKUP),
        isPickupConfig: state.configuredDispatchTypes?.includes(DispatchType.PICKUP),
        mode: isPreview ? LivesitePageLoadModeType.PREVIEW : LivesitePageLoadModeType.LIVE_SITE,
        numDishes: numOfItems,
        numMenus: menus?.length,
        numSections: numOfSections,
        operationId: state.operation?.id,
        statusDisplayed:
          state.availableDispatchTypes?.length > 0
            ? LivesitePageLoadStatusDisplayedType.ACCEPTING_ORDERS
            : LivesitePageLoadStatusDisplayedType.CLOSED,
      };
      biLogger?.report(oloLiveSiteOloPageLoaded(eventParams));
    },
    reportRestaurantsUouPageStartedLoadingBiEvent: () => {
      const eventParams: OloPageStartedLoadingParams = {
        hosting: getHostingEnvironment(environment, editorSubType),
        pageName: PageName.ONLINE_ORDERING,
        product: Product.OLO,
        widgetId: PAGE_DATA.widgetId,
        widgetInstanceId,
        widgetName: WidgetName.OLO,
      };
      biLogger?.report(restaurantsUouPageStartedLoading(eventParams));
    },
    reportRestaurantsUouPageFinishedLoadingBiEvent: () => {
      const eventParams: OloPageFinishedLoadingParams = {
        hosting: getHostingEnvironment(environment, editorSubType),
        pageName: PageName.ONLINE_ORDERING,
        product: Product.OLO,
        widgetId: PAGE_DATA.widgetId,
        widgetInstanceId,
        widgetName: WidgetName.OLO,
      };
      biLogger?.report(restaurantsUouPageFinishedLoading(eventParams));
    },
    reportOloLiveSiteClickOnFulfillmentBiEvent: ({
      origin,
      dispatchType,
      isMemberLoggedIn,
    }: ClickOnFulfillmentParams) => {
      const eventParams: OloLiveSiteClickOnFulfillmentParams = {
        cartState: getAmountOfItemsInCart(),
        isMemberLoggedIn,
        operationId: state.operation?.id,
        selectedFulfillment: dispatchType && LivesiteFulfillmentType[dispatchType],
        currentFulfillment: LivesiteFulfillmentType[state.selectedDispatchType],
        origin,
        statusDisplayed:
          state.availableDispatchTypes.length > 0
            ? LivesiteClickFulfillmentStatusDisplayedType.ACCEPTING_ORDERS
            : LivesiteClickFulfillmentStatusDisplayedType.CLOSED,
      };

      biLogger?.report(restaurantsOloLiveSiteClickOnFulfillment(eventParams));
    },
    reportOloLiveSiteDispatchModalOpenedBiEvent: ({
      schedulingType,
      isAsapDisplayed,
      availableDispatchTypes = [],
      isPreorderDisplayed,
      currentFulfillment,
      operationId,
    }: DispatchModalOpenedParams) => {
      const eventParams: OloLiveSiteDispatchModalOpenedParams = {
        operationId,
        schedulingType: LiveSiteDispatchModalOpenedSchedulingType[schedulingType],
        currentFulfillment: LivesiteFulfillmentType[currentFulfillment],
        isAsapDisplayed,
        isPreorderDisplayed,
        isDeliveryDisplayed: availableDispatchTypes.includes(DispatchType.DELIVERY),
        isPickupDisplayed: availableDispatchTypes.includes(DispatchType.PICKUP),
      };

      biLogger?.report(restaurantsOloLiveSiteDispatchModalOpened(eventParams));
    },
    reportOloLiveSiteClickOnItemBiEvent: ({
      itemId,
      itemName = '',
      menuId,
      sectionId,
      minItemPrice,
    }: ClickOnItemParams) => {
      const eventParams: OloLiveSiteClickOnItemParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        minItemPrice,
      };

      biLogger?.report(oloLiveSiteClickOnItem(eventParams));
    },
    reportOloLiveSiteItemModalOpenedBiEvent: ({
      itemId,
      itemName = '',
      menuId = '',
      sectionId = '',
      minItemPrice,
      num_labels,
      numModifiers = 0,
      numModifiersGroups = 0,
      numPriceVariations = 0,
    }: ItemModalOpenedParams) => {
      const eventParams: OloLiveSiteItemModalOpenedParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        minItemPrice,
        num_labels,
        numModifiers,
        numModifiersGroups,
        numPriceVariations,
      };

      biLogger?.report(oloLiveSiteItemModalOpened(eventParams));
    },
    reportOloLiveSiteAddDishToCartBiEvent: ({
      itemId,
      itemName,
      menuId,
      sectionId,
      num_labels,
      numSelectedModifiers,
      priceVariationName,
      selectedModifiersList,
      amountOfItems,
      itemPrice,
      isSuccess,
      labelsList,
    }: AddDishToCartParams) => {
      const eventParams: OloLiveSiteAddDishToCartParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        num_labels,
        numSelectedModifiers,
        priceVariationName,
        selectedModifiersList,
        amountOfItems,
        itemPrice,
        isSuccess,
        labelsList,
      };

      biLogger?.report(oloLiveSiteAddDishToCart(eventParams));
    },
    reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent: ({
      buttonName,
      currentMenuId,
      currentMenuName,
      currentMenuNumber,
      operationId = '',
      numTotalMenus,
      menuNumber,
      menuName,
      menuId,
    }: ClicksOnMenuDropdownAndOptionParams) => {
      const eventParams: OloLiveSiteClicksOnMenuDropdownAndOptionInsideParams = {
        buttonName,
        currentMenuId,
        currentMenuName,
        currentMenuNumber,
        operationId,
        menuNumber,
        menuName,
        menuId,
        numTotalMenus,
      };

      biLogger?.report(
        restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuDropdownAndOtherMenusInside(eventParams)
      );
    },
    reportOloLiveSiteClicksOnMenuBarBiEvent: ({
      currentSectionNumber,
      numTotalSections,
      operationId = '',
      sectionId,
      sectionName,
    }: ClicksOnMenuBarParams) => {
      const eventParams: OloLiveSiteClicksOnMenuBarParams = {
        currentSectionNumber,
        numTotalSections,
        operationId,
        sectionId,
        sectionName,
      };

      biLogger?.report(restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuBar(eventParams));
    },
    reportOloLiveSiteClicksInsideDispatchModalBiEvent: ({
      dispatchType,
      isPreorder,
      buttonType,
    }: ClicksInsideDispatchModalParams) => {
      const eventParams: OloLiveSiteClicksInsideDispatchModalParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        isPreorder,
        buttonType,
      };

      biLogger?.report(restaurantsOloLiveSiteClicksInsideDispatchModal(eventParams));
    },
    reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent: ({
      dispatchType,
      errorType,
      errorMessage = '',
    }: ErrorDisplayedInDispatchModalParams) => {
      const eventParams: OloLiveSiteErrorDisplayedInDispatchModalParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        errorType: getErrorType(errorType),
        errorMessage,
      };

      biLogger?.report(restaurantsOloLiveSiteErrorDisplayedInDispatchModal(eventParams));
    },

    reportOloLiveSiteDispatchModalClosedBiEvent: ({
      dispatchType,
      triggerName,
    }: DispatchModalClosedParams) => {
      const eventParams: OloLiveSiteDispatchModalClosedParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        triggerName,
      };

      biLogger?.report(restaurantsOloLiveSiteDispatchModalClosed(eventParams));
    },
    reportOloGenericDebugBiEvent: ({ subjectType, value }: GenericDebugEventParams) => {
      const data = { ...value, resolution };
      const eventParams: OloGenericDebugParams = {
        subjectType,
        hosting: getHostingEnvironment(environment, editorSubType),
        value: JSON.stringify(data),
      };
      biLogger?.report(oloGenericDebugEvent(eventParams));
    },
  };

  return biReporterObject;
};
