import { HOURS_IN_DAY, MINUTES_IN_HOUR } from './timeUtils';

class TimeRange {
  start: number;
  end: number;

  constructor(props: TimeRangeProps) {
    const { start, end } = props;
    this.start = start;
    this.end = end;
  }

  isValid() {
    return this.end > this.start;
  }

  startsBefore(range: TimeRange) {
    return this.start < range.start;
  }

  isConcurrentWith(range: TimeRange) {
    return this.start === range.start;
  }

  isConterminalWith(range: TimeRange) {
    return this.end === range.end;
  }

  followedBy(range: TimeRange) {
    return this.end === range.start;
  }

  endsBefore(range: TimeRange) {
    return this.end < range.end;
  }

  precedes(range: TimeRange) {
    return this.end < range.start;
  }
}

export class MinutesRange extends TimeRange {
  getDayOfTheWeek() {
    return Math.floor(this.start / (HOURS_IN_DAY * MINUTES_IN_HOUR));
  }
}

export interface TimeRangeProps {
  start: number;
  end: number;
}
