import type {
  Modifier,
  ModifierGroup,
  ModifierGroupTpa,
  ModifierTpa,
  RuleTpa,
  ModifierAdditionalChargeTpa,
  ModifierListItem,
  ModifierGroupRule,
} from '../types/modifiers';
import { ModifierRuleType } from '../types/modifiers';

const DEFAULT_SELECTION_TYPE = 'SINGLE_SELECT';

export const convertRuleToRuleType = ({
  rule = {},
  isModifierGroupsNewRuleExperimentEnabled = false,
}: {
  rule?: RuleTpa;
  isModifierGroupsNewRuleExperimentEnabled: boolean;
}): ModifierGroupRule => {
  return isModifierGroupsNewRuleExperimentEnabled
    ? ({
        mandatory: rule.mandatory,
        minSelections: rule.minSelections,
        maxSelections: rule.maxSelections,
      } as ModifierGroupRule)
    : ({
        singleSelectOptions: rule.singleSelectOptions,
        multiSelectOptions: rule.multiSelectOptions,
        mandatory: rule.mandatory,
        type: ModifierRuleType[rule?.type ?? DEFAULT_SELECTION_TYPE],
      } as ModifierGroupRule);
};
export const convertModifierToModifierType = ({
  modifiers,
}: {
  modifiers: ModifierTpa[];
}): Modifier[] => {
  const updatedModifiers: Modifier[] = [];
  modifiers.forEach((modifier) => {
    if (modifier.id) {
      updatedModifiers.push({
        id: modifier.id,
        name: modifier.name ?? '',
      });
    }
  });

  return updatedModifiers;
};

export const convertModifierGroupToModifierGroupType = ({
  modifierGroups = [],
  isModifierGroupsNewRuleExperimentEnabled = false,
}: {
  modifierGroups: ModifierGroupTpa[];
  isModifierGroupsNewRuleExperimentEnabled: boolean;
}): ModifierGroup[] => {
  const updatedModifierGroups: ModifierGroup[] = [];
  modifierGroups.forEach((modifierGroup) => {
    if (modifierGroup.id) {
      updatedModifierGroups.push({
        id: modifierGroup.id,
        name: modifierGroup.name ?? '',
        modifiers: convertModifierAdditionalChargeToModifier({
          modifiersAdditionalCharge: modifierGroup.modifiers || [],
        }),
        rule: convertRuleToRuleType({
          rule: modifierGroup.rule,
          isModifierGroupsNewRuleExperimentEnabled,
        }),
      });
    }
  });
  return updatedModifierGroups;
};

const convertModifierAdditionalChargeToModifier = ({
  modifiersAdditionalCharge = [],
}: {
  modifiersAdditionalCharge: ModifierAdditionalChargeTpa[];
}): ModifierListItem[] => {
  return modifiersAdditionalCharge?.map((modifier) => {
    return {
      id: modifier.id,
      price: modifier.additionalCharge ?? '',
    };
  });
};
