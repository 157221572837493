import { getMinutesPassedSinceStartOfWeek, MINUTES_IN_WEEK } from '../../../logic/timeUtils';
import type { PreorderScheduling } from '../../../types/businessTypes';
import type { DateTime } from 'luxon';

export function getFirstAvailableMinuteOfTheWeek({
  minDate,
  timezone,
  chosenDate,
  preorderOptions,
}: {
  minDate?: DateTime;
  timezone: string;
  chosenDate: DateTime;
  preorderOptions?: PreorderScheduling;
}) {
  const isTheMinDateOption = minDate
    ?.setZone(timezone, { keepLocalTime: true, keepCalendarTime: true })
    .startOf('day')
    .equals(chosenDate.startOf('day'));

  let firstAvailableMinuteOfTheWeek;

  if (isTheMinDateOption) {
    const minutesPassed = getMinutesPassedSinceStartOfWeek(timezone);
    firstAvailableMinuteOfTheWeek =
      (minutesPassed + (preorderOptions?.timeInAdvance?.min || 0)) % MINUTES_IN_WEEK;
  } else {
    firstAvailableMinuteOfTheWeek = getMinutesPassedSinceStartOfWeek(timezone, chosenDate);
  }
  return firstAvailableMinuteOfTheWeek;
}
