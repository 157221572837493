import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  listModifierGroups,
  getModifierGroup,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier-group/http';

export class ModifierGroupsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchAllModifierGroups = async (modifierGroupIds?: string[]) => {
    try {
      return this.httpClient
        .request(listModifierGroups({ modifierGroupIds }))
        .then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getModifierGroupById = async (modifierGroupId: string) => {
    try {
      return this.httpClient
        .request(getModifierGroup({ modifierGroupId }))
        .then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
