import { makeAutoObservable } from 'mobx';
import type {
  DeliveryFulfillmentOption,
  PickupFulfillmentOption,
} from '../contexts/FulfillmentContext';
import type {
  Address,
  BusinessOpenStatusOptions,
  DispatchTime,
  MinMaxRange,
  Operation,
  WeeklyAvailability,
} from '../types/businessTypes';
import { BusinessOpenStatus, DispatchType, SameDayScheduling } from '../types/businessTypes';
import { DEFAULT_DISPATCH_STATES, state as rootState } from './RootState';
import {
  canSubmitOrderNow,
  getToday,
  getWeeklyAvailabilityForFulfillmentOptionsMemo,
  isDateAvailable,
  isTimeSlotAvailable,
  resolveASAPTime,
  resolveFastestDispatch,
} from '../logic/timeUtils';
import type {
  AvailableDispatchesInfo,
  StartAndEndTime,
} from '../api/utils/FulfillmentOptionsProcessor';
import {
  getMinOrder,
  getDeliveryFee,
  getFreeFulfillmentPriceThreshold,
} from '../utils/dispatchModalUtils';
import type { DateTime } from 'luxon';

const DEFAULT_DISPATCH_TYPE = DispatchType.PICKUP;

export interface DispatchState {
  address?: Address;
  selectedSameDayScheduling?: SameDayScheduling;
  minimumOrder?: number;
  dispatchTime: DispatchTime;
  dispatchCostEstimate?: number;
  freeFulfillmentPriceThreshold?: number;
  startTime?: DateTime;
  endTime?: DateTime;
  prepTime: MinMaxRange;
  preOrderTimeWindows?: DispatchTime[];
}

export interface IHeaderState {
  openStatus: BusinessOpenStatusOptions;
}

export class HeaderState implements IHeaderState {
  openStatus = DEFAULT_HEADER_STATE.openStatus;

  constructor(props: Partial<IHeaderState>) {
    // @ts-expect-error
    Object.keys(props).forEach((key) => (this[key] = props[key]));
    makeAutoObservable(this);
  }

  setDispatchTime(time: DispatchTime) {
    this.selectedDispatchState.dispatchTime = time;
  }

  get selectedDispatchState() {
    return rootState[rootState.selectedDispatchType];
  }

  get selectedDispatchType() {
    return rootState.selectedDispatchType;
  }

  get dispatchTime() {
    return this.selectedDispatchState?.dispatchTime;
  }

  get minimumOrder() {
    return this.selectedDispatchState?.minimumOrder;
  }

  get selectedSameDayScheduling() {
    return this.selectedDispatchState?.selectedSameDayScheduling;
  }

  get address() {
    return this.selectedDispatchState?.address;
  }

  get dispatchCostEstimate() {
    return this.selectedDispatchState?.dispatchCostEstimate;
  }

  get freeFulfillmentPriceThreshold() {
    return this.selectedDispatchState?.freeFulfillmentPriceThreshold;
  }

  get isPickup() {
    return rootState.selectedDispatchType === DispatchType.PICKUP;
  }

  get isDelivery() {
    return rootState.selectedDispatchType === DispatchType.DELIVERY;
  }
}

export const DEFAULT_HEADER_STATE: IHeaderState = {
  openStatus: BusinessOpenStatus.Open,
};

function getSelectedDispatchType(availableDispatches: DispatchType[]) {
  return availableDispatches.length > 1 ? DEFAULT_DISPATCH_TYPE : availableDispatches[0];
}
function getOpenStatus(availableDispatches?: DispatchType[], hasDispatchTime?: boolean) {
  return availableDispatches && availableDispatches.length > 0 && hasDispatchTime
    ? BusinessOpenStatus.Open
    : BusinessOpenStatus.Closed;
}

const checkDispatchTypeValidity = (dispatchType: DispatchType) => {
  return rootState.availableDispatchTypes.includes(dispatchType);
};

const checkTimeSlotValidity = (
  dispatchTime: DispatchTime | undefined,
  weeklyAvailability: WeeklyAvailability,
  operation: Operation,
  startAndEndTime: StartAndEndTime | undefined,
  timezone: string
) => {
  if (!dispatchTime) {
    return operation.operationType === 'ASAP' && operation.asapOptions;
  }
  const day =
    operation.operationType === 'PRE_ORDER' && dispatchTime.date
      ? dispatchTime.date.weekday % 7
      : getToday(timezone);

  return Number.isInteger(day)
    ? isTimeSlotAvailable({
        timeSlot: { ...dispatchTime },
        day: day as number,
        weeklyAvailability,
      })
    : undefined;
};

const getSelectedDispatchDetails = (
  weeklyPickupAvailability: WeeklyAvailability,
  operation: Operation,
  startAndEndTime: StartAndEndTime | undefined,
  timezone: string
) => {
  const selectedDispatchDetails =
    rootState.PersistDataService?.getSelectedDispatchDetails(timezone);
  if (!selectedDispatchDetails) {
    return undefined;
  }
  const { dispatchTime } = selectedDispatchDetails;
  const isValidDispatch = checkDispatchTypeValidity(selectedDispatchDetails.dispatchType);
  const isValidDate =
    !dispatchTime ||
    isDateAvailable({
      dispatchTime,
      startAndEndTime,
    });
  const isValidTimeSlot = checkTimeSlotValidity(
    dispatchTime,
    weeklyPickupAvailability,
    operation,
    startAndEndTime,
    timezone
  );
  const isValid = isValidDispatch && isValidDate && !!isValidTimeSlot;

  return isValid ? selectedDispatchDetails : undefined;
};
export function getInitialHeaderState({
  operation,
  availableDispatches,
  timezone,
  saveDispatchDetails,
}: {
  timezone: string;
  operation: Operation;
  availableDispatches: AvailableDispatchesInfo;
  saveDispatchDetails?: boolean;
}): Partial<IHeaderState> {
  const pickupDispatches = availableDispatches[DispatchType.PICKUP];
  const [pickupFulfillment] = pickupDispatches.fulfillments;

  const deliveryDispatches = availableDispatches[DispatchType.DELIVERY];

  const deliveryFulfillments = deliveryDispatches.fulfillments;

  const weeklyOptions = pickupFulfillment ? [pickupFulfillment] : deliveryFulfillments;
  const { startAndEndTime } = pickupFulfillment ? pickupDispatches : deliveryDispatches;
  const weeklyPickupAvailability = getWeeklyAvailabilityForFulfillmentOptionsMemo(weeklyOptions);
  const selectedDispatchDetails = saveDispatchDetails
    ? getSelectedDispatchDetails(weeklyPickupAvailability, operation, startAndEndTime, timezone)
    : undefined;

  const selectedSameDayScheduling = selectedDispatchDetails
    ? selectedDispatchDetails.dispatchTime
      ? SameDayScheduling.SAME_DAY
      : SameDayScheduling.ASAP
    : undefined;

  rootState.selectedDispatchType =
    selectedDispatchDetails?.dispatchType ??
    getSelectedDispatchType(rootState.availableDispatchTypes);

  const initialPickupState: Partial<DispatchState> = pickupFulfillment
    ? (getInitialPickupState({
        prepTime: availableDispatches[DispatchType.PICKUP].prepTime,
        timezone,
        weeklyPickupAvailability,
        pickup: pickupFulfillment,
        operation,
        availableDispatches,
        overrides:
          selectedDispatchDetails?.dispatchType === DispatchType.PICKUP
            ? { ...selectedDispatchDetails, selectedSameDayScheduling }
            : undefined,
      }) as DispatchState)
    : (DEFAULT_DISPATCH_STATES[DispatchType.PICKUP] as DispatchState);
  const initialDeliveryState = getDeliveryInitialDispatchState({
    deliveryFulfillments,
    weeklyAvailability: getWeeklyAvailabilityForFulfillmentOptionsMemo(deliveryFulfillments),
    operation,
    timezone,
    prepTime: availableDispatches[DispatchType.DELIVERY].prepTime,
    availableDispatches,
    overrides:
      selectedDispatchDetails?.dispatchType === DispatchType.DELIVERY
        ? { ...selectedDispatchDetails, selectedSameDayScheduling }
        : undefined,
  });

  rootState[DispatchType.PICKUP] = {
    ...initialPickupState,
    prepTime: initialPickupState.prepTime!,
    dispatchTime: initialPickupState.dispatchTime!,
  };
  rootState[DispatchType.DELIVERY] = initialDeliveryState as DispatchState;
  rootState.initialDispatchState = {
    [DispatchType.PICKUP]: initialPickupState as DispatchState,
    [DispatchType.DELIVERY]: initialDeliveryState as DispatchState,
  };
  const isPreorder = operation.operationType === 'PRE_ORDER';
  const hasDispatchTime =
    !isPreorder ||
    !!(initialDeliveryState.dispatchTime.date || initialPickupState.dispatchTime?.date);
  const openStatus = getOpenStatus(rootState.availableDispatchTypes, hasDispatchTime);

  rootState.canAcceptOrders = openStatus === BusinessOpenStatus.Open;
  rootState.isAddressSelected = !(
    rootState.selectedDispatchType === DispatchType.DELIVERY && !initialDeliveryState.address
  );

  return {
    openStatus,
  };
}

function getInitialPickupState({
  timezone,
  pickup,
  weeklyPickupAvailability,
  operation,
  prepTime,
  availableDispatches,
  overrides,
}: {
  timezone: string;
  weeklyPickupAvailability: WeeklyAvailability;
  pickup: PickupFulfillmentOption;
  operation: Operation;
  prepTime: MinMaxRange;
  availableDispatches: AvailableDispatchesInfo;
  overrides: Partial<DispatchState> | undefined;
}): Partial<DispatchState> {
  return {
    ...DEFAULT_DISPATCH_STATES[DispatchType.PICKUP],
    prepTime,
    selectedSameDayScheduling: pickup.canSubmitOrderForNow
      ? SameDayScheduling.ASAP
      : SameDayScheduling.SAME_DAY,
    minimumOrder: pickup.minOrderPrice ? Number(pickup.minOrderPrice) : undefined,
    address: pickup.address,
    startTime: availableDispatches[DispatchType.PICKUP].startAndEndTime?.start,
    endTime: availableDispatches[DispatchType.PICKUP].startAndEndTime?.end,
    ...JSON.parse(JSON.stringify(overrides ?? {})),
    preOrderTimeWindows:
      (overrides?.preOrderTimeWindows ?? rootState.preorderTimeWindows?.PICKUP) || [],
    dispatchTime:
      overrides?.dispatchTime ??
      (pickup.canSubmitOrderForNow
        ? resolveASAPTime(timezone, prepTime)
        : resolveFastestDispatch(weeklyPickupAvailability, operation, timezone, prepTime)),
  };
}

export function getDeliveryInitialDispatchState({
  deliveryFulfillments,
  timezone,
  prepTime,
  weeklyAvailability,
  operation,
  availableDispatches,
  overrides,
}: {
  deliveryFulfillments: DeliveryFulfillmentOption[] | undefined;
  timezone: string;
  weeklyAvailability: WeeklyAvailability;
  prepTime: MinMaxRange;
  operation: Partial<Operation>;
  availableDispatches: AvailableDispatchesInfo;
  overrides?: Partial<DispatchState>;
}): DispatchState {
  if (!deliveryFulfillments || deliveryFulfillments.length < 1) {
    return {
      ...DEFAULT_DISPATCH_STATES[DispatchType.DELIVERY],
      selectedSameDayScheduling: SameDayScheduling.SAME_DAY,
      prepTime,
    };
  }

  return {
    ...DEFAULT_DISPATCH_STATES[DispatchType.DELIVERY],
    prepTime,
    selectedSameDayScheduling: canSubmitOrderNow(
      weeklyAvailability,
      timezone,
      operation.operationType
    )
      ? SameDayScheduling.ASAP
      : SameDayScheduling.SAME_DAY,
    minimumOrder: getMinOrder(deliveryFulfillments),
    dispatchCostEstimate: getDeliveryFee(deliveryFulfillments),
    freeFulfillmentPriceThreshold: getFreeFulfillmentPriceThreshold(deliveryFulfillments),
    startTime: availableDispatches[DispatchType.DELIVERY].startAndEndTime?.start,
    endTime: availableDispatches[DispatchType.DELIVERY].startAndEndTime?.end,
    ...JSON.parse(JSON.stringify(overrides ?? {})),
    preOrderTimeWindows:
      (overrides?.preOrderTimeWindows ?? rootState.preorderTimeWindows?.DELIVERY) || [],
    dispatchTime:
      overrides?.dispatchTime ??
      (canSubmitOrderNow(weeklyAvailability, timezone, operation.operationType)
        ? resolveASAPTime(timezone, prepTime)
        : resolveFastestDispatch(weeklyAvailability, operation, timezone, prepTime)),
  };
}
