import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LabelsClient } from './labelsClient';
import { ItemsClient } from './itemsClient';
import { MenusClient } from './menusClient';
import { SectionsClient } from './sectionsClient';
import type { PopulatedMenu } from '../types/menusTypes';
import { convertDataTypes, populateMenu } from './utils/utils';
import { state } from 'root/states/RootState';

export const PopulatedMenuClient = (flowAPI: PlatformControllerFlowAPI, currency: string) => {
  const menusClient = new MenusClient(flowAPI.httpClient);
  const sectionsClient = new SectionsClient(flowAPI.httpClient);
  const itemsClient = new ItemsClient(flowAPI.httpClient);
  const labelsClient = new LabelsClient(flowAPI.httpClient);

  return {
    getAll: async () => {
      try {
        const [menuResponse, sectionResponse, itemResponse, labelResponse] = await Promise.all([
          menusClient.fetchListVisibleMenus(),
          sectionsClient.fetchAllSections(),
          itemsClient.fetchAllItems(),
          labelsClient.fetchAllLabels(),
        ]);
        const itemsWithoutImgSize =
          itemResponse.items?.filter(
            (item) => item.image && item.image.id && !(item.image.width || item.image.height)
          ) || [];

        const fileIds = itemsWithoutImgSize.map((item) => item.image!.id!);
        const { metaSiteId = '' } = flowAPI.controllerConfig.platformAPIs.bi || {};
        let imagesData;
        if (fileIds.length > 0) {
          state.biReporterService?.reportOloGenericDebugBiEvent({
            subjectType: 'fix width and height of images',
            value: {
              fileIds: JSON.stringify(fileIds),
              fileIdsLength: fileIds.length,
              msid: metaSiteId,
            },
          });
          imagesData = await itemsClient.getImgsData(fileIds, metaSiteId);
        }

        const { menus, sections, items } = await convertDataTypes({
          currency,
          menusTpa: menuResponse.menus,
          sectionsTpa: sectionResponse.sections,
          itemsTpa: itemResponse.items,
          labelsTpa: labelResponse.labels,
          imagesData,
        });

        return menus.map((menu) => populateMenu(menu, sections, items)) as PopulatedMenu[];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('fetch data failed', e);
        state.pubsub.publish('onMenusDataFetchFailed', { shouldShowErrorContent: true });
      }
    },
  };
};
