import type { DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import { MinutesRange } from './MinutesRange';
import type {
  AsapScheduling,
  DAY_OF_THE_WEEK,
  DispatchTime,
  MinMaxRange,
  Minute,
  Operation,
  OperationType,
  PreorderScheduling,
  WeeklyAvailability,
} from '../types/businessTypes';
import type { FulfillmentOption } from '../contexts/FulfillmentContext';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { EMPTY_DISPATCH_TIME } from '../api/consts';
import type { StartAndEndTime } from '../api/utils/FulfillmentOptionsProcessor';

export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
export const MINUTES_IN_WEEK = MINUTES_IN_HOUR * HOURS_IN_DAY * 7;

export function mergeOverlappingMinutesRanges(range: MinutesRange[]) {
  const sortedByStart = [...range].sort((a, b) => (a.start > b.start ? 1 : -1));
  const result: MinutesRange[] = [];
  let currentDate = sortedByStart.shift();
  if (!currentDate) {
    return result;
  }
  while (sortedByStart.length > -1) {
    const nextDate = sortedByStart.shift();
    if (!nextDate) {
      result.push(currentDate);
      break;
    }
    if (currentDate.end < nextDate.start) {
      result.push(currentDate);
      currentDate = nextDate;
    } else {
      currentDate.end = currentDate.end > nextDate.end ? currentDate.end : nextDate.end;
    }
  }
  return result;
}

export const convertDateRangeToMinutesRange = (date: { start: Date; end: Date }): MinutesRange => {
  return new MinutesRange({
    start: date.start.getTime(),
    end: date.end.getTime(),
  });
};

export function getDropdownOptionsFromDates({
  minutes,
  locale,
  t,
  timezone,
}: {
  minutes: DispatchTime[];
  locale: string;
  t: TFunction;
  timezone: string;
}) {
  const localeForIntl = locale.split('-')[0];
  return minutes.map((minute) => {
    return {
      value: getOptionIdFromDispatchTime(minute),
      label: convertMinutesToDisplayableTime({
        minute,
        timezone,
        locale: localeForIntl,
        t,
      }),
    };
  });
}

export function isTomorrow(date: DateTime, timezone: string) {
  const tomorrow = DateTime.local({ zone: timezone }).startOf('day').plus({ days: 1 });
  return date.startOf('day').valueOf() === tomorrow.valueOf();
}

export function isToday(date: DateTime, timezone: string) {
  const today = DateTime.local({ zone: timezone }).startOf('day');
  return date.startOf('day').valueOf() === today.valueOf();
}

export function convertMinutesToDisplayableTime({
  minute,
  locale,
  t,
  timezone,
  justMinutes = true,
}: {
  minute: DispatchTime;
  timezone: string;
  locale: string;
  justMinutes?: boolean;
  t: TFunction;
}) {
  const date = getTimeRangeFromDispatchTime(minute, timezone);
  const fullDateOptions: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const from = date.from.setLocale(locale);
  const until = date.until.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE);

  const timingType = date.from.valueOf() === date.until.valueOf() ? 'exact' : 'range';
  const getRangeString = ({ from, until }: { from: string; until: string }) =>
    t('menu_olo.dispatch.time.simpleRange', { from, until });
  switch (timingType) {
    case 'range': {
      if (isToday(from, timezone)) {
        return !justMinutes
          ? t('menu_olo.dispatch.time.today', {
              time: getRangeString({
                from: from.toLocaleString(DateTime.TIME_SIMPLE),
                until,
              }),
            })
          : getRangeString({
              from: from.toLocaleString(DateTime.TIME_SIMPLE),
              until,
            });
      }
      if (isTomorrow(from, timezone)) {
        return !justMinutes
          ? t('menu_olo.dispatch.time.tomorrow', {
              time: getRangeString({
                from: from.toLocaleString(DateTime.TIME_SIMPLE),
                until,
              }),
            })
          : getRangeString({
              from: from.toLocaleString(DateTime.TIME_SIMPLE),
              until,
            });
      }
      return justMinutes
        ? getRangeString({
            from: from.toLocaleString(DateTime.TIME_SIMPLE),
            until,
          })
        : getRangeString({
            from: from.toLocaleString(fullDateOptions),
            until,
          });
    }
    case 'exact':
    default: {
      if (isToday(date.from, timezone)) {
        return justMinutes
          ? from.toLocaleString(DateTime.TIME_SIMPLE)
          : t('menu_olo.dispatch.time.today', {
              time: from.toLocaleString(DateTime.TIME_SIMPLE),
            });
      }
      if (isTomorrow(from, timezone)) {
        return !justMinutes
          ? t('menu_olo.dispatch.time.tomorrow', {
              time: from.toLocaleString(DateTime.TIME_SIMPLE),
            })
          : from.toLocaleString(DateTime.TIME_SIMPLE);
      }
      return from.toLocaleString(fullDateOptions);
    }
  }
}

export function getDispatchTimeFromTimeRange(
  from: number,
  until: number,
  timezone?: string
): DispatchTime {
  const fromDate = DateTime.fromMillis(from, { zone: timezone });
  const untilDate = DateTime.fromMillis(until, { zone: timezone });
  const minutesInDay = MINUTES_IN_HOUR * HOURS_IN_DAY;
  const startOfWeek = fromDate.startOf('week').minus({ minutes: minutesInDay });
  return {
    from: Math.floor(fromDate.diff(startOfWeek).as('minutes')),
    until: Math.floor(untilDate.diff(startOfWeek).as('minutes')),
    date: getToday(timezone) !== getDayOfTheWeek(fromDate) ? fromDate.startOf('day') : undefined,
  };
}

export function getTimeRangeFromDispatchTime(
  dispatchTime: DispatchTime,
  timezone: string
): {
  from: DateTime;
  until: DateTime;
} {
  const date =
    dispatchTime.date?.startOf('day') || DateTime.local({ zone: timezone }).startOf('day');
  return {
    from: shiftDateByMinutesOfTheWeek(date, dispatchTime.from),
    until: shiftDateByMinutesOfTheWeek(date, dispatchTime.until),
  };
}

export function shiftDateByMinutesOfTheWeek(date: DateTime, minutes: number) {
  const startOfWeek = date.minus({ days: date.weekday % 7 });
  let newDate = startOfWeek.plus({ minutes });
  // if there's a DST shift(switching to winter/summer time) in between
  // isInDST is true if it is a summer time, and false if it is a winter time
  if (newDate.isInDST && !startOfWeek.isInDST) {
    newDate = newDate.minus({ minutes: 60 });
  } else if (startOfWeek.isInDST && !newDate.isInDST) {
    newDate = newDate.plus({ minutes: 60 });
  }

  return newDate;
}

export function distributeAvailableMinutesByDay(minutesOfTheWeek: MinutesRange[]) {
  const week: WeeklyAvailability = Array(7)
    .fill({})
    .map((row) => []);
  minutesOfTheWeek.forEach((minute) => week[minute.getDayOfTheWeek()].push(minute));
  return week;
}

export function unionAvailableTimesForDays(week: WeeklyAvailability): WeeklyAvailability {
  return week.map((day) => mergeOverlappingMinutesRanges(day));
}

export const getWeeklyAvailabilityForFulfillmentOptionsMemo =
  getWeeklyAvailabilityForFulfillmentOptions;

function getWeeklyAvailabilityForFulfillmentOptions(
  options: FulfillmentOption[]
): WeeklyAvailability {
  return unionAvailableTimesForDays(
    distributeAvailableMinutesByDay(options.flatMap((option) => option.minutesOfTheWeek))
  );
}

export function getToday(timezone?: string): DAY_OF_THE_WEEK {
  return (DateTime.local({ zone: timezone }).weekday % 7) as DAY_OF_THE_WEEK;
}

export function getTimeSlotsForDay({
  day,
  weeklyAvailability,
}: {
  day: number;
  weeklyAvailability: WeeklyAvailability;
}): MinutesRange[] {
  return weeklyAvailability[day];
}

export function isTimeSlotAvailable({
  timeSlot,
  day,
  weeklyAvailability,
}: {
  timeSlot: { from: number; until: number };
  day: number;
  weeklyAvailability: WeeklyAvailability;
}): boolean {
  const timeSlots = getTimeSlotsForDay({ day, weeklyAvailability });
  return timeSlots.some((ts) => ts.start <= timeSlot.from && ts.end >= timeSlot.until);
}

export function isDateAvailable({
  dispatchTime,
  startAndEndTime,
}: {
  dispatchTime: DispatchTime;
  startAndEndTime?: StartAndEndTime;
}) {
  const startOfWeek = dispatchTime.date?.minus({ days: dispatchTime.date.weekday % 7 });
  const fromSelected = startOfWeek?.plus({ minutes: dispatchTime.from });
  const untilSelected = startOfWeek?.plus({ minutes: dispatchTime.until });

  const { start: fromAvailable, end: untilAvailable } = startAndEndTime ?? {};

  if (
    fromAvailable &&
    untilAvailable &&
    fromSelected &&
    untilSelected &&
    (fromAvailable > fromSelected || untilAvailable < untilSelected)
  ) {
    return false;
  }

  return true;
}

export function getDispatchTimeForToday(
  weeklyAvailability: WeeklyAvailability,
  timezone: string
): DispatchTime {
  const minutes = getTimeSlotsForToday(weeklyAvailability, timezone).sort(
    (a, b) => b.start - a.start
  )[0];
  return { from: minutes.start, until: minutes.start };
}

export function getTimeSlotsForToday(
  weeklyAvailability: WeeklyAvailability,
  timezone: string
): MinutesRange[] {
  return getTimeSlotsForDay({
    day: getToday(timezone),
    weeklyAvailability,
  });
}

export function roundTo5(num: number) {
  return Math.ceil(num / 5) * 5;
}

export function generateTimesRange({
  startMinute,
  endMinute,
  increment = 15,
  timeWindow = 0,
}: {
  startMinute: number;
  endMinute: number;
  increment?: number;
  timeWindow?: number;
}): DispatchTime[] {
  const DEFAULT_INCREMENT = 15;
  let currentMinute: DispatchTime = {
    from: roundTo5(startMinute),
    until: roundTo5(startMinute) + timeWindow,
  }; // Start with the initial date
  const dates: DispatchTime[] = [];
  while (currentMinute.until <= endMinute) {
    dates.push(currentMinute);
    currentMinute = {
      from: roundTo5(currentMinute.from + (increment || DEFAULT_INCREMENT)),
      until: roundTo5(currentMinute.until + (increment || DEFAULT_INCREMENT)),
    };
  }

  if (currentMinute.from < endMinute && currentMinute.until > endMinute) {
    currentMinute.until = endMinute;
    dates.push(currentMinute);
  }

  return dates;
}

export interface GetDropdownTimeOptionsParams {
  weeklyAvailability: WeeklyAvailability;
  locale: string;
  timezone: string;
  day: DAY_OF_THE_WEEK;
  prepTime: number;
  range?: number;
  t: TFunction;
  firstAvailableMinuteOfTheWeek?: number;
  isAsap?: boolean;
}

export function getDropdownTimeOptions({
  weeklyAvailability,
  locale,
  timezone,
  day,
  prepTime,
  range,
  t,
  firstAvailableMinuteOfTheWeek,
  isAsap,
}: GetDropdownTimeOptionsParams): { label: string; value: string }[] {
  const parsedTimeRanges = getTimeRangesForDay(weeklyAvailability, day, range);
  return getDropdownOptionsFromDates({
    timezone,
    t,
    minutes: filterOutPassedTimes({
      minutes: parsedTimeRanges,
      timezone,
      prepTime,
      firstAvailableMinuteOfTheWeek,
      isAsap,
    }),
    locale,
  });
}

export function getTimeRangesForDay(
  weeklyAvailability: WeeklyAvailability,
  day: number,
  timeWindow?: number
) {
  const timeRanges = getTimeSlotsForDay({ weeklyAvailability, day }) || [];
  const parsedTimeRanges: DispatchTime[] = timeRanges.flatMap((timeRange) =>
    generateTimesRange({
      startMinute: timeRange.start,
      endMinute: timeRange.end,
      increment: timeWindow,
      timeWindow,
    })
  );
  return parsedTimeRanges;
}

function isBetween({ start, end, time }: { start: number; end: number; time: number }) {
  return time >= start && time < end;
}

export function getAllPossibleFulfillments(time: number, fulfillments: FulfillmentOption[]) {
  return fulfillments.filter((fulfillment) =>
    fulfillment.minutesOfTheWeek.find((minutes) =>
      isBetween({ time, start: minutes.start, end: minutes.end })
    )
  );
}

export function getMinutesPassedSinceStartOfWeek(timezone: string, date?: DateTime) {
  const referenceDate =
    !date || isToday(date, timezone) ? DateTime.local({ zone: timezone }) : date.startOf('day');
  const startOfDay = referenceDate.startOf('day');
  const minutesPassedToday = Math.floor(referenceDate.diff(startOfDay).as('minutes'));
  const MINUTES_IN_A_DAY = 24 * 60;
  const dayOfWeek = referenceDate.weekday % 7;
  return minutesPassedToday + dayOfWeek * MINUTES_IN_A_DAY;
}

export function filterOutPassedTimes({
  prepTime = 0,
  timezone,
  minutes,
  firstAvailableMinuteOfTheWeek,
  isAsap,
}: {
  minutes: DispatchTime[];
  timezone: string;
  prepTime?: Minute;
  firstAvailableMinuteOfTheWeek?: Minute;
  isAsap?: boolean;
}) {
  const minutesPassed =
    firstAvailableMinuteOfTheWeek !== undefined
      ? firstAvailableMinuteOfTheWeek + (isAsap ? prepTime : 0)
      : getMinutesPassedSinceStartOfWeek(timezone) + prepTime;
  let timeRanges = minutes.filter((minute) => minute.from >= minutesPassed);

  const today = Math.floor(minutesPassed / (MINUTES_IN_HOUR * HOURS_IN_DAY));
  const dayFromMinutesArray = Math.floor(minutes[0]?.from / (MINUTES_IN_HOUR * HOURS_IN_DAY));

  if (timeRanges.length === 0 && today !== dayFromMinutesArray) {
    timeRanges = minutes;
  }
  return timeRanges;
}

const OPTION_SEPARATOR = '=';

export function convertOptionToDispatchTime(option: string): DispatchTime {
  const [from, until] = option.split(OPTION_SEPARATOR);
  return { from: Number(from), until: Number(until) };
}

export function getOptionIdFromDispatchTime(dispatchTime?: DispatchTime): string {
  return dispatchTime ? `${dispatchTime.from}${OPTION_SEPARATOR}${dispatchTime.until}` : '';
}

export function getPrepTime(asapOptions: AsapScheduling) {
  return {
    min: asapOptions.maxInMinutes || asapOptions.rangeInMinutes?.min || 0,
    max: asapOptions.maxInMinutes || asapOptions.rangeInMinutes?.max || 0,
  };
}

export function resolveFastestDispatch(
  weeklyAvailability: WeeklyAvailability,
  operation: Partial<Operation>,
  timezone: string,
  prepTime: MinMaxRange
): DispatchTime {
  const { operationType, preOrderOptions } = operation;
  return operationType === 'ASAP'
    ? getFastestTimeAsap(weeklyAvailability, prepTime, timezone)
    : preOrderOptions
    ? getFastestPreOrderTime(weeklyAvailability, preOrderOptions, timezone)
    : EMPTY_DISPATCH_TIME;
}

export function getFastestTimeAsap(
  weeklyAvailability: WeeklyAvailability,
  prepTime: MinMaxRange,
  timezone: string
) {
  const allTimesForToday: DispatchTime[] = filterOutPassedTimes({
    minutes: getTimeRangesForDay(weeklyAvailability, getToday(timezone)),
    timezone,
    prepTime: prepTime.max,
    isAsap: true,
  });
  const sorted = allTimesForToday.sort((a, b) => a.from - b.from);

  const fastest: { from: number; until: number } =
    sorted[0] && sorted[0].from > getMinutesPassedSinceStartOfWeek(timezone)
      ? { from: roundTo5(sorted[0].from), until: roundTo5(sorted[0].from) }
      : resolveASAPTime(timezone, prepTime);

  return fastest;
}

export function getFirstAvailableDayAndTimeRange(
  weeklyAvailability: WeeklyAvailability,
  preorderOptions: PreorderScheduling,
  firstAvailableMinuteInTheWeek: number,
  today: number
) {
  const firstAvailableDayThisWeek = weeklyAvailability.findIndex((day) =>
    day.find((min) => min.end > firstAvailableMinuteInTheWeek)
  );

  const chosenDay =
    firstAvailableDayThisWeek > -1
      ? firstAvailableDayThisWeek
      : weeklyAvailability.findIndex((day) =>
          day.find((min) => min.end + MINUTES_IN_WEEK > firstAvailableMinuteInTheWeek)
        );
  const shouldAddOneMoreWeek = firstAvailableDayThisWeek < 0 && chosenDay >= today;

  const firstAvailableTimeRange = getFirstAvailableTimeRange({
    weeklyAvailability,
    chosenDay,
    preorderOptions,
    firstAvailableMinuteInTheWeek,
  });
  return { firstAvailableTimeRange, shouldAddOneMoreWeek, chosenDay };
}

export function getNextAvailableDayAndTimeRange({
  weeklyAvailability,
  preorderOptions,
  firstAvailableMinuteInTheWeek,
  weeksToAdd,
  lastAvailableMinute,
  today,
}: {
  weeklyAvailability: WeeklyAvailability;
  preorderOptions: PreorderScheduling;
  firstAvailableMinuteInTheWeek: number;
  weeksToAdd: number;
  lastAvailableMinute: number;
  today: number;
}) {
  const firstAvailableDayThisWeek = weeklyAvailability.findIndex((day) =>
    day.find(
      (min) =>
        min.end > firstAvailableMinuteInTheWeek + MINUTES_IN_DAY &&
        min.start + MINUTES_IN_WEEK * weeksToAdd < lastAvailableMinute
    )
  );

  const chosenDay =
    firstAvailableDayThisWeek > -1
      ? firstAvailableDayThisWeek
      : weeklyAvailability.findIndex((day) =>
          day.find(
            (min) =>
              min.end + MINUTES_IN_WEEK > firstAvailableMinuteInTheWeek + MINUTES_IN_DAY &&
              min.start + MINUTES_IN_WEEK * (weeksToAdd + 1) < lastAvailableMinute
          )
        );

  const shouldAddOneMoreWeek = firstAvailableDayThisWeek < 0 && chosenDay >= today;

  const firstAvailableTimeRange = getFirstAvailableTimeRange({
    weeklyAvailability,
    chosenDay,
    preorderOptions,
    firstAvailableMinuteInTheWeek,
  });
  return { firstAvailableTimeRange, shouldAddOneMoreWeek, chosenDay };
}

export function getFirstAvailableTimeRange({
  weeklyAvailability,
  chosenDay,
  preorderOptions,
  firstAvailableMinuteInTheWeek,
}: {
  weeklyAvailability: WeeklyAvailability;
  preorderOptions: PreorderScheduling;
  chosenDay: number;
  firstAvailableMinuteInTheWeek: number;
}) {
  const timeRanges = getTimeRangesForDay(
    weeklyAvailability,
    chosenDay,
    preorderOptions.timeWindowDuration
  );
  let firstAvailableTimeRange = timeRanges.find(
    (time) => time.from >= firstAvailableMinuteInTheWeek
  );

  const timeRangesDay = Math.floor(timeRanges[0]?.from / (MINUTES_IN_HOUR * HOURS_IN_DAY));
  const firstAvailableDay = Math.floor(
    firstAvailableMinuteInTheWeek / (MINUTES_IN_HOUR * HOURS_IN_DAY)
  );

  if (!firstAvailableTimeRange && timeRangesDay !== firstAvailableDay) {
    firstAvailableTimeRange = timeRanges[0];
  }
  return firstAvailableTimeRange;
}

export function getFastestPreOrderTime(
  weeklyAvailability: WeeklyAvailability,
  preorderOptions: PreorderScheduling,
  timezone: string
): DispatchTime {
  const minutesPassed = getMinutesPassedSinceStartOfWeek(timezone);
  let weeksToAdd = Math.floor(preorderOptions.timeInAdvance.min / MINUTES_IN_WEEK);
  const today = Math.floor(minutesPassed / (MINUTES_IN_HOUR * HOURS_IN_DAY));
  const firstAvailableMinute = minutesPassed + preorderOptions.timeInAdvance.min;
  const lastAvailableMinute = minutesPassed + preorderOptions.timeInAdvance.max;
  const firstAvailableMinuteInTheWeek = firstAvailableMinute % MINUTES_IN_WEEK;

  const firstAvailableDayAndTimeRanges = getFirstAvailableDayAndTimeRange(
    weeklyAvailability,
    preorderOptions,
    firstAvailableMinuteInTheWeek,
    today
  );
  let chosenDay = firstAvailableDayAndTimeRanges.chosenDay;
  let firstAvailableTimeRange = firstAvailableDayAndTimeRanges.firstAvailableTimeRange;

  if (firstAvailableDayAndTimeRanges.shouldAddOneMoreWeek) {
    weeksToAdd++;
  }

  if (!firstAvailableTimeRange) {
    const nextAvailableDayAndTimeRanges = getNextAvailableDayAndTimeRange({
      weeklyAvailability,
      preorderOptions,
      firstAvailableMinuteInTheWeek,
      weeksToAdd,
      lastAvailableMinute,
      today,
    });
    chosenDay = nextAvailableDayAndTimeRanges.chosenDay;

    if (nextAvailableDayAndTimeRanges.shouldAddOneMoreWeek) {
      weeksToAdd++;
    }

    firstAvailableTimeRange = nextAvailableDayAndTimeRanges.firstAvailableTimeRange;
  }

  const daysToAdd = today >= chosenDay ? chosenDay + 7 - today : chosenDay - today;

  return {
    date: firstAvailableTimeRange
      ? DateTime.local({ zone: timezone })
          .plus({ weeks: weeksToAdd })
          .plus({ days: daysToAdd })
          .startOf('day')
      : undefined,
    from: firstAvailableTimeRange?.from || 0,
    until: firstAvailableTimeRange?.until || 0,
  };
}

export function resolveASAPTime(timezone: string, prepTime: MinMaxRange) {
  const now = getMinutesPassedSinceStartOfWeek(timezone);
  return {
    from: now + prepTime.min,
    until: now + prepTime.max,
  };
}

export function getDayOfTheWeek(date: DateTime): DAY_OF_THE_WEEK {
  return (date.weekday % 7) as DAY_OF_THE_WEEK;
}

export function canSubmitOrderNow(
  weeklyAvailability: WeeklyAvailability,
  timezone: string,
  operationType?: OperationType
) {
  return operationType && operationType === 'PRE_ORDER'
    ? false
    : !!weeklyAvailability[getToday(timezone)].find(
        (min) =>
          min.start <= getMinutesPassedSinceStartOfWeek(timezone) &&
          min.end > getMinutesPassedSinceStartOfWeek(timezone)
      );
}
