import type { DispatchModalState } from '../state/DispatchModalState';
import { DispatchType, SchedulingType } from '../../../types/businessTypes';
import type { Address } from '../../../types/businessTypes';
import type { DispatchPickerProps } from '../dispatchModalBindings';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { IBIReporterService } from '../../../services/biReporterService';
import { toJS } from 'mobx';

export function getDispatchPickerProps(
  state: DispatchModalState,
  showPicker: boolean,
  configuredDispatchTypes: DispatchType[],
  schedulingType: SchedulingType,
  setCurrentDeliveryAddressAndOptions: (address?: Address) => void,
  biReporterService?: IBIReporterService
): DispatchPickerProps {
  return {
    collapsed: () => !showPicker,
    data: () => ({
      configuredDispatchTypes,
      availableDispatchTypes: configuredDispatchTypes,
      selectedDispatchType: state.selectedDispatchType,
      onDispatchTypeChange: (dispatchType: DispatchType) => {
        biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
          dispatchType,
          isPreorder: schedulingType === SchedulingType.PRE_ORDER,
          buttonType:
            dispatchType === DispatchType.PICKUP
              ? LiveSiteClicksInsideDispatchModalButtonType.PICKUP_TAB
              : LiveSiteClicksInsideDispatchModalButtonType.DELIVERY_TAB,
        });
        state.setDispatchType(dispatchType);
        if (
          dispatchType === DispatchType.DELIVERY &&
          !state[DispatchType.DELIVERY].options?.length
        ) {
          setCurrentDeliveryAddressAndOptions(toJS(state[DispatchType.DELIVERY].address));
        }
      },
    }),
  };
}
