import type { BindAll } from '@wix/velocycle-mobx';
import type {
  I$W,
  IWixWindow,
  PlatformControllerFlowAPI,
  TFunction,
  ISiteApis,
  Experiments,
} from '@wix/yoshi-flow-editor';
import type { DispatchModalProps } from '../../types/widgetsProps';
import { bindDispatchModal } from './dispatchModalBindings';
import { DispatchModalState } from './state/DispatchModalState';
import { FulfillmentsClient } from '../../api/fulfillmentsClient';
import { getTimeState, resolveTimeSchedulingState } from './dispatchModalUtils';
import { DISPATCH_MODAL_DISPATCH_STATE, DispatchType, TimeState } from '../../types/businessTypes';
import { DISPATCH_MODAL_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { autorun, toJS } from 'mobx';
import { canSubmitOrderNow } from '../../logic/timeUtils';
import { getSchedulingPickerProps } from './componentsBindings/SchedulingPickerBinding';
import { getErrorMessageProps } from './componentsBindings/ErrorMessageBinding';
import { getAddressInputProps, setAddressOptions } from './componentsBindings/AddressInputBindings';
import { getSaveButtonProps } from './componentsBindings/SaveButtonBinding';
import { getTimeSlotsProps } from './componentsBindings/TimeSlotsBinding';
import { getDispatchPickerProps } from './componentsBindings/DispatchPickerBinding';
import { getDatePickerProps } from './componentsBindings/DatePickerBinding';
import { getASAPTimeString } from '../../utils/operationUtils';
import { LiveSiteDispatchModalClosedTriggerName } from '@wix/restaurants-bi';
import { DEFAULT_LOCALE } from 'root/api/consts';
import type { Address, Operation } from '../../types/businessTypes';
import { convertAddressToAddressInput } from '../Header/headerUtils';
import type { IBIReporterService } from 'root/services/biReporterService';

export class DispatchModalController {
  constructor(
    private $bindAll: BindAll<I$W>,
    private $w: I$W,
    private props: DispatchModalProps | undefined,
    private flowAPI: PlatformControllerFlowAPI,
    private window: IWixWindow,
    private experiments: Experiments
  ) {}

  init(
    onModalOpen: () => void,
    closeModal: (window: IWixWindow, data?: DispatchModalProps) => void
  ) {
    if (!this.props) {
      return;
    }
    const t = this.flowAPI.translations.t as TFunction;
    const site = this.flowAPI.controllerConfig.wixCodeApi.site as ISiteApis;
    const timezone =
      this.flowAPI.controllerConfig.wixCodeApi.site.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = this.flowAPI.controllerConfig.wixCodeApi.site.regionalSettings || DEFAULT_LOCALE;

    const onClose = () => closeModal(this.window);

    const {
      operationId,
      onSave,
      showPicker,
      configuredDispatchTypes,
      canOrderPickupNow,
      schedulingType,
      preorderOptions,
      operationType,
      biReporterService,
    } = this.props;
    const fulfillmentsClient = new FulfillmentsClient(this.flowAPI.httpClient, operationId);
    const dispatchModalMethods = new DispatchModalMethods(this.$w);
    const state = new DispatchModalState(this.props, site, t, this.experiments);
    const operation = { operationType, preOrderOptions: preorderOptions };

    autorun(() => {
      const timeState = resolveTimeSchedulingState(
        schedulingType,
        state,
        (state.isPickup && canOrderPickupNow) ||
          canSubmitOrderNow(state.weeklyAvailability, timezone, operationType)
      );
      dispatchModalMethods.switchTimeState(
        timeState === TimeState.SAME_DAY_LATER ? TimeState.SAME_DAY : timeState
      );
    });

    autorun(() => {
      dispatchModalMethods.switchDispatchState(state.dispatchType);
    });

    const address = toJS(state[DispatchType.DELIVERY].address);

    const setCurrentDeliveryAddressAndOptions = this.setCurrentAddress(
      state,
      fulfillmentsClient,
      timezone,
      site,
      t,
      operation,
      biReporterService
    );

    setCurrentDeliveryAddressAndOptions(address);

    bindDispatchModal({
      $bindAll: this.$bindAll,
      componentsProps: {
        dispatchPicker: getDispatchPickerProps(
          state,
          showPicker,
          configuredDispatchTypes,
          schedulingType,
          setCurrentDeliveryAddressAndOptions,
          biReporterService
        ),
        timeSlots: getTimeSlotsProps(state, schedulingType, t, biReporterService),
        saveButton: getSaveButtonProps(state, onSave, onClose, t, biReporterService),
        ASAPText: {
          text: () => getASAPTimeString(t, state[state.selectedDispatchType].prepTime),
        },
        schedulingPicker: getSchedulingPickerProps(
          state,
          schedulingType,
          !!canOrderPickupNow,
          t,
          timezone,
          biReporterService
        ),
        closeButton: {
          onClick: () => {
            biReporterService?.reportOloLiveSiteDispatchModalClosedBiEvent({
              dispatchType: state.dispatchType,
              triggerName: LiveSiteDispatchModalClosedTriggerName.X_BUTTON,
            });
            onClose();
          },
        },
        errorMessage: getErrorMessageProps(state),
        addressInput: getAddressInputProps(
          state,
          t,
          schedulingType,
          setCurrentDeliveryAddressAndOptions,
          biReporterService
        ),
        datePicker: getDatePickerProps({
          state,
          timezone,
          t,
          schedulingType,
          locale,
          biReporterService,
          preorderOptions,
          experiments: this.experiments,
        }),
        pickupAddress: {
          text: () => state[DispatchType.PICKUP].address?.formattedAddress || '',
        },
        pickupHeader: {
          text: () => t('menu_olo.dispatchModal.pickup.from'),
        },
        modalTitle: {
          text: () => t('menu_olo.dispatchModal.headerTitle'),
        },
      },
    });
    const timeState = getTimeState(state, schedulingType, !!canOrderPickupNow, timezone);
    const isAsapDisplayed = timeState === TimeState.ASAP_ONLY || timeState === TimeState.SAME_DAY;
    const isPreorderDisplayed = timeState === TimeState.PRE_ORDER;
    onModalOpen();
    biReporterService?.reportOloLiveSiteDispatchModalOpenedBiEvent({
      isAsapDisplayed,
      availableDispatchTypes: configuredDispatchTypes,
      isPreorderDisplayed,
      schedulingType,
      currentFulfillment: state.selectedDispatchType,
      operationId,
    });
  }

  setCurrentAddress =
    (
      state: DispatchModalState,
      fulfillmentsClient: FulfillmentsClient,
      timezone: string,
      site: ISiteApis,
      t: TFunction,
      operation: Partial<Operation>,
      biReporterService?: IBIReporterService
    ) =>
    async (address?: Address) => {
      if (address) {
        this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.addressInput).value =
          convertAddressToAddressInput(address);

        await setAddressOptions({
          address,
          state,
          fulfillmentsClient,
          timezone,
          site,
          t,
          biReporterService,
          operation,
          experiments: this.experiments,
          schedulingType: this.props?.schedulingType,
        });
      }
    };
}

export class DispatchModalMethods {
  constructor(private $w: I$W) {}

  switchTimeState = (state: TimeState) => {
    const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeMultiState);
    multiStateBox.changeState(state !== TimeState.SAME_DAY_LATER ? state : TimeState.SAME_DAY);
  };

  switchDispatchState = (type: DispatchType) => {
    const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dispatchStateBox);
    multiStateBox.changeState(DISPATCH_MODAL_DISPATCH_STATE[type]);
  };
}
