import type {
  MinMaxRange,
  OperationType,
  PreorderScheduling,
  WeeklyAvailability,
  SchedulingType,
} from '../../../types/businessTypes';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '../../../api/consts';
import {
  getWeeklyAvailabilityForFulfillmentOptionsMemo,
  resolveFastestDispatch,
} from '../../../logic/timeUtils';
import type { Experiments, ISiteApis, TFunction } from '@wix/yoshi-flow-editor';
import {
  getDispatchDropdownOptions,
  getSelectedTime,
  getPreOrderTimeSlotsDropdownOptions,
} from '../dispatchModalUtils';
import type { DispatchState } from '../../../states/HeaderState';
import type { DispatchForm } from './DispatchModalState';
import type { FulfillmentOption } from 'root/contexts/FulfillmentContext';
import type { DateTime } from 'luxon';
import { SPECS } from 'root/appConsts/experiments';

const getMinDate = ({
  startTime,
  weeklyAvailability,
  availableDispatches,
  operationType,
  preOrderOptions,
  timezone,
  prepTime,
}: {
  startTime: DateTime;
  weeklyAvailability: WeeklyAvailability;
  availableDispatches: FulfillmentOption[];
  operationType: OperationType;
  timezone: string;
  prepTime: MinMaxRange;
  preOrderOptions?: PreorderScheduling;
}): DateTime => {
  if (availableDispatches.length === 0) {
    return startTime;
  }
  const { date } = resolveFastestDispatch(
    weeklyAvailability,
    { operationType, preOrderOptions },
    timezone,
    prepTime
  );
  return date && date > startTime ? date : startTime;
};

export const getInitialDispatchForm = ({
  dispatchState,
  availableDispatches,
  preorderOptions,
  operationType,
  site,
  t,
  experiments,
  schedulingType,
}: {
  dispatchState: DispatchState;
  availableDispatches: FulfillmentOption[];
  preorderOptions?: PreorderScheduling;
  operationType: OperationType;
  site: ISiteApis;
  t: TFunction;
  experiments: Experiments;
  schedulingType: SchedulingType;
}): DispatchForm => {
  const { timezone = DEFAULT_TIMEZONE, regionalSettings = DEFAULT_LOCALE } = site;
  const initialSelectedTime = dispatchState.dispatchTime;

  const weeklyAvailability: WeeklyAvailability =
    getWeeklyAvailabilityForFulfillmentOptionsMemo(availableDispatches);
  const minDate =
    dispatchState.startTime &&
    getMinDate({
      timezone,
      startTime: dispatchState.startTime,
      weeklyAvailability,
      availableDispatches,
      operationType,
      preOrderOptions: preorderOptions,
      prepTime: dispatchState.prepTime,
    });

  const options =
    experiments.enabled(SPECS.newPreOrderTimeSlotsLogic) && schedulingType === 'PRE_ORDER'
      ? getPreOrderTimeSlotsDropdownOptions({
          date: minDate,
          t,
          locale: regionalSettings,
          preOrderTimeWindows: dispatchState.preOrderTimeWindows ?? [],
          timezone,
        })
      : getDispatchDropdownOptions({
          t,
          locale: regionalSettings,
          dispatchState,
          timezone,
          preorderOptions,
          weeklyAvailability,
          minDate,
        });

  const { selectedTimeOptionId, selectedTime } = getSelectedTime(options, initialSelectedTime);
  return {
    prepTime: dispatchState.prepTime,
    weeklyAvailability,
    maxDate: dispatchState.endTime,
    minDate,
    selectedSchedulingType: dispatchState?.selectedSameDayScheduling,
    options,
    selectedTime,
    address: dispatchState?.address,
    availableFulfillments: availableDispatches,
    selectedTimeOptionId,
    selectedDate: initialSelectedTime?.date,
    preOrderTimeWindows: dispatchState.preOrderTimeWindows,
  };
};
