import type model from './model';
import { NAVIGATION_TOOLBAR_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import { MAX_CHARS_SECTION_TAB } from '../../appConsts/consts';
import { truncate } from '../../utils/utils';
import { state } from '../../states/RootState';
import type { I$W } from '@wix/yoshi-flow-editor';
import { initSectionsList } from './utils';
import type { SectionList } from './utils';
import type { ControllerParams } from '../../types/widgets';
import { LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName } from '@wix/restaurants-bi';

type NavigationMenuProps = {
  menus: PopulatedMenu[];
};

type Bind = ControllerParams<typeof model>['$bind'];
type BindAll = ControllerParams<typeof model>['$bindAll'];

export class NavigationMenuController {
  selectedMenu!: PopulatedMenu;
  selectedSection!: string;
  menusMap: Record<string, PopulatedMenu> = {};
  sectionList: SectionList = {};
  hasMultipleMenus: boolean = true;

  constructor(
    private $w: I$W,
    private $bind: Bind,
    private $bindAll: BindAll,
    private isMobile?: boolean
  ) {
    const { pubsub } = state;
    pubsub.subscribe('onSectionViewportEnter', this.onSectionViewportEnter);
    pubsub.subscribe('onSectionViewportLeave', this.onSectionViewportLeave);
    pubsub.subscribe('onHeaderViewportEnter', this.onHeaderViewportEnter);
    pubsub.subscribe('onHeaderViewportLeave', this.onHeaderViewportLeave);
  }

  init({ menus }: NavigationMenuProps) {
    this.menusMap = menus.reduce((acc, menu) => ({ ...acc, [menu.id as string]: menu }), {});
    this.selectedMenu = menus[0];
    this.selectedSection = this.selectedMenu?.sections[0]?.id;
    this.sectionList = initSectionsList(menus);
    const menuItems = this.getMenuItems();
    this.hasMultipleMenus = menus.length > 1;
    const commonParamsMenuDropdownBIEvent = {
      operationId: state.operation?.id,
      numTotalMenus: menus.length,
      currentMenuId: this.selectedMenu?.id,
      currentMenuName: this.selectedMenu?.name,
      currentMenuNumber: menus.findIndex((menu) => menu.id === this.selectedMenu?.id),
    };
    this.$bindAll({
      [NAVIGATION_TOOLBAR_IDS.menuDropdown]: {
        options: () =>
          menus.map((menu) => ({
            label: menu.name,
            value: menu.id,
          })),
        onClick: () => {
          state.biReporterService?.reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent({
            ...commonParamsMenuDropdownBIEvent,
            buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName.DROPDOWN,
            menuNumber: undefined,
            menuName: undefined,
            menuId: undefined,
          });
        },
        onChange: (event: $w.Event) => {
          // TODO: remove ts-expect-error when $w types are fixed
          const menuId = event.target.value;
          state.biReporterService?.reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent({
            ...commonParamsMenuDropdownBIEvent,
            buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName.DROPDOWN_MENU_OPTION,
            menuNumber: menus.findIndex((menu) => menu.id === menuId),
            menuName: this.menusMap[menuId].name,
            menuId,
          });

          this.selectedMenu = this.menusMap[menuId];
          this.selectedSection = this.selectedMenu?.sections[0]?.id;
          state.pubsub.publish('onScrollToMenu', { menuId });
          this.setMenuItems();
        },
        collapsed: () => !this.hasMultipleMenus,
      },
      [NAVIGATION_TOOLBAR_IDS.navigationDivider]: {
        collapsed: () => !this.hasMultipleMenus,
      },
      [NAVIGATION_TOOLBAR_IDS.sectionTabs]: {
        menuItems: () => menuItems,
        // @ts-expect-error
        onItemClick: ({ item }) => {
          state.biReporterService?.reportOloLiveSiteClicksOnMenuBarBiEvent({
            currentSectionNumber: this.selectedMenu?.sections.findIndex(
              (section) => section.id === this.selectedSection
            ),
            numTotalSections: this.selectedMenu?.sections.length,
            operationId: state.operation?.id,
            sectionId: item.id,
            sectionName: this.selectedMenu?.sections.find((section) => section.id === item.id)
              ?.name,
          });
          this.selectedSection = item.id;
          state.pubsub.publish('onScrollToSection', { sectionId: item.id });
          this.setMenuItems();
        },
        collapsed: () => menuItems?.length === 0,
      },
    });
  }

  setMenuItems = () => {
    const menuItems = () => this.getMenuItems();
    this.$bind(NAVIGATION_TOOLBAR_IDS.sectionTabs, { menuItems });
  };

  getMenuItems = () => {
    return this.selectedMenu?.sections.map((section) => {
      const label = truncate(section.name || '', MAX_CHARS_SECTION_TAB);
      return {
        label,
        id: section.id,
        selected: this.selectedSection === section.id,
      };
    });
  };

  onSectionViewportEnter = ({ sectionId }: { sectionId: string }) => {
    this.sectionList[sectionId].isInViewPort = true;
  };

  private changeSelectedSectionIfNeeded = (sectionId: string) => {
    if (this.selectedSection === sectionId) {
      let nextSectionId = this.sectionList[sectionId].nextSectionId;
      if (!nextSectionId || !this.sectionList[nextSectionId].isInViewPort) {
        nextSectionId = this.sectionList[sectionId].prevSectionId;
      }
      if (nextSectionId && this.sectionList[nextSectionId].isInViewPort) {
        this.selectedSection = nextSectionId;
        return true;
      }
    }
    return false;
  };

  private changeSelectedMenuIfNeeded = (menuId: string) => {
    if (this.selectedMenu?.id !== menuId) {
      this.selectedMenu = this.menusMap[menuId];
      this.$w(NAVIGATION_TOOLBAR_IDS.menuDropdown).value = this.selectedMenu?.id;
    }
  };

  onSectionViewportLeave = ({ sectionId }: { sectionId: string }) => {
    this.sectionList[sectionId].isInViewPort = false;
    const didChangeSection = this.changeSelectedSectionIfNeeded(sectionId);
    if (didChangeSection) {
      const nextMenuId = this.sectionList[this.selectedSection].menuId;
      this.changeSelectedMenuIfNeeded(nextMenuId);
      this.setMenuItems();
    }
  };

  onHeaderViewportLeave = () => {
    if (this.isMobile && this.hasMultipleMenus) {
      this.$w(NAVIGATION_TOOLBAR_IDS.menuDropdown).collapse();
    }
  };

  onHeaderViewportEnter = () => {
    if (this.isMobile && this.hasMultipleMenus) {
      this.$w(NAVIGATION_TOOLBAR_IDS.menuDropdown).expand();
    }
  };
}
