import type {
  DayOfWeekAvailability,
  FulfillmentOption as FulfillmentOptionAPI,
  ListAvailableFulfillmentOptionsResponse,
  TimeOfDay,
  TimeOfDayRange,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import {
  DayOfWeek,
  FulfillmentType,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { partition } from 'lodash';
import { MinutesRange } from '../../logic/MinutesRange';
import type { Address, MinMaxRange } from '../../types/businessTypes';
import { DispatchType } from '../../types/businessTypes';
import type {
  DeliveryFulfillmentOption,
  FulfillmentOption,
  PickupFulfillmentOption,
} from '../../contexts/FulfillmentContext';
import { HOURS_IN_DAY, MINUTES_IN_HOUR } from '../../logic/timeUtils';
import { MOCK_ADDRESS } from '../consts';
import { DateTime } from 'luxon';
import { DEFAULT_PREP_TIME } from '../../components/DispatchModal/state/defaults';

export const FulfillmentOptionsProcessor = {
  process: processAvailableFulfillmentOptionsResponse,
};

const daysMaps = {
  [DayOfWeek.SUN]: 0,
  [DayOfWeek.MON]: 1,
  [DayOfWeek.TUE]: 2,
  [DayOfWeek.WED]: 3,
  [DayOfWeek.THU]: 4,
  [DayOfWeek.FRI]: 5,
  [DayOfWeek.SAT]: 6,
};
export interface StartAndEndTime {
  start: DateTime;
  end: DateTime;
}

export interface AvailableDispatchesInfo {
  [DispatchType.PICKUP]: {
    fulfillments: PickupFulfillmentOption[];
    startAndEndTime?: StartAndEndTime;
    prepTime: MinMaxRange;
    timezone?: string | null;
  };
  [DispatchType.DELIVERY]: {
    fulfillments: DeliveryFulfillmentOption[];
    startAndEndTime?: StartAndEndTime;
    prepTime: MinMaxRange;
    timezone?: string | null;
  };
}
export interface ProcessResult {
  configuredDispatchTypes: DispatchType[];
  availableDispatches: AvailableDispatchesInfo;
}

function processAvailableFulfillmentOptionsResponse(
  response: ListAvailableFulfillmentOptionsResponse
): ProcessResult {
  return {
    configuredDispatchTypes: getConfiguredDispatchTypes(response),
    availableDispatches: parseFulfillmentOptions(response, true),
  };
}

function getPrepTime(fulfillmets: FulfillmentOptionAPI[]) {
  if (!fulfillmets[0]) {
    return DEFAULT_PREP_TIME;
  }
  const getMin = (delivery: FulfillmentOptionAPI): number =>
    (delivery.durationRangeOptions
      ? delivery.durationRangeOptions.minDuration
      : delivery.maxTimeOptions) || 0;
  const getMax = (delivery: FulfillmentOptionAPI): number =>
    (delivery.durationRangeOptions
      ? delivery.durationRangeOptions.maxDuration
      : delivery.maxTimeOptions) || 0;

  let min = getMin(fulfillmets[0]);
  let max = getMax(fulfillmets[0]);
  fulfillmets.forEach((delivery) => {
    const currentMin = getMin(delivery);
    const currentMax = getMax(delivery);
    min = Math.min(min, currentMin);
    max = Math.max(max, currentMax);
  });
  return { min, max };
}
function getStartAndEndTime(fulfillmets: FulfillmentOptionAPI[]): {
  start: DateTime;
  end: DateTime;
} {
  let min: DateTime | undefined;
  let max: DateTime | undefined;
  fulfillmets.forEach((fulfillmet) => {
    if (fulfillmet.availability?.startTime) {
      const startTime = DateTime.fromJSDate(fulfillmet.availability.startTime);
      if (!min || min > startTime) {
        min = startTime;
      }
    }
    if (fulfillmet.availability?.endTime) {
      const endTime = DateTime.fromJSDate(fulfillmet.availability.endTime);
      if (!max || max < endTime) {
        max = endTime;
      }
    }
  });
  return {
    start: min || DateTime.fromJSDate(new Date()),
    end: max || DateTime.fromJSDate(new Date(2200, 1, 1)),
  };
}
function parseFulfillmentOptions(
  response: ListAvailableFulfillmentOptionsResponse,
  shouldCalculateStartAndEnd = false
) {
  const [pickup, delivery] = partition(
    response.fulfillmentOptions!,
    (option) => option.type === FulfillmentType.PICKUP
  );
  return {
    [DispatchType.PICKUP]: {
      fulfillments: pickup.map(convertFulfillmentOptionAPIToPickup),
      prepTime: getPrepTime(pickup),
      startAndEndTime:
        shouldCalculateStartAndEnd && pickup.length > 0 ? getStartAndEndTime(pickup) : undefined,
      timezone: pickup[0]?.availability?.timeZone,
    },
    [DispatchType.DELIVERY]: {
      fulfillments: delivery.map(convertFulfillmentOptionAPIToDelivery),
      prepTime: getPrepTime(delivery),
      startAndEndTime:
        shouldCalculateStartAndEnd && delivery.length > 0
          ? getStartAndEndTime(delivery)
          : undefined,
      timezone: delivery[0]?.availability?.timeZone,
    },
  };
}

function convertFulfillmentOptionAPIToPickup(
  option: FulfillmentOptionAPI
): PickupFulfillmentOption {
  return {
    ...convertFulfillmentOptionAPIToFulfillmentOption(option),
    type: DispatchType.PICKUP,
    address: (option.pickupOptions?.address as Address) || MOCK_ADDRESS,
  };
}
function convertFulfillmentOptionAPIToDelivery(
  option: FulfillmentOptionAPI
): DeliveryFulfillmentOption {
  const freeFulfillmentPriceThreshold = Number(option.freeFulfillmentPriceThreshold);
  return {
    ...convertFulfillmentOptionAPIToFulfillmentOption(option),
    type: DispatchType.DELIVERY,
    deliveryFee: Number(option.fee),
    freeFulfillmentPriceThreshold:
      freeFulfillmentPriceThreshold >= 0 ? freeFulfillmentPriceThreshold : undefined,
  };
}
function convertFulfillmentOptionAPIToFulfillmentOption(
  option: FulfillmentOptionAPI
): FulfillmentOption {
  return {
    id: option.id || '',
    type: DispatchType.PICKUP,
    minOrderPrice: option.minOrderPrice!,
    canSubmitOrderForNow: !!option.availability?.canSubmitOrderForNow,
    minutesOfTheWeek: option.availability!.availableTimes!.flatMap((time) =>
      convertDayOfWeekAvailabilityToMinutesRange(time)
    ),
    availabilityTimeSlots: option.availability?.availableTimes ?? [],
  };
}

function getConfiguredDispatchTypes(response: ListAvailableFulfillmentOptionsResponse) {
  const result: DispatchType[] = [];
  response.pickupConfigured && result.push(DispatchType.PICKUP);
  response.deliveryConfigured && result.push(DispatchType.DELIVERY);
  return result;
}

function convertTimeOfDayToMinutes(timeOfDay: TimeOfDay) {
  return (timeOfDay.hours || 0) * 60 + (timeOfDay.minutes || 0);
}
function convertTimeOfDayRangeToMinutesRange(
  timeOfDayRange: TimeOfDayRange,
  offset: number
): MinutesRange {
  return new MinutesRange({
    start: offset + convertTimeOfDayToMinutes(timeOfDayRange.startTime!),
    end: offset + convertTimeOfDayToMinutes(timeOfDayRange.endTime!),
  });
}

function convertDayOfWeekAvailabilityToMinutesRange(
  dayOfWeek: DayOfWeekAvailability
): MinutesRange[] {
  const dayStart = daysMaps[dayOfWeek.dayOfWeek!] * HOURS_IN_DAY * MINUTES_IN_HOUR;
  return dayOfWeek.timeRanges!.map((time) => convertTimeOfDayRangeToMinutesRange(time, dayStart));
}
