import type {
  ModifierGroup as ModifierGroupTPA,
  Modifier as ModifierAdditionalChargeTPA,
  MultiSelect,
  Rule as RuleTPA,
  SingleSelect,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier-group/types';
import { Type as RuleTypeTPA } from '@wix/ambassador-restaurants-menus-v1-item-modifier-group/types';
import type { Modifier as ModifierTPA } from '@wix/ambassador-restaurants-menus-v1-item-modifier/types';
import { Type as ModifierTypeTPA } from '@wix/ambassador-restaurants-menus-v1-item-modifier/types';
import type {
  PriceVariant as PriceVariantTPA,
  ModifierGroup as ItemModifierGroupTPA,
} from '@wix/ambassador-restaurants-menus-v1-item/types';

export type ModifierGroupTpa = ModifierGroupTPA;
export type ModifierTpa = ModifierTPA;
export type RuleTpa = RuleTPA;
export type PriceVariantTpa = PriceVariantTPA;
export type ModifierAdditionalChargeTpa = ModifierAdditionalChargeTPA;
export type ItemModifierGroupTpa = ItemModifierGroupTPA;
export { ModifierTypeTPA as ModifierTypeTpa };
export { RuleTypeTPA as RuleTypeTpa };

export type Rule = {
  singleSelectOptions?: SingleSelect;
  multiSelectOptions?: MultiSelect;
  mandatory?: boolean;
  type?: ModifierRuleType;
};

export type NewRule = {
  mandatory?: boolean;
  minSelections?: number;
  maxSelections?: number;
};

export type ModifierGroupRule = Rule | NewRule;

export type ModifierGroup = {
  id?: string;
  name?: string;
  modifiers?: ModifierAdditionalCharge[];
  rule?: ModifierGroupRule;
};

export type ItemModifierGroup = {
  id: string;
};

export type Modifier = {
  id?: string;
  name?: string;
};

export type ModifierAdditionalCharge = {
  id?: string;
  price?: string;
};

export type ModifierListItem = {
  id?: string;
  name?: string;
  price?: string;
};

export type PriceVariant = {
  id: string;
  price?: string;
};

export type ModifierGroupListItem = {
  id: string;
  name?: string;
  rule?: ModifierGroupRule;
  modifiers?: ModifierListItem[];
};

export type ModifierRepeaterData = ModifierGroupListItem & { _id: number };

export enum ModifierRuleType {
  SINGLE_SELECT = RuleTypeTPA.SINGLE_SELECT,
  MULTI_SELECT = RuleTypeTPA.MULTI_SELECT,
  UNSPECIFIED_RULE = RuleTypeTPA.UNSPECIFIED_RULE,
}

export enum RuleType {
  NO_LIMIT,
  CHOOSE_ONE,
  CHOOSE_X,
  CHOOSE_AT_LEAST_ONE,
  CHOOSE_AT_LEAST_X,
  CHOOSE_UP_TO_X,
  CHOOSE_BETWEEN_X_AND_Y,
}
